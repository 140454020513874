import React, { useState } from 'react';

import { MdCheck, MdClose, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Avatar, Flex, IconButton, Image, Input, Text, Tooltip } from '@chakra-ui/react';

import ReactionEmojiBar from '../../../../components/ReactionEmojiBar/ReactionEmojiBar';
import profile from '../../../../services/profile';
import requests from '../../../../services/requests';
import { commentConfused, commentHappy, commentLike, commentSad } from '../../../../utils/exports';

import ModalDeleteComment from './ModalDeleteComment';

const ProcessChatMessage = ({ entity, getComments, processComments }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(entity?.description);

  const hasCommentsEmojis = false;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    requests
      .editProcessComment(entity.processIdentifier, entity.identifier, {
        description: editedDescription,
        hasBeenRead: 0,
      })
      .then(() => {
        getComments();
        toast.success('Comentário alterado com sucesso!');
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao editar o comentário, atualize a página e tente novamente.');
      });
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedDescription(entity?.description);
    setIsEditing(false);
  };

  function TimeSince({ createdAt }) {
    // Converter a string de data e hora no formato dd/mm/yyyy hh:mm
    // para o formato yyyy-mm-ddThh:mm
    const [datePart, timePart] = createdAt.split(' ');
    const [day, month, year] = datePart.split('/');
    const formattedDateTime = `${year}-${month}-${day}T${timePart}`;

    // Criar um objeto Date para o momento atual e o createdAt formatado
    const now = new Date();
    const createdDate = new Date(formattedDateTime);

    // Diferença em milissegundos
    let diff = now - createdDate;

    // Converter a diferença em minutos
    let diffInMinutes = Math.floor(diff / (1000 * 60));
    if (diffInMinutes < 60) {
      // Se a diferença for menor que 60 minutos, retornar a diferença em minutos
      return <span>{diffInMinutes}m</span>;
    }

    // Converter a diferença em horas
    let diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      // Se a diferença for menor que 24 horas, retornar a diferença em horas
      return <span>{diffInHours}h</span>;
    }

    // Se a diferença for maior que 24 horas, converter a diferença para dias
    let diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays <= 7) {
      return <span>{diffInDays}d</span>;
    }

    return <span>{createdAt}</span>;
  }

  function getPicture(picture) {
    let url = process.env.REACT_APP_BASE_URL;

    if (url === 'http://localhost:8000' || 'http://localhost:8001') {
      url = 'https://api.vendemmia.com.br';
    }

    const newImage = window.localStorage.getItem('temp-profile-picture');
    const tempPicture = newImage || picture;
    return (url + tempPicture)?.replace('//profile', '/profile');
  }

  const onReactionUpdate = (key) => {
    //console.log(key);
  };

  return (
    <Flex className="down-anim" mb="20px">
      <Flex mt="15px" mr="20px" borderRadius="100%" border="#422C76 solid 1px" p="2px" h="fit-content">
        <Avatar w="40px" h="40px" src={getPicture(entity.userProfilePictureUri)} name={entity.userName} />
      </Flex>

      <Flex direction="column" bg="#F2F2F2" p="10px 15px" w="100%" borderRadius="5px">
        <Flex direction="column">
          <Flex alignItems="center" gap="14px" justify="space-between">
            <Flex gap="10px" align="center">
              <Text color="primary" fontSize="19px" fontWeight="700">
                {entity.userName}
              </Text>

              {/* <Text bg="#A885F9" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
                {'Autor'}
              </Text> */}

              <Text bg="#E52359" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
                {entity.stageDescription}
              </Text>
            </Flex>

            <Flex gap="5px" align="center">
              <Text right="0" color="#88868E" fontSize="14px">
                {TimeSince({ createdAt: entity.createdAt })}
              </Text>
            </Flex>
          </Flex>

          <Text color="#0F0A1D99" fontWeight="normal" fontSize="14px">
            {entity.userRole == 'Operator' ? 'Time operacional Vendemmia' : 'Time Vendemmia'}
          </Text>
        </Flex>

        <Flex align="center" justify="space-between" gap="5px">
          {isEditing ? (
            <Input value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
          ) : (
            <Text fontSize="16px" fontWeight="600" lineHeight="2" mt="10px">
              {entity?.description}
            </Text>
          )}
          {profile.identifier === entity.userIdentifier && !processComments.hideProcess && (
            <Flex gap="5px">
              {isEditing ? (
                <>
                  <Tooltip label="Salvar alterações">
                    <IconButton icon={<MdCheck color="#422C7694" />} onClick={handleSave} />
                  </Tooltip>
                  <Tooltip label="Cancelar">
                    <IconButton icon={<MdClose color="#E74C3C" />} onClick={handleCancelClick} />
                  </Tooltip>
                </>
              ) : (
                <Tooltip label="Editar comentário">
                  <IconButton icon={<MdEdit color="#422C7694" />} onClick={handleEdit} />
                </Tooltip>
              )}

              <ModalDeleteComment entity={entity} getComments={getComments} />
            </Flex>
          )}
        </Flex>
        {hasCommentsEmojis && (
          <Text p={'5px 0'}>
            <ReactionEmojiBar
              reactions={[
                { label: 'curtir', node: <Image src={commentLike} size={20} color="#AFAEB4" />, key: 'like' },
                { label: 'confuso', node: <Image src={commentConfused} size={20} color="#AFAEB4" />, key: 'confused' },
                { label: 'feliz', node: <Image src={commentHappy} size={20} color="#AFAEB4" />, key: 'happy' },
                { label: 'triste', node: <Image src={commentSad} size={20} color="#AFAEB4" />, key: 'sad' },
              ]}
              reactionCounter={
                [] /* [
                { label: 'curtir', node: <Image src={commentLike} size={20} color="#AFAEB4" /> },
                { label: 'curtir', node: <Image src={commentLike} size={20} color="#AFAEB4" /> },
                { label: 'confuso', node: <Image src={commentConfused} size={20} color="#AFAEB4" /> },
              ] */
              }
              onReactionUpdate={onReactionUpdate}
            />
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ProcessChatMessage;
