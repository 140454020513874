import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import SortableTableHeader from '../../components/SortableTableHeader';

import PickingPackingList from './PickingPackingList';

const PickingPackingTable = ({ list, handleSort, sorting, onSave }) => {
  let profileRole = localStorage.getItem('profile-role');

  return (
    <Table w="full">
      <Thead>
        <Tr>
          <Th>
            <Box></Box>
          </Th>
          {profileRole === 'ROLE_SYSTEM_ADMIN' && (
            <Th>
              <Box></Box>
            </Th>
          )}
          <SortableTableHeader column="e.idNotafiscal" label="ID NF (Sênior)" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.statusNf" label="Status" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.usuarioConferencia" label="Usuário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufArmazem" label="Armazém" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nuNota" label="Nota fiscal" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.serieNf" label="Série" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idPedido" label="Pedido" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.classTpPedido" label="Canal" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeEntrega" label="Destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufDestinatario" label="UF Destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.recipientCity" label="Cidade destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeDepositante" label="Depositante" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeTransportadora" label="Transportadora" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtCadastro" label="Data de cadastro" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtRoterizado" label="Início da separação" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtFimSeparacao" label="Término da separação" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader
            column="e.dtInicioConferencia"
            label="Início da conferência"
            sorting={sorting}
            handleSort={handleSort}
          />
          <SortableTableHeader column="e.dtFimConferencia" label="Término da conferência" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.qtVolumes" label="Volumes" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.cubagemM3" label="Cub." sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.valorNf" label="Valor total da NF" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.pesoBrutoKg" label="Peso bruto" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.observacao" label="Observação" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.updatedAt" label="Última atualização" sorting={sorting} handleSort={handleSort} />

          {profileRole !== 'ROLE_CLIENT' && (
            <Th>
              <Text>Ações</Text>
            </Th>
          )}
        </Tr>
      </Thead>

      <Tbody>
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => (
              <PickingPackingList key={entity.id} count={key} entity={entity} onSave={onSave} />
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={25}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default PickingPackingTable;
