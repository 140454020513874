import { useEffect, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';

import { useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import { commonListConfig } from './components/commonListConfig';
import ModalManagementClient from './components/ModalManagementClient';

const ClientManagementPage = () => {
  const hasPermission = permissions.vendemmiaManagementClientManagement;

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [identifier, setIdentifier] = useState();

  const [list, setList] = useState([]);

  const [sorting, setSorting] = useState(null);
  const handleSort = (column, direction) => {
    setSorting({
      column,
      direction,
    });
  };

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    fetchClientListData(filters, metadata.current_page, 25);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const fetchClientListData = async (filters, page, pageSize) => {
    try {
      const res = await requests.listClients(filters, page, pageSize, 'all');
      setList(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const subtitle = `Existe um total de ${metadata.total_count} clientes`;

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const handleOpenEditModal = (identifier) => {
    onEditModalOpen();
    setIdentifier(identifier);
  };

  const handleEditModalClose = () => {
    onEditModalClose();
    load();
  };

  const customButtons = [
    { label: 'Prévia', tooltip: 'Visualizar cliente', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenEditModal },
  ];

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Page
      screen="client-management"
      title={`Gestão de clientes`}
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por nome ou CNPJ"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      showPeriodFilter={false}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      isRefreshLoading={isLoading}
      showFilters={false}
      showRefreshData={false}
      allowFiltersOverride={false}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
    >
      <ModalManagementClient
        triggerRefresh={triggerRefresh}
        identifier={identifier}
        handleClose={handleEditModalClose}
        handleOpen={onEditModalOpen}
        isOpen={isEditModalOpen}
      />
      <CommonList
        subtitle={subtitle}
        hasPagination={true}
        sorting={sorting}
        handleSort={handleSort}
        list={list}
        rawData={list}
        action={action}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        customButtons={customButtons}
        {...commonListConfig}></CommonList>
    </Page>
  );
};

export default ClientManagementPage;
