import React, { useEffect } from 'react';

import { ErrorMessage } from 'formik';
import moment from 'moment';
import { MdAdd, MdDelete } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';

import { Box, Flex, FormControl, Grid, IconButton, Input, Text, Tooltip } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';

const FormQuote = ({ values, setFieldValue, selectCustomStyles, handleChange, isLoadCurrency, currency }) => {
  const handleAddClick = () => {
    console.log('formquoteadd');

    const newField = {
      supplier: '',
      currency: '',
      totalValue: '',
      transitTime: '',
      freeTime: '',
      expiresAt: '',
    };

    setFieldValue('logisticInformation', [...values.logisticInformation, newField]);
  };

  const handleDeleteClick = (index) => {
    console.log('deleteQuote');

    const newValues = values.logisticInformation.filter((_, i) => i !== index);
    setFieldValue('logisticInformation', newValues);
  };

  useEffect(() => {
    console.log('useEffectquote');

    if (!values.logisticInformation || values.logisticInformation.length === 0) {
      setFieldValue('logisticInformation', [
        {
          supplier: '',
          currency: '',
          totalValue: '',
          transitTime: '',
          freeTime: '',
          expiresAt: '',
        },
      ]);
    }
  }, []);

  return (
    <Flex m="20px" gap="20px" direction="column">
      <Text textStyle="cardTitle">Cotação</Text>

      {values.logisticInformation.map((entity, index) => (
        <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 90px" gap="15px" key={index}>
          <FormControl>
            <LabelDefault text="Fornecedor" />

            <InputDefault
              placeholder="Nome do Fornecedor"
              name={`logisticInformation[${index}].supplier`}
              id={`logisticInformation[${index}].supplier`}
              setFieldValue={setFieldValue}
              value={values.logisticInformation[index]?.supplier}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].supplier`} />
          </FormControl>

          <FormControl>
            <LabelDefault name="currency" text="Moeda" />

            <Select
              styles={selectCustomStyles()}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              placeholder="Selecione"
              name={`logisticInformation[${index}].currency`}
              id={`logisticInformation[${index}].currency`}
              options={currency}
              isLoading={isLoadCurrency}
              onChange={(option) => setFieldValue(`logisticInformation[${index}].currency`, option)}
              value={currency.find((value) => value?.value === values.logisticInformation[index]?.currency?.value)}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].currency`} />
          </FormControl>

          <FormControl>
            <LabelDefault name="totalValue" text="Valor total" />

            <NumericFormat
              placeholder="R$0,00"
              className="custom-input-mask"
              type="text"
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              thousandsGroupStyle="thousand"
              decimalScale={2}
              allowNegative={true}
              fixedDecimalScale={true}
              id="logisticInformation.totalValue"
              name={`logisticInformation[${index}].totalValue`}
              onValueChange={(values) => {
                const { floatValue } = values;
                setFieldValue(`logisticInformation[${index}].totalValue`, floatValue);
              }}
              value={values.logisticInformation[index]?.totalValue}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].totalValue`} />
          </FormControl>

          <FormControl>
            <LabelDefault name="transitTime" text="Transit Time" />

            <InputDefault
              placeholder="Nome do Transit Time"
              name={`logisticInformation[${index}].transitTime`}
              setFieldValue={setFieldValue}
              value={values.logisticInformation[index]?.transitTime}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].transitTime`} />
          </FormControl>

          <FormControl>
            <LabelDefault name="freeTime" text="Free Time" />

            <InputDefault
              placeholder="Nome do Free Time"
              name={`logisticInformation[${index}].freeTime`}
              setFieldValue={setFieldValue}
              value={values.logisticInformation[index]?.freeTime}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].freeTime`} />
          </FormControl>

          <FormControl>
            <LabelDefault text="Validade" />

            <Input
              bg="#F2F2F2"
              borderColor="#70707033"
              type="date"
              cursor="pointer"
              name={`logisticInformation[${index}].expiresAt`}
              onChange={handleChange}
              value={values.logisticInformation[index]?.expiresAt ? moment(values.logisticInformation[index]?.expiresAt).format('Y-MM-DD') : ''}
            />

            <ErrorMessage component="p" className="error-message-error" name={`logisticInformation[${index}].expiresAt`} />
          </FormControl>

          <Flex justify="space-between" align="end">
            {index === values.logisticInformation.length - 1 && (
              <Tooltip label="Adicionar">
                <Box>
                  <IconButton
                    borderRadius="full"
                    bgColor="#2ECC7152"
                    border="1px dashed #2ECC71"
                    icon={<MdAdd color="#2ECC71" />}
                    onClick={() => handleAddClick()}
                  />
                </Box>
              </Tooltip>
            )}

            {values?.logisticInformation.length > 1 && (
              <Tooltip label="Excluir">
                <Box>
                  <IconButton icon={<MdDelete color="#E52359" />} onClick={() => handleDeleteClick(index)} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Grid>
      ))}
    </Flex>
  );
};

export default FormQuote;
