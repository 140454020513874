import React, { useEffect, useState } from 'react';


import {
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

import SlaData from './SlaData';

const ModalManagementClient = ({
  identifier,
  handleClose,
  isOpen,
}) => {
  const [entity, setEntity] = useState({});
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);

  const getEntity = () => {
    setIsLoadingEntity(true);

    requests
      .showClient(identifier)
      .then((data) => {
        setEntity(data);
        setIsLoadingEntity(false);
      })
      .catch((error) => {
        setIsLoadingEntity(false);
      });
  };

  useEffect(() => {
    if (identifier !== '') {
      getEntity(identifier);
    }
  }, [identifier]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />

        <ModalContent borderRadius="5px" maxW="none" w="80vw">
          <ModalHeader px="20px" py="10px">
            <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76">
              Informações do cliente
            </Text>
          </ModalHeader>

          <Tabs isFitted position="relative" variant="unstyled">
            <TabList justifyContent="flex-start" pl="20px" borderBottom="#70707036 solid 1px" flexWrap={'wrap'}>
              <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                <Text fontSize="14px">Gerenciamento de SLA</Text>
              </Tab>
            </TabList>

            <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />

            <TabPanels>
              <TabPanel p="0px">
                {isLoadingEntity ? (
                  <Flex justify="center" my="25px">
                    <span>Carregando...</span>
                  </Flex>
                ) : (
                  <>
                    <SlaData
                      entity={entity}
                      getEntity={getEntity}
                      handleClose={handleClose}
                    />
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalManagementClient;
