import React, { useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdHelp, MdMenu } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';

const DragAndDropConfigFilter = ({ setColumnData, columnData }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const fieldsAux = reorder(columnData, result.source.index, result.destination.index);
    setColumnData(fieldsAux);
  };

  const onDragEndChildrens = (result, fatherIndex) => {
    if (!result.destination) {
      return;
    }

    const fieldsAux = reorder(columnData[fatherIndex].subFields, result.source.index, result.destination.index);
    const aux = [...columnData];
    aux[fatherIndex].subFields = fieldsAux;
    setColumnData(aux);
  };

  const handleMainCheckboxChange = (index) => {
    setColumnData((prevFilterFields) => {
      const newFilterFields = [...prevFilterFields];
      const currentField = newFilterFields[index];

      const newActiveStatus = !currentField.active;

      currentField.active = newActiveStatus;
      currentField.subFields = currentField.subFields.map((subField) => ({
        ...subField,
        active: newActiveStatus,
      }));

      return newFilterFields;
    });
  };

  const handleSubCheckboxChange = (mainIndex, subIndex) => {
    setColumnData((prevFilterFields) => {
      const newFilterFields = [...prevFilterFields];
      const currentSubField = newFilterFields[mainIndex].subFields[subIndex];

      currentSubField.active = !currentSubField.active;

      return newFilterFields;
    });
  };

  const [containerWidth, setContainerWidth] = useState('60%');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 760) {
        setContainerWidth('100%');
      } else {
        setContainerWidth('60%');
      }
    };

    handleResize(); // Set initial width

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Flex gap="5px" direction="column">
      <FormControl direction="column">
        <Flex>
          <FormLabel mr="3px" fontWeight="bold">
            Campos/colunas da tabela
          </FormLabel>

          <Tooltip label="Primeiramente, selecione as colunas a serem exibidas e, em seguida, os campos desejados.">
            <Box>
              <MdHelp color="#422C76" />
            </Box>
          </Tooltip>
        </Flex>
      </FormControl>

      <Accordion allowToggle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul
                className="characters"
                style={{
                  border: '1px solid #A885F99C',
                  padding: '20px',
                  overflowY: 'auto',
                  height: '332px',
                  width: containerWidth,
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {columnData.map((field, index) => {
                  return (
                    <Draggable id={field.id} key={field.id} draggableId={field.id} index={index}>
                      {(provided) => (
                        <AccordionItem border="none">
                          <li ref={provided.innerRef} {...provided.draggableProps}>
                            <Flex bgColor="#EEEEEE" p="12px" align="center" justify="space-between">
                              <Flex>
                                <AccordionButton w="min-content" _hover={{ bgColor: 'none' }} p="0">
                                  <AccordionIcon color="#422C76" />
                                </AccordionButton>

                                <Checkbox
                                  iconColor="primary"
                                  ml="10px"
                                  onChange={() => handleMainCheckboxChange(index)}
                                  isChecked={field.active}>
                                  <Text>{field.name}</Text>
                                </Checkbox>
                              </Flex>

                              <Flex {...provided.dragHandleProps}>
                                <MdMenu size={22} />
                              </Flex>
                            </Flex>

                            <DragDropContext onDragEnd={(result) => onDragEndChildrens(result, index)}>
                              <Droppable droppableId="childrens">
                                {(provided) => (
                                  <ul
                                    className="childrens"
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                      flexDirection: 'column',
                                      border: 'none',
                                      paddingBottom: '10px',
                                    }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {field.subFields &&
                                      field.subFields.map((subField, subIndex) => {
                                        return (
                                          <Draggable id={subField.id} key={subField.id} draggableId={subField.id} index={subIndex}>
                                            {(provided) => (
                                              <AccordionPanel pl="30px" p="0px" w="full" minW={{ sm: '200px', xx: '400px' }} mt="10px">
                                                <li ref={provided.innerRef} {...provided.draggableProps}>
                                                  <Flex bgColor="#EEEEEE" p="12px" align="center" justify="space-between">
                                                    <Checkbox
                                                      iconColor="primary"
                                                      onChange={() => handleSubCheckboxChange(index, subIndex)}
                                                      isChecked={subField.active}>
                                                      <Text>{subField.name}</Text>
                                                    </Checkbox>

                                                    <Flex {...provided.dragHandleProps}>
                                                      <MdMenu size={22} />
                                                    </Flex>
                                                  </Flex>
                                                </li>
                                              </AccordionPanel>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </li>
                        </AccordionItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Accordion>
    </Flex>
  );
};

export default DragAndDropConfigFilter;
