import React, { useEffect, useRef, useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CollectionStatusChart from '../../../components/Charts/Warehouse/CollectionStatusChart';
import OrderProgressChart from '../../../components/Charts/Warehouse/OrderProgressChart';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import FilterOption from '../../../utils/filters/FilterOption';
import { filterOptionsWarehousePicking } from '../../../utils/filters/filterPresets';

import PickingPackingTable from './components/PickingPackingTable';
import PickingPackingTotalProducts from './components/PickingPackingTotalProducts';

const PickingPackingPage = () => {
  const hasPermission = permissions.warehousePickingAndPacking;

  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [list, setList] = useState([]);
  const [chartData, setChartData] = useState({});
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [statusGrafico, setStatusGrafico] = useState(null);
  const [collectionStatus, setCollectionStatus] = useState([]);

  const [sorting, setSorting] = useState(null);

  const load = (filters, key, page) => {
    setIsLoading(true);

    filters.type_storage = 'picking';

    var filters = {
      ...filters,
      statusGrafico,
    };

    requests
      .listPickingPack(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    filters.type_storage = 'picking';

    requests
      .dashboardPickingPack(filters, 'picking')
      .then((data) => {
        if (requestChart.current && requestChart.current > key) {
          return;
        }
        requestChart.current = key;

        setChartData(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABActionPickingPacking = (filters, key) => {
    //type - optional
    filters.type_storage = 'picking';

    var filters = {
      ...filters,
      statusGrafico,
    };

    //preset
    const endpoint = `/pickingepacking/export/`;
    const fileName = `PickingPacking`;
    const extension = `xlsx`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionPickingPackingExpedicao = (filters, key) => {
    //type - optional
    filters.type_storage = 'both';

    //preset
    const endpoint = `/pickingepacking/export/`;
    const fileName = `PickingPacking-e-Expedicao`;
    const extension = `xlsx`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehousePicking());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    const filteredChildren = filterOptions.filter((item) => item instanceof FilterOption).map((item) => item.children);

    setCollectionStatus(filteredChildren);
  }, [filterOptions]);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico]);

  const chartsView = [
    {
      title: 'Status dos pedidos',
      content: <CollectionStatusChart data={chartData} setStatusGrafico={setStatusGrafico} />,
    },
    {
      title: 'Status de coleta',
      content: <OrderProgressChart data={chartData} collectionStatus={collectionStatus} filterOptions={filterOptions} type="status" />,
    },
    {
      title: 'Soma dos valores fiscais',
      content: <PickingPackingTotalProducts data={chartData} />,
    },
  ];

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  return (
    <Page
      screen="warehouse-picking"
      title="Picking e Packing"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      hasChartChanges={hasChartChanges}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Exportar Picking & Packing',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing',
          action: exportListFABActionPickingPacking,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="10px" m="10px">
        <Flex w="full" gap="10px" h={{ base: 'initial', md: '345px' }}>
          {!isMobile ? (
            chartsView.map((item, index) => {
              return (
                <Card w="full" title={item.title} key={index}>
                  <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                </Card>
              );
            })
          ) : (
            <Accordion w="full" gap="10px" display="grid">
              {chartsView.map((item, index) => {
                return (
                  <AccordionItem key={index}>
                    <Card w="full">
                      <AccordionButton display="flex" justifyContent="space-between">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <ScreenLoader isLoading={isLoadingChart}>
                        <AccordionPanel>{item.content}</AccordionPanel>
                      </ScreenLoader>
                    </Card>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Flex>

        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <Box overflow="auto" h="calc(100vh - 220px)">
                <ScreenLoader isLoading={isLoading}>
                  <PickingPackingTable list={list} handleSort={handleSort} sorting={sorting} onSave={() => triggerRefresh()} />
                </ScreenLoader>
              </Box>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>
          <ScreenLoader isLoading={isLoading}>
            <Box overflowX="auto" m="1rem">
              <PickingPackingTable list={list} handleSort={handleSort} sorting={sorting} onSave={() => triggerRefresh()} />
            </Box>
          </ScreenLoader>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default PickingPackingPage;
