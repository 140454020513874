import React, { useEffect, useRef } from 'react';

import { Image } from '@chakra-ui/react';

import off from '../../assets/images/toggle_off.svg';
import on from '../../assets/images/toggle_on.svg';

/**
 *
 * @param status bool (representando ativo ou desativado)
 * @returns uma imagem representando o estado do toggle
 */
const Toggle = ({ status = false, onClick }) => {
  const toggleRef = useRef(null);

  useEffect(() => {
    const toggleElement = toggleRef.current;
    if (toggleElement) {
      // Adiciona a classe .toggle-click após 200ms
      toggleElement.classList.add('toggle-click');
      setTimeout(() => {
        // Remove a classe .toggle-click após 400ms
        toggleElement.classList.remove('toggle-click');
      }, 400);
    }
  }, [status]);

  return <Image src={status ? on : off} onClick={onClick} ref={toggleRef} />;
};

export default Toggle;
