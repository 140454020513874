import React from 'react';

import { MdOutlineExpandLess, MdOutlineExpandMore, MdOutlineUnfoldMore } from 'react-icons/md';

import { Flex, Text } from '@chakra-ui/react';

const ArrowIcon = ({ isActive, direction }) => {
  if (!isActive) {
    return <MdOutlineUnfoldMore size={20} />;
  }
  return direction === 'ASC' ? <MdOutlineExpandMore size={20} /> : <MdOutlineExpandLess size={20} />;
};

const SortableListHeader = ({ children, ...props }) => {
  const column = props?.column;
  const label = props?.label;
  const sorting = props?.sorting;
  const handleSort = props?.handleSort;

  const isActive = sorting?.column === column;

  return column ? (
    <Flex
      direction="row"
      align="center"
      onClick={() => handleSort(column, isActive && sorting?.direction === 'ASC' ? 'DESC' : 'ASC')}
      cursor="pointer">
      <Text>{label}</Text>
      <ArrowIcon isActive={isActive} direction={sorting?.direction} />
    </Flex>
  ) : (
    <Text>{label}</Text>
  );
};

export default SortableListHeader;
