import validateCPF from '../../../../utils/fields/validateCPF';

const yup = require('yup');

export const userValidation = (entity = {}) => {
  return yup.object().shape({
    cellphone: yup.string(),
    phone: yup.string(),
    name: yup.string().required('Campo obrigatório'),
    documentNumber: yup.string()
      .required('Campo obrigatório')
      .test('cpf-validate', 'CPF inválido', (value) => {
        // Se o campo estiver vazio, não execute a validação
        if (!value) {
          return true;
        }
        return validateCPF(value);
      }),
    password: entity?.identifier
      ? yup.string()
      : yup.string().required('Campo obrigatório').min(8, 'A senha deve conter no mínimo 8 caracteres'),
    email: yup.string().required('Campo obrigatório').email('Deve ser um e-mail válido').trim(),
    additional: yup.object().shape({
      cnh: yup.string().required('Campo obrigatório'),
    })
  });
};

export const userInitialValues = (entity = undefined) => {
  return ({
    name: entity?.name || "",
    email: entity?.email || "",
    phone: entity?.phone || "",
    cellphone: entity?.cellphone || "",
    documentNumber: entity?.documentNumber || "",
    role:
    {
      slug: 'driver',
      label: 'Motorista',
      value: 'ROLE_DRIVER',
    },
    password: "",
    additional:
      {
        cnh: entity?.additional?.cnh || "",
        rg: entity?.additional?.rg || "",
        birthday: entity?.additional?.birthday || "",
        zipcode: entity?.additional?.zipcode || "",
        location: entity?.additional?.address || "",
        number: entity?.additional?.number || "",
        complement: entity?.additional?.complement || "",
        neighborhood: entity?.additional?.neighborhood || "",
        city: entity?.additional?.city || "",
        state: entity?.additional?.state || "",
      }
  });
}