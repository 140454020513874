import React from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Box, Collapse, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

const StockList = ({ entity, count }) => {
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowDown color="#707070" /> : <MdKeyboardArrowRight color="#707070" />;

  const handleColor = (status, type) => {
    if (type === 1) {
      switch (status) {
        case 'A':
          return '#2ECC71';
        case 'B':
          return '#F1C40F';
        case 'C':
          return '#E74C3C';
        default:
          return '';
      }
    } else {
      if (status === '1' || status === 'LIBERADO') {
        return '#2ECC71';
      } else {
        return '#E74C3C';
      }
    }
  };

  const handleColorABC = (status) => {
    switch (status) {
      case 'A':
        return '#2ECC71';
      case 'B':
        return '#F1C40F';
      default:
        return '#E74C3C';
    }
  };

  const handleText = (text) => {
    switch (text) {
      case '1':
      case 'LIBERADO':
        return 'Normal';
      case '2':
        return 'Danificado';
      default:
        return '';
    }
  };
  return (
    <>
      <style>
        {`
          body .table-default:not(.collapse) tbody tr.bg_color:nth-child(2n + 2) {
            background-color: #f9f9f9 !important;
          }

          body .table-default:not(.collapse) tbody tr:hover, .table-default:not(.collapse) tbody tr:nth-child(2n + 1):hover {
            background-color: initial;
          }

          .table-default thead, .table-default tbody{
            border: 0;
          }

          body div div .table-default tbody tr {
            background-color: transparent !important;
            border: 0.5px solid #70707036;
            border-radius: 20px;
          }

          body .group_stock{
            border-top: 1px solid #ccc;
          }
        `}
      </style>
      <Tr className="bg_color" onClick={onToggle} cursor="pointer" border="none" style={{ '--delay': `${count / 10}s` }}>
        <Td>
          <Box>{Icon}</Box>
        </Td>
        <Td>{entity.codProduto ?? '-'}</Td>
        <Td>{entity.setor ?? '-'}</Td>
        <Td>
          <Text color={handleColor(entity?.situacao, 2)}>{handleText(entity?.situacao) ?? '-'}</Text>
        </Td>
        {entity?.situacao === '1' || entity?.situacao === 'LIBERADO' ? (
          <>
            <Td>{entity?.qtdeEstoqueDisponivelFormatted}</Td>
            <Td>{entity?.qtdeSeparacaoFormatted}</Td>
            <Td>{entity?.qtdeEstoqueFormatted}</Td>
            <Td textAlign="center">-</Td>
          </>
        ) : (
          <>
            <Td textAlign="center">-</Td>
            <Td textAlign="center">-</Td>
            <Td textAlign="center">-</Td>
            <Td>{entity?.qtdeAvariadaFormatted}</Td>
          </>
        )}
        <Td>{entity?.totalValueFormatted ?? '-'}</Td>
        <Td>{entity?.idLoteFabricacao ?? '-'}</Td>
        <Td>{entity?.stateCodeFormatted ?? '-'}</Td>
        <Td>{entity?.dtVencimentoFormatted ?? '-'}</Td>
        <Td>
          <Flex
            align="center"
            borderRadius="15px"
            justifyContent="center"
            bgColor={handleColorABC(entity?.inAbc)}
            color="white"
            w="4rem"
            h="2rem">
            {entity?.inAbc ?? '-'}
          </Flex>
        </Td>
        <Td>
          {entity?.clientName?.length > 30 ? (
            <Tooltip label={entity.clientName}>{limitChars(entity.clientName, 30)}</Tooltip>
          ) : (
            entity.clientName ?? '-'
          )}
        </Td>
      </Tr>
      <Tr w="full">
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen} animateOpacity>
            <Box /*  bgColor="#F9F9F9" */ m="1.2rem">
              <Table>
                <Thead border="0" borderBottom="1px" h={15}>
                  <Tr>
                    <Th>Descrição: </Th>
                  </Tr>
                </Thead>
                <Tbody borderBottom="1px">
                  <Tr>
                    <Td>
                      {entity?.descProduto?.length > 50 ? (
                        <Tooltip label={entity.descProduto}>{limitChars(entity.descProduto, 50)}</Tooltip>
                      ) : (
                        entity.descProduto ?? '-'
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default StockList;
