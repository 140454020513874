import React, { useEffect, useState } from 'react';

import { AiFillEdit } from 'react-icons/ai';
import { FaCheckDouble } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { ProgressBar } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import NoPicture from '../../../assets/images/icons/driver-without-picture.png';
import pointStatus from '../../../assets/svgs/trip/pointStatus.svg';
import truckIcon from '../../../assets/svgs/trip/truckIcon.svg';
import Card from '../../../components/Card/Card';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import DefaultCommentModal from '../../../components/Modal/Template/DefaultCommentModal';
import DefaultModal from '../../../components/Modal/Template/DefaultModal';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import requests from '../../../services/requests';
import { driver, processCheck, processUncheck } from '../../../utils/exports';
import { limitChars } from '../../../utils/strings';
import { descriptionStatusTrip, getStatusTransition } from '../../../utils/translate';
import ModalFreight from '../TravelManagement/components/TravelList/ModalFreight';

import CommentTab from './components/Comment/CommentTab';
import DocumentTab from './components/Document/DocumentTab';
import EventsTab from './components/Events/EventsTab';
import InformationTab from './components/Information/InformationTab';
import MapTrajectory from './components/Map/MapTrajectory';
import TrackingTab from './components/Tracking/TrackingTab';
import TrajectoryTab from './components/Trajectory/TrajectoryTab';

const TravelDetailPage = ({ percent = 40 }) => {
  // const steps = [
  //   { accomplished: false, icon: pointStatus },
  //   { accomplished: false, icon: pointStatus },
  //   { accomplished: false, icon: pointStatus },
  //   { accomplished: false, icon: pointStatus },
  //   { accomplished: false, icon: pointStatus },
  // ];

  // const totalSteps = steps.length;

  const { identifier } = useParams();
  let { state } = useLocation();

  let { viewport } = useResponsiveCheck();

  const [entity, setEntity] = useState(state);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsFull, setEventsFull] = useState([]);
  const [checkpoints, setCheckpoints] = useState([]);
  const [lastVersionApp, setLastVersionApp] = useState('Não encontrado');
  const [additionalInformation, setAdditionalInformation] = useState({});

  const [tripPercentage, setTripPercentage] = useState();

  const [enabled, setEnabled] = useState(false);

  const [load, setLoad] = useState(false);
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { isOpen: isOpenFinish, onOpen: onOpenFinish, onClose: onCloseFinish } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const [action, setAction] = useState(1);
  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });
  const [zoom, setZoom] = useState(5);
  let profileRole = localStorage.getItem('profile-role');

  const onSubmit = async (action, description, onClose) => {
    setLoad(true);

    requests
      .addFreightComment(entity.identifier, { description: description })
      .then(async () => {
        toast.success(`Comentário da viagem #${entity?.code} enviado com sucesso!`);
        requests
          .changeStatusDriverFreight(entity?.identifier, action)
          .then((response) => {
            toast.success(`Viagem #${entity?.code} alterada com sucesso`);
            getEntity();
            onClose();
            setLoad(false);
          })
          .catch(() => {
            toast.error(`Problemas ao alterar a viagem #${entity?.code}, tente novamente ou entre em contato com o Administrador`);
            setLoad(false);
          });
      })
      .catch(() => {
        toast.error(`Erro ao enviar o comentário da viagem #${entity?.code} , tente novamente.`);
        setLoad(false);
      });
  };

  const getEntity = () => {
    setIsLoadingEntity(true);
    requests
      .showDriverFreight(identifier)
      .then((response) => {
        setEntity(response);
        setIsLoadingEntity(false);
      })
      .catch(() => {
        setIsLoadingEntity(false);
      });
  };

  const generateVersion = () => {
    return `Versão do APP: ${lastVersionApp}`;
  };

  const getEvents = () => {
    setIsLoadingEvents(true);
    requests
      .showEventsDriverFreight(identifier)
      .then((response) => {
        setEvents(response);
      })
      .finally(() => {
        setIsLoadingEvents(false);
      });
  };

  const getEventsFull = () => {
    // setIsLoadingEventsFull(true);
    requests
      .showEventsDriverFreight(identifier, 'full')
      .then((response) => {
        setEventsFull(response);
      })
      .finally(() => {
        // setIsLoadingEventsFull(false);
      });
  };

  const getCheckpoints = () => {
    setIsLoadingCheckpoints(true);
    requests
      .showCheckpointsDriverFreight(entity.identifier)
      .then((response) => {
        setAdditionalInformation(response?.additional);

        if (response?.checkpoints?.length > 0) {
          setCheckpoints(response?.checkpoints);

          if (entity?.modality == 'APP_MOTORISTA' && response?.lastVersionApp != null) {
            setLastVersionApp(response?.lastVersionApp);
          }
        }
      })
      .finally(() => {
        setIsLoadingCheckpoints(false);
      });
  };

  const handleClickChangeStatus = (event) => {
    setIsLoadingEntity(true);
    let transition = getStatusTransition(entity?.status, event?.target?.value);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      setIsLoadingEntity(false);
      return;
    }

    requests
      .changeStatusDriverFreight(entity?.identifier, transition)
      .then((response) => {
        toast.success(
          `Status alterado de ${descriptionStatusTrip(entity?.status)} para ${descriptionStatusTrip(event?.target?.value)}`
        );
        window.location.reload();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status da viagem, tente novamente ou entre em contato com o Administrador');
        setIsLoadingEntity(false);
      });
  };

  const checkStatus = (action, status) => {
    const cancel = ['DRAFT', 'CREATED', 'SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
    const complete_travel = [
      'DELIVERED_WITH_SIGNATURE',
      'DELIVERED_WITHOUT_SIGNATURE',
      'SENT_TO_DRIVER',
      'ACCEPTED_BY_DRIVER',
      'TRAVELLING',
    ];

    switch (action) {
      case 'cancel':
        return cancel.indexOf(status) !== -1 ? true : false;
      case 'complete_travel':
        return complete_travel.indexOf(status) !== -1 ? true : false;
      default:
        return false;
    }
  };

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return 'Não informado';
    }
  };

  const plates = checkPlate();

  useEffect(() => {
    getEntity();
  }, [action]);

  useEffect(() => {
    if (entity && entity.identifier) {
      getEvents();
      getCheckpoints();

      if (profileRole === 'ROLE_SYSTEM_ADMIN') {
        getEventsFull();
      }
    }
  }, [entity]);

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.percentageCompletionDriverFreight(identifier);
      setTripPercentage(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  useEffect(() => {
    fetchPercentageCompletion(identifier);
  }, []);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: state?.historyBack ?? '/logistic/trip', title: 'Viagens' },
        ]}
        placeholder="Pesquisar viagens..."
        title={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        icon={driver}
        hideAllFilters={true}
        isRefreshLoading={isLoadingEntity}
        triggerRefreshData={() => getEntity()}
        showRefreshData={true}
        refreshSeconds={300}
      />
      <ScreenLoader isLoading={isLoadingEntity}>
        <Grid
          mx="20px"
          gap="10px"
          templateColumns={viewport > '1300' ? '1fr 1.5fr' : '1fr'}
          templateRows={viewport > '1300' ? '1fr' : '1fr 1.2fr'}>
          <Flex order={viewport > '1300' ? 1 : 2} direction="column" gap="10px" h="full">
            <Card p="20px" h="260px" minW="600px">
              <Flex direction="column" h="full" w="full" justify="space-between">
                <Flex align="center" gap="20px">
                  {entity?.userDriverName && entity?.userDriverPicture ? (
                    <Image
                      src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                      alt="Foto de perfil do motorista"
                      w="80px"
                      h="80px"
                      objectFit="cover"
                    />
                  ) : (
                    <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                  )}

                  <Flex direction="column">
                    <Text fontSize="1.5rem" fontWeight="bold" color="primary" overflow="ellips">
                      {isLoadingEntity ? (
                        '...'
                      ) : (
                        <>
                          <Tooltip label={entity?.userDriverName}>
                            {entity?.userDriverName ? limitChars(entity?.userDriverName, 40) : 'Motorista não identificado'}
                          </Tooltip>
                        </>
                      )}
                    </Text>

                    <Flex gap="4px" align="center" textColor="primary">
                      {entity?.userDriverTrips} {entity?.userDriverTrips === 1 ? 'Viagem realizada' : 'Viagens realizadas'}
                    </Flex>
                  </Flex>
                </Flex>

                {entity && entity.userDriverName && (
                  <>
                    <Flex gap="20px">
                      <Flex direction="column">
                        <Text fontSize="13px">Id Viagem</Text>

                        <Text fontSize="22px" fontWeight="bold">
                          #{entity?.code}
                        </Text>
                      </Flex>

                      <Flex direction="column">
                        <Text fontSize="13px">Veículo</Text>

                        <Text fontSize="22px" fontWeight="bold">
                          {plates?.length > 16 ? <Tooltip label={plates}>{limitChars(plates, 16)}</Tooltip> : limitChars(plates, 16)}
                        </Text>
                      </Flex>

                      <Flex direction="column">
                        <Text fontSize="13px">Transportadora</Text>

                        <Text fontSize="22px" fontWeight="bold">
                          {isLoadingEntity ? '...' : <>{entity?.shippingCompanyName}</>}
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                )}

                {entity && (
                  <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
                    <Flex
                      w="fit-content"
                      p="7px 11px"
                      border="1px"
                      borderColor="#2ECC71"
                      color="#2ECC71"
                      borderRadius="10px"
                      gap="5px">
                      Pegada CO₂:
                      {entity?.carbonTrack?.calculated.toLocaleString('pt-BR', {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                      t
                      {entity?.carbonTrack?.retired > 0 ? (
                        <Image src={processCheck} opacity={'1'} />
                      ) : (
                        <Image src={processUncheck} opacity={'1'} />
                      )}
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
            </Card>

            {profileRole !== 'ROLE_CLIENT' &&
              entity?.modality != 'MANUAL' &&
              (entity?.status === 'DRAFT' ||
                checkStatus('cancel', entity?.status) ||
                checkStatus('complete_travel', entity?.status)) && (
                <Card p="20px" h="auto" gap="40px">
                  <Flex justify="space-between" align="center">
                    <Flex gap="10px">
                      <Flex bgColor="linkColor" p="10px 2px" borderRadius="2px"></Flex>

                      <Flex direction="column">
                        <Text fontSize="20px" fontWeight="bold">
                          Status da viagem
                        </Text>

                        <Text textColor="primary" fontSize="16px" opacity="1">
                          Alterar status manualmente
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex align="center" gap="1.8rem">
                      {entity?.status == 'DRAFT' && (
                        <Button
                          leftIcon={<AiFillEdit color="#FFFFFF" size={16} />}
                          _hover={{ bgColor: '#33225C' }}
                          shadow="xl"
                          p="1.3rem"
                          pb="1.3rem"
                          pl="1rem"
                          pr="1rem"
                          borderRadius="10px"
                          bg="primary"
                          onClick={(decision) => {
                            if (decision) {
                              onOpenEdit();
                            }
                          }}>
                          <Text mr="10px" color="white">
                            Editar
                          </Text>
                        </Button>
                      )}

                      {checkStatus('cancel', entity?.status) && (
                        <DefaultCommentModal
                          action="cancel"
                          title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                          txtButton="Cancelar"
                          textButtonConfirm="Salvar"
                          icon={<MdCancel color="#FFFFFF" size={16} />}
                          load={load}
                          onSubmit={onSubmit}
                          isOpen={isOpenCancel}
                          onOpen={onOpenCancel}
                          onClose={onCloseCancel}
                          background="red"
                          hoverColor="#B53C2F"
                        />
                      )}

                      {checkStatus('complete_travel', entity?.status) && (
                        <DefaultCommentModal
                          action="complete_travel"
                          title={`Você tem certeza que deseja finalizar a viagem #${entity?.code}?`}
                          txtButton="Finalizar"
                          textButtonConfirm="Salvar"
                          icon={<FaCheckDouble color="#FFFFFF" size={16} />}
                          load={load}
                          onSubmit={onSubmit}
                          isOpen={isOpenFinish}
                          onOpen={onOpenFinish}
                          onClose={onCloseFinish}
                          hoverColor="#26A65B"
                        />
                      )}
                    </Flex>
                  </Flex>

                  {/* <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px">
                    {steps.map((step, index) => {
                      const stepStartPercent = (index * 100) / totalSteps;
                      const stepEndPercent = ((index + 1) * 100) / totalSteps;

                      const isCurrentStep = tripPercentage >= stepStartPercent && tripPercentage <= stepEndPercent;
                      const isCompletedStep = tripPercentage >= stepEndPercent;

                      return (
                        <Step key={index} transition="scale">
                          {({ accomplished }) => (
                            <>
                              {console.log(accomplished)}
                              <Tooltip label={`${tripPercentage}%`} isDisabled={!isCurrentStep}>
                                <img
                                  src={isCurrentStep ? truckIcon : pointStatus}
                                  style={{
                                    width: '20px',
                                    filter: isCompletedStep || isCurrentStep ? 'none' : 'grayscale(100%)',
                                    transform: isCurrentStep ? 'rotate(90deg)' : 'rotate(0deg)',
                                  }}
                                  alt={isCurrentStep ? 'Current step' : 'Completed step'}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Step>
                      );
                    })}
                  </ProgressBar> */}

                  <div style={{ position: 'relative', width: '100%', height: '0px' }}>
                    <ProgressBar percent={tripPercentage} filledBackground="#6C48C2" height="3px" />

                    {/* Ícone no início */}
                    <img
                      src={pointStatus}
                      style={{
                        position: 'absolute',
                        top: '-9px',
                        left: '-10px',
                        width: '20px',
                      }}
                      alt="Starting Point"
                    />

                    {/* Imagem do caminhão seguindo o progresso */}
                    <Tooltip label={`${tripPercentage}%`}>
                      <img
                        src={truckIcon}
                        style={{
                          width: '24px',
                          position: 'absolute',
                          top: '-43px',
                          left: `calc(${tripPercentage}% - 12px)`,
                          transition: 'left 0.5s ease',
                          transform: 'rotate(90deg)',
                        }}
                        alt="Truck Icon"
                      />
                    </Tooltip>

                    {/* Ícone no fim */}
                    <img
                      src={pointStatus}
                      style={{
                        position: 'absolute',
                        top: '-9px',
                        right: '-10px',
                        width: '20px',
                        filter: 'grayscale(100%)',
                      }}
                      alt="Ending Point"
                    />
                  </div>
                </Card>
              )}

            {entity?.modality == 'MANUAL' && (
              <Card p="20px">
                <Flex align="center" gap="1.8rem">
                  <Flex justify="space-between">
                    <Flex bgColor="linkColor" p="10px 2px" ml={4} borderRadius="2px"></Flex>

                    <Box ml={4}>
                      <Text fontSize={'1.375rem'} fontWeight={'bold'}>
                        Status da viagem
                      </Text>
                      <Text fontSize={'1rem'}>Alterar status manualmente</Text>
                    </Box>
                  </Flex>
                  <Select
                    variant="primary"
                    w="fit-content"
                    name="status"
                    defaultValue={entity?.status}
                    isDisabled={entity?.statusAvailable?.length === 1}
                    onChange={(event) => handleClickChangeStatus(event)}>
                    {entity &&
                      entity?.statusAvailable?.length > 0 &&
                      entity.statusAvailable.map((status, key) => {
                        return (
                          <>
                            <option key={key} value={status.description}>
                              {status.translated}
                            </option>
                          </>
                        );
                      })}
                  </Select>
                </Flex>
              </Card>
            )}

            <Tabs position="relative" variant="unstyled" overflow="hidden" isFitted>
              <Card
                h={viewport > '1300' ? 'calc(100vh - 450px)' : '400px'}
                header={
                  <Flex position="relative" w="full">
                    <TabList w="full">
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {eventsFull && eventsFull?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="14px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Flex>
                }>
                <Flex overflowY="auto">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {eventsFull && eventsFull.length > 0 && <EventsTab events={eventsFull} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Flex>
              </Card>
            </Tabs>
          </Flex>

          {!isLoadingEntity && entity && (
            <MapTrajectory
              viewport={viewport}
              w="100%"
              identifier={identifier}
              entity={entity}
              events={events}
              checkpoints={checkpoints}
              additional={additionalInformation}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
            />
          )}
        </Grid>
      </ScreenLoader>

      <DefaultModal
        label="Reprovar"
        title={'Certeza que deseja reprovar?'}
        enabled={enabled}
        subtitle={
          <>
            Você tem certeza que deseja reprovar esses dados?
            <br /> Ao reprovar o motorista receberá uma notificação com a sua mensagem de observação.
          </>
        }
        buttonColor="red"
        txtButton="Enviar reprovação"
        callback={(decision) => {
          // if (decision) {
          //   changeStatus(identifier, 'reject', { section: section, observation: observation });
          // }
        }}
      />

      <Flex className="float-button-modal">
        <ModalFreight
          entity={entity}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          title="Edição de viagem"
          subTitle="Você pode salvar as alterações da sua viagem."
          action={action}
          setAction={setAction}
        />
      </Flex>
    </Layout>
  );
};

export default TravelDetailPage;
