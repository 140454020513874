import React, { useState } from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import formatDocument from '../../../../utils/fields/masks/formatDocument';

const CreditCard = ({ list, loadList, action, setAction, metadata, setMetadata }) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);

  const handleColor = (status) => {
    switch (status) {
      case 'CLEARED':
        return 'green';
      case 'ATTENTION':
        return 'yellow-500';
      case 'REJECTED':
        return 'red';
      default:
        return 'gray-200';
    }
  };

  return (
    <>
      <Grid>
        <Flex border="1px" borderColor={'#70707036'}>
          <Grid templateColumns={'180px 10px auto'} justifyContent={'space-between'} w="full" p={'1px 1px'} borderColor={'#70707036'}>
            <GridItem fontWeight={700}>CNPJ</GridItem>
            <GridItem justifyContent={'center'} display={'grid'} fontWeight={700}>
              <Text>Visualizar</Text>
            </GridItem>
            <GridItem> </GridItem>
          </Grid>
        </Flex>
        <Flex border="1px" borderColor={'#70707036'}>
          <Accordion w={'100%'}>
            {list?.map((entity, key) => (
              <AccordionItem key={key} style={{ '--delay': `0.${key}s` }} _odd={{ bg: '#eee' }} _hover={'none'}>
                <AccordionButton>
                  <Flex templateColumns={'1fr 40px auto'} justifyContent={'space-between'} w={'100%'}>
                    <Box textAlign={'left'} color={'#707070'}>
                      <Flex>
                        <Box mr="2">
                          <Flex
                            align="center"
                            w={'18px'}
                            h={'18px'}
                            gap="10px"
                            borderRadius="15px"
                            bgColor={handleColor(entity.status)}
                            color="white"></Flex>
                        </Box>
                        {formatDocument(entity.documentNumber) ?? '-'}
                      </Flex>
                    </Box>
                    <Box>
                      <Link to={`/vendemmia/credit-analysis/detail/${entity?.identifier}`}>
                        <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                      </Link>
                    </Box>
                    <Box textAlign={'end'}>
                      <AccordionIcon />
                    </Box>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={'15px 20px'} bg={'white'}>
                  <Grid flexDir={'column'} gridTemplateColumns={'1fr'} fontSize={'14px'}>
                    <Text>
                      <strong>Razão Social:</strong> {entity.companyName ?? '-'}
                    </Text>
                    <Text>
                      <strong>Tipo de consulta:</strong> {entity.modalityText ?? '-'}
                    </Text>
                    <Text>
                      <strong>Status:</strong> {entity.statusText ?? '-'}
                    </Text>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}

            {list?.length == 0 && (
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            )}
          </Accordion>
        </Flex>
      </Grid>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default CreditCard;
