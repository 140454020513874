import React, { useState } from 'react';

import { ImLocation } from 'react-icons/im';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Box, Grid, GridItem, IconButton, Switch, TabPanel, Tooltip, useDisclosure } from '@chakra-ui/react';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../../services/requests';
import { handleDocuments } from '../../../../../utils/documents/handleDocuments';
import ModalChangeSituation from '../Modals/ModalChangeSituation';

const TrajectoryTab = ({ entity, setCenter, setZoom, getEntity = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [trajectorySelected, setTrajectorySelected] = useState({});

  const formartType = (type, docsNumber) => {
    switch (type) {
      case 'COLETA':
        return `Coleta`;
      default:
        if (docsNumber != undefined && docsNumber != null && docsNumber?.length > 0) return docsNumber?.join(',');
        return `Entrega`;
    }
  };

  const handleClick = (identifier, type) => {
    requests
      .changeDriverFreightTrajectorySituation(identifier)
      .then(() => {
        toast.success(`${type} confirmada com sucesso`);
        getEntity();
      })
      .catch(() => {
        toast.error(`Problemas ao confirmar ${type}, tente novamente ou entre em contato com o Administrador`);
      });
  };

  return (
    <TabPanel>
      {entity?.trajectories?.length > 0 &&
        entity.trajectories.map(function (trajectory, key) {
          return (
            <Grid
              gridTemplateColumns={'60px 1fr 30px 30px'}
              gap={'20px'}
              alignItems={'center'}
              p={'16px 24px'}
              borderBottom={'1px solid #E2E8F0'}
              key={key}>
              <GridItem color="#854FFF" fontSize={14}>
                {formartType(trajectory?.type, trajectory?.docsNumber)}
              </GridItem>
              <GridItem color="#0F0A1DB3" fontSize={14}>
                <Box>{trajectory?.address}</Box>
              </GridItem>
              <GridItem color="#854FFF" fontSize={14} display="flex" gap="15">
                {trajectory?.latitude != 0 && trajectory?.longitude != 0 && (
                  <ImLocation
                    title="Origem"
                    color="#ea4335"
                    cursor="pointer"
                    size="30px"
                    onClick={(decision) => {
                      if (decision) {
                        setCenter({
                          lat: trajectory?.latitude,
                          lng: trajectory?.longitude,
                        });
                        setZoom(18);
                      }
                    }}
                  />
                )}
                {entity.modality === 'MANUAL' && (
                  <Tooltip label={`${trajectory.hasExecuted ? trajectory.type + ' confirmada' : 'Confirmar ' + trajectory.type}`}>
                    <Box>
                      <Switch
                        size="md"
                        colorScheme="teal"
                        isChecked={trajectory.hasExecuted}
                        isDisabled={trajectory.hasExecuted || entity.status !== 'CANCELLED'}
                        onChange={() => {
                          onOpen();
                          setTrajectorySelected(trajectory);
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </GridItem>
              {trajectory?.type === 'ENTREGA' && entity?.fileDeliveryIdentifier && (
                <GridItem color="#854FFF" fontSize={14} display="flex" gap="15">
                  <DefaultModal
                    label="Visualizar arquivo"
                    width="70vw"
                    height="44vw"
                    ctaButton={
                      <IconButton
                        bg="#6b6b6b"
                        p="0"
                        mr="2"
                        icon={<MdOutlineOpenInNew size={16} color="#FFFFFF" />}
                        transition="0.2s"
                        _hover={{ bgColor: '#b8b8b8' }}
                      />
                    }
                    title="Comprovante de entrega"
                    showButtons={false}>
                    {handleDocuments(`/open/freight/${entity?.identifier}/file/${entity?.fileDeliveryIdentifier}/view`, entity.name)}
                  </DefaultModal>
                </GridItem>
              )}
            </Grid>
          );
        })}
      <ModalChangeSituation
        trajectorySelected={trajectorySelected}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        callback={(decision) => {
          if (decision) {
            handleClick(trajectorySelected?.identifier, trajectorySelected?.type);
          }
        }}
      />
    </TabPanel>
  );
};

export default TrajectoryTab;
