import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { useDisclosure } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

import ModalFormPolicy from './ModalFormPolicy';

export const ModalEditPolicy = ({ onSave, entity }) => {
  const [load, setLoad] = useState(false);
  const [description, setDescription] = useState(entity.description);

  const { onClose } = useDisclosure();

  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.editPolicyPrivacy(entity.identifier, { description });

      onSave();
      setLoad(false);
      onClose();

      toast.success('Tópico editado com sucesso');
    } catch (error) {
      setLoad(false);
      onClose();

      toast.error('Erro ao editar, tente novamente!');
    }
  };

  return (
    <>
      <ModalFormPolicy
        enabled={entity.status === 'DRAFT'}
        isEdit={true}
        txtButton="Novo cadastro"
        txtHeader="Edição da Politica de Privacidade"
        textButtonConfirm="Salvar"
        onSubmit={onSubmit}
        description={description}
        handleContentChange={handleContentChange}
        load={load}
      />
    </>
  );
};

export default ModalEditPolicy;
