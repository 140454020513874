import { useEffect, useState } from 'react';

import { MdAdd, MdHistory } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import formatDocument from '../../../utils/fields/masks/formatDocument';
import { filterOptionsTaxInquiry } from '../../../utils/filters/filterPresets';

import TaxInquiryHistoryDrawer from './components/TaxInquiryHistoryDrawer';

const TaxInquiryPage = () => {
  const hasPermission = permissions.vendemmiaTaxInquiry;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [sorting, setSorting] = useState(null);

  const load = (filters, key) => {
    fetchTaxInquiryListData(filters, metadata.current_page, 25);
  };

  const fetchTaxInquiryListData = async (filters, page, pageSize) => {
    setIsLoading(true);
    await requests.fetchTaxInquiryList(filters, page, pageSize).then((data) => {
      let options = [];
      data.data.map((tax) => {
        return options.push({
          identifier: tax.identifier,
          code: tax.code ?? '-',
          companyBranchName: tax.companyBranchName ?? '-',
          clientDocumentNumber: tax?.clientDocumentNumber != null ? formatDocument(tax.clientDocumentNumber) : '-',
          importModality: tax.importModalityReadable ?? '-',
          taxBenefit: tax.taxBenefitReadable ?? '-',
          requestedAt: tax?.requestedAtFormatted,
          expiresAt: tax?.expiresAtFormatted ?? '-',
          status: tax.statusReadable,
          createdAtFormatted: tax?.createdAtFormatted ?? '-',
        });
      });
      setList(options);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const exportListFABActionCSV = (filters, key) => {
    //preset
    const extension = `csv`;
    const endpoint = `/tax-inquiry/export/${extension}`;
    const fileName = `taxs`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionExcel = (filters, key) => {
    //preset
    const extension = `xlsx`;
    const endpoint = `/tax-inquiry/export/${extension}`;
    const fileName = `taxs`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const openFABActionHistory = () => {
    onOpen();
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsTaxInquiry());
  };

  const handleSort = (column, direction) => {
    setSorting({
      column,
      direction,
    });
  };

  //component preset
  const title = 'Consulta tributária';
  const collapsedContentType = 'table';
  const maxLengthResume = 35;
  /* const emptyListMessage = 'Nenhum dado encontrado para os parâmetros filtrados'; */
  const mainCategoryMobile = 'code';
  const hiddenCategories = ['identifier', 'createdAtFormatted'];
  const detailsRedirectEndpoint = '/vendemmia/tax-inquiry/detail/${identifier}';
  /* const subCategories = ['Estado do produto', 'Código', 'Quantidade', 'Lote', 'Descrição']; */

  const categories = [
    'Consulta',
    {
      title: 'Filial Vendemia',
      field: 'e.companyBranchName',
    },
    {
      title: 'Cliente',
      field: 'e.clientDocumentNumber',
    },
    {
      title: 'Modalidade importação',
      field: 'e.importModality',
    },
    {
      title: 'Benefício fiscal',
      field: 'e.taxBenefit',
    },
    {
      title: 'Solicitação',
      field: 'e.requestedAt',
    },
    {
      title: 'Validade',
      field: 'e.expiresAt',
    },
    {
      title: 'Status',
      field: 'e.status',
    },
    'Ações',
  ];

  const tags = ['status'];

  const statusPreset = [
    { color: '#47A4F7', label: 'Em validação', value: 'Em validação' },
    { color: '#6BDD9E', label: 'Em uso', value: 'Em uso' },
    { color: '#E9C743', label: 'Em estudo', value: 'Em estudo' },
    { color: '#E74C3C', label: 'Cancelada', value: 'Cancelada' },
  ];
  //component preset

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Page
      screen="tax-inquiry"
      title="Consulta tributária"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome e CNPJ do Cliente"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      sorting={sorting}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showRefreshData={false}
      showFilters={true}
      allowFiltersOverride={false}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Link to="/vendemmia/tax-inquiry/new" key={2}>
              <Button
                leftIcon={<MdAdd color="#FFFFFF" size={20} />}
                _hover={{ bgColor: '#70D499' }}
                bg="green"
                p="1.5rem"
                pb="1.5rem"
                pl="1.3rem"
                pr="1.3rem"
                borderRadius="50px">
                <Text mr="10px" color="white">
                  Novo estudo
                </Text>
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
        {
          title: 'Baixar em CSV',
          text: 'Exportar arquivo CSV com dados de consulta tributária',
          action: exportListFABActionCSV,
          modality: 'export-csv',
        },
        {
          title: 'Baixar em Excel',
          text: 'Exportar arquivo XLSX com dados de consulta tributária',
          action: exportListFABActionExcel,
          modality: 'export-csv',
        },
        {
          title: 'Ver Historico',
          text: 'Consultar histórico da NCM',
          action: (
            <Button
              leftIcon={<MdHistory color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p="1.5rem"
              pb="1.5rem"
              pl="1.3rem"
              pr="1.3rem"
              borderRadius="50px"
              onClick={openFABActionHistory}>
              <Text mr="10px" color="white">
                Ver Historico
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        tags={tags}
        title={title}
        categories={categories}
        hiddenCategories={hiddenCategories}
        mainCategoryMobile={mainCategoryMobile}
        hasCollapsed={false}
        hasPagination={true}
        hasDetailsButton={true}
        sorting={sorting}
        handleSort={handleSort}
        statusPreset={statusPreset}
        detailsRedirectEndpoint={detailsRedirectEndpoint}
        collapsedContentType={collapsedContentType}
        maxLengthResume={maxLengthResume}
        list={list}
        //paginate
        action={action}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
      />
      {isOpen && <TaxInquiryHistoryDrawer isOpen={isOpen} onClose={onClose} />}
    </Page>
  );
};

export default TaxInquiryPage;
