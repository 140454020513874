import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Grid, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CollectionStatusChart from '../../../components/Charts/Warehouse/CollectionStatusChart';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import OrderProgressByStateChart from '../../../components/Charts/Warehouse/OrderProgressByStateChart';
import OrderProgressChart from '../../../components/Charts/Warehouse/OrderProgressChart';
import OrderTrackingChart from '../../../components/Charts/Warehouse/OrderTrackingChart';
import ProductStatusChart from '../../../components/Charts/Warehouse/ProductStatusChart';
import ProductTrackingChart from '../../../components/Charts/Warehouse/ProductTrackingChart';
import ReceiptDeadlinesChart from '../../../components/Charts/Warehouse/ReceiptDeadlinesChart';
import ShipmentAnalysisChart from '../../../components/Charts/Warehouse/ShipmentAnalysisChart';
import TotalProducts from '../../../components/Charts/Warehouse/TotalProducts';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import FilterOption from '../../../utils/filters/FilterOption';
import { filterOptionsMetricsWarehouse } from '../../../utils/filters/filterPresets';
import PickingPackingTotalProducts from '../../warehouse/picking-packing/components/PickingPackingTotalProducts';

const MetricsWarehousePage = () => {
  const hasPermission = permissions.metricsWarehouse;

  let navigate = useNavigate();

  const windowWidth = window.innerWidth;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoadingChart, setIsLoadingChart] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [dataReceipt, setDataReceipt] = useState({});
  const [dataStock, setDataStock] = useState({});
  const [dataPickingPack, setDataPickingPack] = useState({});
  const [dataExpedition, setDataExpedition] = useState({});

  const [loadReceipt, setLoadReceipt] = useState(false);
  const [loadStock, setLoadStock] = useState(false);
  const [loadPickingPack, setLoadPickingPack] = useState(false);
  const [loadExpedition, setLoadExpedition] = useState(false);

  const [collectionStatus, setCollectionStatus] = useState([]);

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);
    showMetricsReceipt(filters, key);
    showMetricsStock(filters, key);
    showMetricsPickingPack(filters, key);
    showMetricsExpedition(filters, key);
    setIsLoadingChart(false);
  };

  const showMetricsReceipt = async (filters, key) => {
    setLoadReceipt(false);

    try {
      const response = await requests.dashboardRecebimento(filters);
      setDataReceipt(response.data ?? { average: '0' });

      setLoadReceipt(true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const showMetricsStock = async (filters, key) => {
    setLoadStock(false);
    try {
      const responseStock = await requests.dashboardEstoque(filters);
      setDataStock(responseStock.data ?? { average: '0' });

      setLoadStock(true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const showMetricsPickingPack = async (filters, key) => {
    setLoadPickingPack(false);
    try {
      const responsePickingPack = await requests.dashboardPickingPack({ ...filters, type_storage: 'picking' }, 'picking');
      setDataPickingPack(responsePickingPack.data ?? { average: '0' });

      setLoadPickingPack(true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const showMetricsExpedition = async (filters, key) => {
    setLoadExpedition(false);
    try {
      const responseExpedition = await requests.remittanceAnalysisExpedition({ ...filters, type_storage: 'expedicao' }, 'expedicao');
      setDataExpedition(responseExpedition.data ?? { average: '0' });

      setLoadExpedition(true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsMetricsWarehouse());
  };

  useEffect(() => {
    !permissions.metricsWarehouse && navigate('/');

    getFilterOptions();
  }, []);

  useEffect(() => {
    const filteredChildren = filterOptions.filter((item) => item instanceof FilterOption).map((item) => item.children);

    setCollectionStatus(filteredChildren);
  }, [filterOptions]);

  const metricsWarehouseView = [
    {
      title: 'Prazos de recebimento',
      loader: !loadReceipt,
      content: <ReceiptDeadlinesChart data={dataReceipt} isPreview={true} />,
    },
    {
      title: 'Tracking de produtos',
      loader: !loadReceipt,
      content: <ProductTrackingChart data={dataReceipt} isPreview={true} />,
    },
    {
      title: 'Tracking de pedidos',
      loader: !loadReceipt,
      content: <OrderTrackingChart data={dataReceipt} isPreview={true} />,
    },
    {
      title: 'Análise de remessas',
      loader: !loadReceipt,
      content: <ShipmentAnalysisChart data={dataReceipt} />,
    },
    {
      title: 'Curva ABC - Estoque',
      loader: !loadStock,
      content: <CurveStockChart data={dataStock} modality="ESTOQUE" isPreview={true} />,
    },
    {
      title: 'Status de Produtos - Estoque',
      loader: !loadStock,
      content: <ProductStatusChart data={dataStock} isPreview={true} />,
    },
    {
      title: 'Total de Produtos - Estoque',
      loader: !loadStock,
      content: <TotalProducts data={dataStock} />,
    },
    {
      title: 'Status dos pedidos - Picking & Pack',
      loader: !loadPickingPack,
      content: <CollectionStatusChart data={dataPickingPack} isPreview={true} />,
    },
    {
      title: 'Status de coleta - Picking & Pack',
      loader: !loadPickingPack,
      content: (
        <OrderProgressChart
          data={dataPickingPack}
          collectionStatus={collectionStatus}
          filterOptions={filterOptions}
          isPreview={true}
        />
      ),
    },
    {
      title: 'Total de Produtos - Picking & Pack',
      loader: !loadPickingPack,
      content: <PickingPackingTotalProducts data={dataPickingPack} />,
    },
    {
      title: 'Curva ABC - Expedição',
      loader: !loadExpedition,
      content: <CurveStockChart data={dataPickingPack} modality="EXPEDICAO" isPreview={true} />,
    },
    {
      title: 'Valor de nota em expedição por estado',
      loader: !loadExpedition,
      content: <OrderProgressByStateChart data={dataExpedition} isPreview={true} />,
    },
    {
      title: 'Análise de remessas - Expedição',
      loader: !loadExpedition,
      content: <ShipmentAnalysisChart data={dataExpedition} />,
      isVariant: true,
    },
  ];

  return (
    <Page
      screen="kpi-warehouse"
      title="Armazém"
      breadcrumbs={[{ link: '#', title: 'Métricas' }]}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      loadCharts={loadCharts}
      isContentLoading={isLoadingChart}
      filterOptions={filterOptions}
      isRefreshLoading={isLoadingChart}>
      <Flex w="full" justify="center">
        <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} w="full" gap={5} p={4}>
          {windowWidth > 768 ? (
            /* desktop */
            metricsWarehouseView.map((item, index) => (
              <Card key={index} title={item.title} pb="20px">
                <ScreenLoader isLoading={item.loader}>
                  {item?.isVariant ? (
                    <Flex h="full" direction="row" justify="center" gap="30px">
                      <ShipmentAnalysisChart data={dataExpedition} />
                    </Flex>
                  ) : (
                    item.content
                  )}
                </ScreenLoader>
              </Card>
            ))
          ) : (
            /* mobile */
            <Accordion display="flex" flexDirection="column" gap="10px">
              {metricsWarehouseView.map((item, index) => {
                return (
                  <AccordionItem key={index} display="grid">
                    <Card>
                      <AccordionButton display="flex" justifyContent="space-between">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <ScreenLoader isLoading={item.loader}>
                        <AccordionPanel>
                          {item?.isVariant ? (
                            <Flex h="full" direction="row" justify="center" gap="30px">
                              <ShipmentAnalysisChart data={dataExpedition} />
                            </Flex>
                          ) : (
                            item.content
                          )}
                        </AccordionPanel>
                      </ScreenLoader>
                    </Card>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Grid>
      </Flex>
    </Page>
  );
};

export default MetricsWarehousePage;
