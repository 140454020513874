import React from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../../../components/Card/Card';
import Paginate from '../../../../../components/Paginate/Paginate';
import useResponsiveCheck from '../../../../../hooks/useResponsiveCheck';
import permissions from '../../../../../services/permissions';
import TableTravel from '../../../components/Tables/Travel/TableTravel';
import TravelManagementPanelTabs from '../TravelManagementPanelTabs';

import ModalFreight from './ModalFreight';

const TravelList = ({ tab, setTab, load, list, action, setAction, metadata, setMetadata, loadData, reloadList }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const responsive = useResponsiveCheck();
  const viewport = responsive.viewport;

  return (
    <Card
      m="10px"
      h="calc(100vh - 200px)"
      header={
        <Flex
          w="full"
          h={viewport < 900 ? '120px' : '65px'}
          direction={viewport < 900 ? 'column-reverse' : 'row'}
          justify="space-between"
          align="center">
          <Flex gap="2px" direction="column" align={viewport < 900 ? 'center' : 'normal'}>
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Viagens em preparação
            </Text>

            {metadata && metadata.total_count > 0 && (
              <Text textStyle="subtitle" color="#BEBEBE" fontSize={{ base: '14px', md: '16px' }}>
                Existe um total de {metadata?.total_count} viagens
              </Text>
            )}
          </Flex>

          <TravelManagementPanelTabs tab={tab} setTab={setTab} />
        </Flex>
      }>
      <TableTravel list={list} load={load} loadData={loadData} />

      {permissions.logisticTripCreate && (
        <Flex className="float-button-modal">
          <Button
            bgColor="green"
            color="white"
            borderRadius="50px"
            shadow="xl"
            h="50px"
            py="15px"
            px="30px"
            onClick={() => {
              onOpen();
            }}
            _hover={{ bgColor: '#70D499' }}>
            <MdAdd />
            <Text textStyle="paragraph" ml="10px">
              Nova viagem
            </Text>
          </Button>

          <ModalFreight
            entity={false}
            isOpen={isOpen}
            onClose={onClose}
            title="Cadastro de viagem"
            subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
            tab={tab}
            reloadList={reloadList}
          />
        </Flex>
      )}

      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Card>
  );
};

export default TravelList;
