/* Exemplo de uso

const [inputCpforCnpj, setInputCpforCnpj] = useState('');

<Input
  value={inputCpforCnpj}
  onChange={(event) => {
    const formatted = formatDocument(event.target.value);
    setInputCpforCnpj(formatted);
  }}
  maxLength="18"
/>;

*/

const formatDocument = (value) => {
  // Remove caracteres não numéricos
  const unformattedValue = value.replace(/\D/g, ''); //Remove tudo o que não é dígito

  // Formata como CPF
  if (unformattedValue.length <= 11) {
    let v;

    v = unformattedValue; 
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos

    const formattedValue = v;
    // Atualiza o valor no campo
    return formattedValue;
  }

  // Formata como CNPJ
  else {
    let v;

    v = unformattedValue; 
    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos

    const formattedValue = v;
    // Atualiza o valor no campo
    return formattedValue;
  }
};

export default formatDocument;
