import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import icon from '../../../../assets/images/expedition_documents.svg';
import Card from '../../../../components/Card/Card';
import Page from '../../../../components/Page';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { generateExportFile } from '../../../../utils/actions/generateExportFile';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';
import { filterOptionsWarehouseExpeditionDocuments } from '../../../../utils/filters/filterPresets';
import ExpeditionDocumentTable from '../components/ExpeditionDocumentTable';

import ExpeditionUploadDocument from './ExpeditionUploadDocument';

const ExpeditionDocumentsPage = () => {
  let profileRole = localStorage.getItem('profile-role');

  const hasPermission = permissions.warehouseExpeditionDocumentsView || permissions.warehouseExpeditionDocumentsManagement;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listExepditionDocuments(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getClientOptions = () => {
    requests
      .listClients({}, '1', '99999', 'all')
      .then((response) => {
        let options = [];
        response?.data?.length > 0 &&
          response.data.map((row) => {
            if (row.documentNumber.length === 8) {
              return;
            }

            options.push({
              label: `${row.name} - (${row.documentNumberFormatted})`,
              value: row.identifier,
            });
          });

        setClientOptions(options);
      })
      .finally(() => {});
  };

  const exportDocumentsFABActionRomaneio = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `cargo-detail`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `receipt-comprovant`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionRomaneioCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `all`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio-e-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouseExpeditionDocuments());
  };

  useEffect(() => {
    getClientOptions();
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="contract-management"
      title="Documentos de expedição"
      breadcrumbIcon={icon}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nota fiscal ou número de carga"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      allowEmptySearchPeriod={true}
      filterOptions={filterOptions}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      FAB={
        permissions.warehouseExpeditionDocumentsManagement
          ? [
              {
                title: 'Exportar documentos de Romaneio',
                text: 'Exportar documentos de Romaneio anexos à Expedição',
                action: exportDocumentsFABActionRomaneio,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Canhoto',
                text: 'Exportar documentos de Canhoto anexos à Expedição',
                action: exportDocumentsFABActionCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Romaneio e Canhoto',
                text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
                action: exportDocumentsFABActionRomaneioCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Importar documentos de expedição',
                text: 'Upload de documentos',
                action: (
                  <Button
                    leftIcon={<MdAdd size={20} color="#ffffff" />}
                    _hover={{ bgColor: 'purple-400' }}
                    bg="primary"
                    p="1.5rem"
                    pb="1.5rem"
                    pl="1.3rem"
                    pr="1.3rem"
                    onClick={() => {
                      closeFloatActionButton();
                      onOpen();
                    }}
                    borderRadius="50px">
                    <Text mr="10px" color="white">
                      Novo documento
                    </Text>
                  </Button>
                ),
                modality: 'custom',
              },
            ]
          : [
              {
                title: 'Exportar documentos de Romaneio',
                text: 'Exportar documentos de Romaneio anexos à Expedição',
                action: exportDocumentsFABActionRomaneio,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Canhoto',
                text: 'Exportar documentos de Canhoto anexos à Expedição',
                action: exportDocumentsFABActionCanhoto,
                modality: 'export-files',
              },
              {
                title: 'Exportar documentos de Romaneio e Canhoto',
                text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
                action: exportDocumentsFABActionRomaneioCanhoto,
                modality: 'export-files',
              },
            ]
      }>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Documentos de expedição
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.item_count} documentos
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            <ExpeditionDocumentTable
              list={list}
              action={forceLoadTrigger}
              setAction={setForceLoadTrigger}
              metadata={metadata}
              setMetadata={setMetadata}
              load={load}
            />
          </ScreenLoader>
        </Box>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="780px"
          px="2px"
          py="2px"
          my="0"
          overflow={'auto'}
          // h={{ sm: `${window.innerHeight - 138}px`, md: `${window.innerHeight - 590}px` }}
          top={0}
          borderRadius={'0'}>
          <ModalHeader p="25px">
            <ModalCloseButton alt="Fechar janela" title="Fechar janela" />
          </ModalHeader>
          <ModalBody bgColor="#FAFBFF" p="0" display="flex">
            <ExpeditionUploadDocument clientOptions={clientOptions} onClose={onClose} load={load} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
};

export default ExpeditionDocumentsPage;
