import React, { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box, Flex, Grid, GridItem, Icon,
    Text,
} from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import ModalEditVehicle from '../../VehicleManagement/components/ModalEditVehicle';
import ModalChangeToggle from '../Modals/ModalChangeToggle';

const VehiclesCard = ({ list, loadList, action, setAction, metadata, setMetadata, hideButtons = false }) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);

  const handleDelete = (identifier) => {
    setIsLoadingDelete(true);

    requests
      .deleteVehicle(identifier)
      .then((response) => {
        setIsLoadingDelete(false);
        toast.success(`Veículo ${response?.deleted ? 'excluído' : 'inativado'} com sucesso!`);
      })
      .catch(() => {
        setIsLoadingDelete(false);
        toast.error('Problemas ao remover veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handleClick = (key, identifier, statusText) => {
    setIsLoadingChangeStatus(true);
    let status = 'activate';

    if (statusText == 'ATIVO') {
      status = 'inactivate';
    }

    requests
      .changeStatusVehicle(identifier, status)
      .then((response) => {
        toast.success(`Veículo ${response?.deleted ? 'excluído' : 'inativado'} com sucesso!`);
        loadList();
        setIsLoadingChangeStatus(false);
      })
      .catch(() => {
        setIsLoadingChangeStatus(false);
        toast.error('Problemas ao alterar status do veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  return (
    <>
      <Grid>
        <Flex 
          border="1px" 
          borderColor={'#70707036'}
        >
          <Grid 
            templateColumns={'80px 150px 40px'}
            justifyContent={"space-between"}
            w="full"
            p={'8px 16px'}
            borderColor={'#70707036'}
          >
            <GridItem fontWeight={700}>
              Placas
            </GridItem>
            {!hideButtons && (
            <GridItem
              justifyContent={'center'}
              gridTemplateColumns={'1fr 1fr'}
              display={'grid'}
              fontWeight={700}
            >
              <Text>Ações</Text>
              <Text>Status</Text>
            </GridItem>
            )}
            <GridItem>{' '}</GridItem>
          </Grid>
        </Flex>
        <Flex 
          border="1px"
          borderColor={'#70707036'}
        >
          <Accordion 
            w={'100%'}
          >
          {list?.map((entity, key) => (
            <>
              <AccordionItem 
                key={key} 
                style={{ '--delay': `0.${key}s` }} 
                _odd={{ bg: "#eee" }} 
                _hover={'none'}
              >
                <AccordionButton>
                  <Grid
                    templateColumns={"80px 150px 40px"}
                    justifyContent={"space-between"}
                    w={'100%'}
                  >
                    <GridItem 
                      textAlign={'left'}
                      color={'#707070'}
                    >
                      {entity.plate ?? '-'}
                    </GridItem>
                    <GridItem>
                      {!hideButtons && (
                        <>
                          <Flex 
                            gap={'20px'}
                          >
                            <Flex align="center" gap={"5px"} justifyContent={'space-between'}>
                                <ModalEditVehicle vehicle={entity} action={action} setAction={setAction} />

                                <DefaultModal
                                  ctaButton={
                                    <Box h="24px">
                                      <Icon
                                        as={MdDeleteOutline}
                                        color="#AFAEB4"
                                        w="24px"
                                        h="24px"
                                        cursor="pointer"
                                        _hover={{ color: '#E74C3C' }}
                                      />
                                    </Box>
                                  }
                                  title={'Excluir o veículo'}
                                  loading={isLoadingDelete}
                                  subtitle={
                                    <>
                                      Você tem certeza que deseja excluir esse veículo?
                                      <br /> Esse veículo ficará indisponível para viagens.
                                    </>
                                  }
                                  buttonColor="red"
                                  txtButton="Excluir"
                                  callback={(decision) => {
                                    if (decision) {
                                      handleDelete(entity.identifier);
                                    }
                                  }}
                                />
                            </Flex>
                            <Flex color={'#707070'}>
                              <ModalChangeToggle
                                status={entity.status}
                                callback={(decision) => {
                                  if (decision) {
                                    handleClick(key, entity.identifier, entity.statusText);
                                  }
                                }}
                              />
                            </Flex>
                          </Flex>
                        </>
                      )}
                    </GridItem>
                    <GridItem textAlign={'end'}>
                      <AccordionIcon />
                    </GridItem>
                  </Grid>
                </AccordionButton>          
                <AccordionPanel
                  p={'15px 20px'}
                  bg={'white'}
                >
                  <Grid 
                    flexDir={'column'}
                    gridTemplateColumns={'1fr 1fr'}
                    fontSize={'14px'}
                  >
                    <Text>
                      <strong>Modelo:</strong> {entity.model ?? '-'}
                    </Text>
                    <Text>
                      <strong>Cor:</strong> {entity.color ?? '-'}
                    </Text>
                    <Text>
                      <strong>Ano fab:</strong> {entity.year ?? '-'}
                    </Text>
                    <Text>
                      <strong>Tipo carreta:</strong> {entity.type ?? '-'}
                    </Text>
                    <Text>
                      <strong>Capacidade:</strong> {entity.capacity ?? '-'}
                    </Text>
                    <Text>
                      <strong>Veículo próprio:</strong> {entity.ownershipLabel ?? '-'}
                    </Text>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            </>
          ))}

          {list?.length == 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          )}
          </Accordion>
        </Flex>
      </Grid>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default VehiclesCard;
