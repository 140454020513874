import React from 'react';

import { MdOutlineReceiptLong } from 'react-icons/md';

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

const DetailsOR = ({ entity }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="secundary" onClick={onOpen}>
        Detalhes OR
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent maxW="none" w="1200px" h="70vh">
          <ModalHeader borderBottom="1px" borderColor="#7070701A">
            <Flex align="center" gap="12px">
              <MdOutlineReceiptLong color="#6C48C2" size="35px" opacity="0.6" />
              <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#422C76">
                Detalhes do recebimento
              </Text>
            </Flex>

            <ModalCloseButton />
          </ModalHeader>

          <Flex overflowY="auto" p="30px" direction="column">
            <Text textColor="primary" textStyle="tableTitle" fontWeight="bold">
              Informações da OR
            </Text>

            <Grid templateColumns={{ base: 'repeat(4, 1fr)' }} mt="25px" gap="20px">
              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  OR:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.idOrdemRecto ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Recebimento:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.dtFimFormatted ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Transportadora:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.transportadora ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Cliente:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.clienteText ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Tipo de veículo:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.tipoVeiculo ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Placa:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.placa ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Motorista:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.motorista ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Observações:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.observacao ?? '-'}
                </Flex>
              </FormControl>
            </Grid>

            <FormControl w="450px">
              <FormLabel mb="9px" fontWeight="bold">
                Informações adicionais:
              </FormLabel>

              <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                <ul>
                  <li>
                    <Link
                      href={`http://nestprd.seniorcloud.com.br:8080/wmsweb/infoentrada/?local=relatorioRda&idOr=${entity?.idOrdemRecto}`}
                      target="_blanck">
                      Clique aqui para ver as <b>Fotos</b>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`http://nestprd.seniorcloud.com.br:8080/wmsweb/infoentrada/?local=relatorioResultadoConferencia&idOr=${entity?.idOrdemRecto}`}
                      target="_blanck">
                      Clique aqui para ver o <b>Termo de Faltas e Avaria (TFA)</b>
                    </Link>
                  </li>
                </ul>
              </Flex>
            </FormControl>

            <Text textColor="primary" textStyle="tableTitle" fontWeight="bold" my="40px">
              Material sujeito a conferência
            </Text>

            <Flex gap="20px">
              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Volumes:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.qtdVolumeFormatted ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Peso bruto:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  {entity?.peso ?? '-'}
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel mb="9px" fontWeight="bold">
                  Notas Fiscais:
                </FormLabel>

                <Flex minH="40px" px="16px" borderRadius="6px" align="center" bgColor="#FBFBFB">
                  <ul>
                    {entity?.notas && entity?.notas?.length > 0
                      ? entity?.notas?.map((entity, key) => (
                          <React.Fragment key={key}>
                            <li>{entity?.idNota}</li>
                          </React.Fragment>
                        ))
                      : null}
                  </ul>
                </Flex>
              </FormControl>
            </Flex>

            <Flex direction="column" bgColor="#6C48C21F" p="30px" mt="50px" gap="20px" borderRadius="6px">
              <Text textColor="primary" fontWeight="bold">
                Declaro que as informações referentes a mercadoria descritas acima, estão corretas e condizem com o estado do material
                no momento do recebimento na VENDEMMIA ARMAZEM E LOGISTICA INTEGRADA LTDA.
              </Text>

              <Text textColor="primary" fontWeight="bold" textAlign="end">
                Conferente: {entity?.conferente}
              </Text>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DetailsOR;
