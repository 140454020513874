import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Image, Text } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { importFileAction } from '../../../utils/actions/importFileAction';
import { add } from '../../../utils/exports';
import { filterOptionsNcm } from '../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';

const NcmManagementPage = () => {
  const request = useRef(0);
  const hasPermission = permissions.vendemmiaNcmManagement;
  const [filterOptions, setFilterOptions] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [list, setList] = useState([]);

  const [sorting, setSorting] = useState(null);
  const handleSort = (column, direction) => {
    setSorting({
      column,
      direction,
    });
  };

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    fetchNCMListData(filters, metadata.current_page, 25);
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  const fetchNCMListData = async (filters, page, pageSize) => {
    try {
      const res = await requests.fetchNCMList(filters, page, pageSize);
      setList(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  const subtitle = `Existe um total de ${metadata.total_count} ncm's`;

  const exportListFABActionCSV = () => {
    //preset
    const endpoint = `/ncm/export/${extension}`;
    const fileName = ``;
    const extension = `csv`;
    const body = {};
    const filters = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, body, filters);
  };

  const exportListFABActionExcel = () => {
    //preset
    const endpoint = `/ncm/export/${extension}`;
    const fileName = ``;
    const extension = `xlsx`;
    const body = {};
    const filters = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, body, filters);
  };

  const uploadFABActionDocuments = (docs, config) => {
    return importFileAction(`/ncm/upload`, docs, config);
  };

  const tags = ['statusReadable'];

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsNcm());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="ncm-management"
      title={`Gestão de NCMs`}
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por código ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingMore}
      showRefreshData={false}
      allowFiltersOverride={false}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      FAB={[
        {
          title: 'Cadastrar NCM',
          text: 'Cadastro de NCM',
          action: (
            <Link to="/vendemmia/ncm-management/new" key={2}>
              <Button variant={'floatingGreenButton'}>
                <Text mr="10px" color="white">
                  Cadastrar
                </Text>
                <Image src={add} />
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
        {
          title: 'Baixar em CSV',
          text: 'Exportar arquivo CSV com dados de NCMs',
          action: exportListFABActionCSV,
          modality: 'export-csv',
        },
        {
          title: 'Baixar em Excel',
          text: 'Exportar arquivo XLSX com dados de NCMs',
          action: exportListFABActionExcel,
          modality: 'export-csv',
        },
        {
          title: 'Importar',
          text: 'Importar novo arquivo',
          action: uploadFABActionDocuments,
          modality: 'upload-files',
        },
      ]}>
      <CommonList
        subtitle={subtitle}
        tags={tags}
        hasPagination={true}
        hasDetailsButton={true}
        sorting={sorting}
        handleSort={handleSort}
        detailsRedirectEndpoint={'/vendemmia/ncm-management/detail/${identifier}'}
        list={list}
        rawData={list}
        action={action}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        {...commonListConfig}></CommonList>
    </Page>
  );
};

export default NcmManagementPage;
