import React from 'react';

import { Card as CardChakra, Flex, Text } from '@chakra-ui/react';

const Card = ({ title, header, children, ...props }) => {
  return (
    <CardChakra overflow="hidden" h="auto" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" direction="column" {...props}>
      {(title || header) && (
        <Flex justify="space-between" alignItems="center" p="10px 15px" borderBottom="#70707036 solid 1px">
          {title && <Text textStyle="cardTitle">{title}</Text>}
          {header}
        </Flex>
      )}
      {children}
    </CardChakra>
  );
};

export default Card;
