import React from 'react';

export const LabelDefault = ({ name, text, children, style }) => {
  return (
    <label style={{ color: '#422C76', ...style }} htmlFor={name}>
      {children}
      {text}
    </label>
  );
};
