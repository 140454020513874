import React, { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const ModalDeleteContract = ({ entity, loadList }) => {
  const [load, setLoad] = useState(false);

  const handleDelete = async () => {
    setLoad(true);

    requests
      .deleteOperation(entity.identifier)
      .then(() => {
        setLoad(false);
        loadList();

        toast.success('Especificação operacional removida com sucesso');
      })
      .catch(() => {
        toast.error('Falha remover contrato, tente novamente ou entre em contato com o Administrador');

        setLoad(false);
      });

    setLoad(false);
  };
  return (
    <>
      <DefaultModal
        label="Excluir contrato"
        ctaButton={<MdDeleteOutline size={20} color="#E74C3C" />}
        title={'Certeza que deseja excluir?'}
        loading={load}
        subtitle={
          <>
            Deseja mesmo excluir esse contrato?
            <br />
            Ele sumirá automaticamente da lista de contrato.
          </>
        }
        buttonColor="red"
        txtButton="Confirmar"
        loadingText="Excluindo"
        enabled={entity.status === 'RASCUNHO'}
        callback={async (decision) => {
          if (decision) {
            await handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteContract;
