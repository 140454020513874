import { seaRoute } from 'searoute-ts';

import { reduceCheckpointListByDistance } from '../../../utils/geolocation';

const convertCoordinates = (coordinates, extraProps = []) => {
  return coordinates.map((coord, index) => {
    const point = { lat: coord[1], lng: coord[0] };
    if (extraProps[index]) {
      Object.assign(point, extraProps[index]);
    }
    return point;
  });
};

export const generateRoute = async (path) => {
  const points = [];

  if (!Array.isArray(path) || path.length < 2) {
    console.error('Invalid path:', path);
    return points;
  }

  for (let i = 0; i < path.length - 1; i++) {
    const origin = path[i];
    const destination = path[i + 1];

    if (!origin || !destination) {
      console.error('Invalid origin or destination at index', i);
      continue;
    }

    // Check if origin and destination are the same
    if (origin.lat === destination.lat && origin.lng === destination.lng) {
      console.error('Origin and destination are the same at index', i);
      continue;
    }

    // Check if coordinates are within valid ranges
    if (
      origin.lat < -90 ||
      origin.lat > 90 ||
      origin.lng < -180 ||
      origin.lng > 180 ||
      destination.lat < -90 ||
      destination.lat > 90 ||
      destination.lng < -180 ||
      destination.lng > 180
    ) {
      console.error('Coordinates out of range at index', i, 'origin:', origin, 'destination:', destination);
      continue;
    }

    const originFeature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [origin.lng, origin.lat],
      },
    };
    const destinationFeature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [destination.lng, destination.lat],
      },
    };

    const originProps = { ...origin };
    const destinationProps = { ...destination };

    // Remove properties
    delete originProps.lat;
    delete originProps.lng;
    delete destinationProps.lat;
    delete destinationProps.lng;

    try {
      // Log origin and destination features for debugging
      /* console.log('Generating route:', { originFeature, destinationFeature }); */

      const route = seaRoute(originFeature, destinationFeature);

      if (!route || !route.geometry || !Array.isArray(route.geometry.coordinates)) {
        console.error('Invalid route geometry:', route);
        continue;
      }

      const extraProps = route.geometry.coordinates.map((_, index) => {
        if (index === 0) return originProps;
        if (index === route.geometry.coordinates.length - 1) return destinationProps;
        return {};
      });

      const convertedPoints = convertCoordinates(route.geometry.coordinates, extraProps);
      points.push(...convertedPoints);
    } catch (error) {
      console.error('Error generating route:', error);
    }
  }

  let aux = [];
  points?.length > 0 && points.map(point => {
    aux.push({
      latitude: point.lat,
      longitude: point.lng,
    });
  });

  const pointsReduced = reduceCheckpointListByDistance(aux, 10);

  const result = [];
  pointsReduced && pointsReduced.map(current => {
    result.push({
      lat: current.latitude,
      lng: current.longitude,

    });
  }); 

  return result;
};
