import React, { useState } from 'react';

import { MdInsertDriveFile, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { Box, Collapse, Flex, IconButton, Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';
import { limitChars } from '../../../../utils/strings';
import ShortenText from '../../../../utils/text/format/shortenText';
import DocumentModalView from '../../components/ModalViewFile';

const ExpeditionList = ({ entity, count }) => {
  let profileRole = localStorage.getItem('profile-role');
  const { isOpen, onToggle } = useDisclosure();

  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const Icon = isOpen ? <MdKeyboardArrowUp color="#707070" /> : <MdKeyboardArrowDown color="#707070" />;

  const handleColor = (text) => {
    switch (text) {
      case 'FATURADO':
        return 'status-100';

      case 'COLETA INICIADA':
        return 'status-200';

      case 'PROCESSADO':
        return 'status-300';

      case 'EXPEDIDO':
        return 'status-900';

      case 'CANCELADO':
        return 'red';

      case 'A':
        return '#2ECC71';

      case 'B':
        return '#F1C40F';

      case 'C':
        return '#E74C3C';

      default:
        return '';
    }
  };

  const handleOpenModal = (entity) => {
    setCurrentEntity(entity);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const getIcon = (origin) => {
    switch (origin) {
      case 'API':
        return api;
      default:
        return ftp;
    }
  };

  const getLabel = (origin) => {
    switch (origin) {
      case 'API':
        return 'Importação via API Sênior';
      default:
        return 'Importação via FTP Sênior';
    }
  };

  return (
    <>
      <DocumentModalView isOpen={isOpenModalViewFile} entity={currentEntity} onClose={() => handleOpenModal([])} />
      <Tr className="border" cursor="pointer">
        <Td onClick={onToggle}>
          <Box>{Icon}</Box>
        </Td>
        {profileRole === 'ROLE_SYSTEM_ADMIN' && (
          <Td>
            <Box>
              <Tooltip label={getLabel(entity?.originImport)}>
                <Image
                  src={getIcon(entity?.originImport)}
                  color="status-600"
                  alt="Origem do registro"
                  ml="1"
                  width="26px"
                  height="26px"
                />
              </Tooltip>
            </Box>
          </Td>
        )}
        <Td onClick={onToggle}>
          <Box>
            <Flex
              align="center"
              borderRadius="15px"
              justifyContent="center"
              bgColor={handleColor(entity?.statusNf)}
              color="white"
              w={'9rem'}
              h="2rem">
              {entity?.statusNf?.length > 13 ? (
                <Tooltip label={entity.statusNf}>{limitChars(entity.statusNf, 13) ?? '-'}</Tooltip>
              ) : (
                entity?.statusNf ?? '-'
              )}
            </Flex>
          </Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.usuarioConferencia ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.stateCodeFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.nuNota ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.serieNf ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.idPedido ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box minW="300px">
            {entity?.nomeDestinatario?.length > 50 ? (
              <Tooltip label={entity.nomeDestinatario}>{limitChars(entity.nomeDestinatario, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDestinatario ?? '-'
            )}
          </Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.ufDestinatario ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.recipientCity ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box minW="300px">
            {entity?.nomeDepositante?.length > 50 ? (
              <Tooltip label={entity.nomeDepositante}>{limitChars(entity.nomeDepositante, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDepositante ?? '-'
            )}
          </Box>
        </Td>
        <Td onClick={onToggle}>
          <Box minW="300px">
            {entity?.nomeTransportadora?.length > 50 ? (
              <Tooltip label={entity.nomeTransportadora}>{limitChars(entity.nomeTransportadora, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeTransportadora ?? '-'
            )}
          </Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.dtFaturamentoFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.dtFimColetaFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.embLiberado ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.carga ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.qtVolumesFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.valorNfFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.cubagemM3Formatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>{entity?.weightFormatted ?? '-'}</Box>
        </Td>
        <Td onClick={onToggle}>
          <Box>
            {entity?.observacao?.length > 15 ? (
              <Tooltip label={entity?.observacao}>
                <span>{limitChars(entity?.observacao, 15)}</span>
              </Tooltip>
            ) : (
              entity?.observacao ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Flex gap="10px" justify="flex-end">
            <Tooltip label="Visualizar todos">
              <Box>
                <IconButton
                  bg="transparent"
                  icon={<MdInsertDriveFile size={26} color="#6C48C2" />}
                  onClick={(decision) => {
                    if (decision) {
                      handleOpenModal(entity);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
      <Tr w="full">
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen} animateOpacity>
            <Box bgColor="#F9F9F9" m="1.2rem">
              <Table>
                <Thead border="0" borderBottom="1px" h={50}>
                  <Tr>
                    <Th>Código</Th>
                    <Th>Descrição</Th>
                    <Th>Quantidade</Th>
                    <Th>Valor</Th>
                    <Th>Lote Indústria</Th>
                    <Th>Número de série</Th>
                    <Th>Curva ABC</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {entity?.itens && entity?.itens?.length > 0 ? (
                    entity?.itens?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>{item?.codProduto ?? '-'}</Td>
                            <Td>{item?.descricaoProduto ?? '-'}</Td>
                            <Td>{item?.qtdeExpedidaFormatted ?? '-'}</Td>
                            <Td>{item?.totalValueFormatted ?? '-'}</Td>
                            <Td>{item?.loteIndustrial ?? '-'}</Td>
                            <Td>
                              <ShortenText value={item?.infoEspecifica} maxLength={25} />
                            </Td>
                            <Td>
                              <Box>
                                <Flex
                                  align="center"
                                  justifyContent="center"
                                  borderRadius="15px"
                                  bgColor={handleColor(item?.inAbc)}
                                  color="white"
                                  w="3rem"
                                  h="2rem">
                                  {item?.inAbc ?? '-'}
                                </Flex>
                              </Box>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex justify="center" my="25px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ExpeditionList;
