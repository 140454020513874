import React from 'react';

import CurrencyInput from 'react-currency-input-field';

import { Button, FormControl, Grid, InputGroup, InputLeftAddon, Text } from '@chakra-ui/react';

import { LabelDefault } from '../../../../../components/Form/Label/LabelDefault';

const CardFreight = ({ values, setFieldValue, errors, setFieldError, validatedForm, setStep }) => {
  const onSubmit = async () => {
    if (await validatedForm(values, setFieldError, 'freightSchema')) setStep(3);
  };

  return (
    <>
      <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="10px" mx="1px">
        <FormControl w="13.75rem">
          <LabelDefault name="freight.merchandiseValue" text="Valor da mercadoria" />
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={values.freight.merchandiseValue}
              id="freight.merchandiseValue"
              name="freight.merchandiseValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Mercadoria"
              onValueChange={(value) => setFieldValue('freight.merchandiseValue', value)}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                width: '11.25rem',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {errors?.merchandiseValue && <Text className="error-message-error">{errors.merchandiseValue}</Text>}
        </FormControl>
        <FormControl w="13.75rem">
          <LabelDefault name="freight.merchandiseDamagedValue" text="Valor da mercadoria avariada" />
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={values.freight.merchandiseDamagedValue}
              id="freight.merchandiseDamagedValue"
              name="freight.merchandiseDamagedValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Mercadoria avariada"
              onValueChange={(value) => setFieldValue('freight.merchandiseDamagedValue', value)}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                width: '11.25rem',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {errors?.merchandiseDamagedValue && <Text className="error-message-error">{errors.merchandiseDamagedValue}</Text>}
        </FormControl>
        <FormControl w="13.75rem">
          <LabelDefault name="freight.merchandiseDamagedValue" text="Valor do frete" />
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={values.freight.freightValue}
              id="freight.freightValue"
              name="freight.freightValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Valor do frete"
              onValueChange={(value) => setFieldValue('freight.freightValue', value)}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                width: '11.25rem',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {errors?.freightValue && <Text className="error-message-error">{errors.freightValue}</Text>}
        </FormControl>

        <FormControl w="13.75rem">
          <LabelDefault name="freight.merchandiseDamagedValue" text="Cubagem da carga" />
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              Cub
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={values.freight.cubage}
              id="freight.cubage"
              name="freight.cubage"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Cubagem"
              onValueChange={(value) => setFieldValue('freight.cubage', value)}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                width: '11.25rem',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {errors?.cubage && <Text className="error-message-error">{errors.cubage}</Text>}
        </FormControl>

        <FormControl w="13.75rem">
          <LabelDefault name="freight.merchandiseDamagedValue" text="Peso" />
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              KG
            </InputLeftAddon>

            <CurrencyInput
              defaultValue={values.freight.weight}
              id="freight.weight"
              name="freight.weight"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Cubagem"
              onValueChange={(value) => setFieldValue('freight.weight', value)}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                width: '11.25rem',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>

          {errors?.weight && <Text className="error-message-error">{errors.weight}</Text>}
        </FormControl>
      </Grid>

      <Button mt="1.25rem" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>
    </>
  );
};

export default CardFreight;
