import React, { useEffect, useRef, useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import ProductStatusChart from '../../../components/Charts/Warehouse/ProductStatusChart';
import StockCollectionHistoryChart from '../../../components/Charts/Warehouse/StockCollectionHistoryChart';
import StockCollectionStatusChart from '../../../components/Charts/Warehouse/StockCollectionStatusChart';
import TotalProducts from '../../../components/Charts/Warehouse/TotalProducts';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsWarehouse } from '../../../utils/filters/filterPresets';

import StockTable from './components/StockTable';

const StockPage = () => {
  const hasPermission = permissions.warehouseStock;
  const hasImportPermission = permissions.warehouseStockPositionConsolidationUpload;

  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [chartData, setChartData] = useState({});
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [showUploadPalletPosition, setShowUploadPalletPosition] = useState(false);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  const [stockPositionConsolidation, setStockPositionConsolidation] = useState(null);
  const [stockPositionHistory, setStockPositionHistory] = useState(null);

  const [ownState, setOwnState] = useState(false);
  const [useOwnState, setUseOwnState] = useState(false);

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [statusGrafico, setStatusGrafico] = useState(null);
  const [situation, setSituation] = useState(null);

  const [stockPosition, setStockPosition] = useState(null);

  const [sorting, setSorting] = useState(null);

  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />;

  const load = (filters, key, page) => {
    getListStock(filters, key, page);
    getStockPositionConsolidation(filters, key, page);
    getStockPositionHistory(filters, key, page);
  };

  const loadCharts = (filters, key) => {
    var filters = {
      ...filters,
      statusGrafico,
      situation,
    };

    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    requests
      .dashboardEstoque(filters)
      .then((data) => {
        if (requestChart.current && requestChart.current > key) {
          return;
        }
        requestChart.current = key;

        setChartData(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const getListStock = (filters, key, page) => {
    setIsLoading(true);

    var filters = {
      ...filters,
      statusGrafico,
      situation,
    };

    requests
      .listEstoque(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setShowUploadPalletPosition(hasImportPermission);
  };

  const getStockPositionConsolidation = async (filters, key) => {
    var filters = {
      ...filters,
      stockPosition,
    };

    requests
      .getStockConsolidation(filters)

      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setStockPositionConsolidation(
          data.result[0]
            ? data.result[0]
            : [
                {
                  'Porta Pallet': 0,
                  Blocado: 0,
                  Recebimento: 0,
                  Expedição: 0,
                  Devolução: 0,
                },
              ]
        );

        const AllValuesZero = data.result[0] ? true : false;

        setShowMoreVisible(AllValuesZero);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getStockPositionHistory = async (filters, key) => {
    requests
      .getStockHistory(filters)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setStockPositionHistory(data.result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportListFABAction = (filters, key) => {
    var filters = {
      ...filters,
      statusGrafico,
      situation,
    };

    //preset
    const extension = `xlsx`;
    const endpoint = `/estoque/download/estoque`;
    const fileName = `Estoque`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const handlerUploadPalletPosition = (docs, config) => {
    setUseOwnState(true);

    const toastId = toast.info('Aguarde enquanto enviamos os arquivos para o servidor...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });
    setOwnState(true);

    requests
      .uploadPalletPosition(docs, config)
      .then(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Upload efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
        setOwnState(false);
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar o upload, tente novamente ou entre em contato com o Administrador');
        setOwnState(false);
      });
  };

  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouse());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico, situation]);

  const chartsView = [
    {
      title: 'Curva ABC',
      content: <CurveStockChart data={chartData} modality="ESTOQUE" setStatusGrafico={setStatusGrafico} />,
    },
    {
      title: 'Status de Produtos',
      content: <ProductStatusChart data={chartData} setSituation={setSituation} />,
    },
    {
      title: 'Total de Produtos',
      content: <TotalProducts data={chartData} />,
    },
  ];

  return (
    <Page
      screen="warehouse-stock"
      title="Estoque"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Código, lote ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      useOwnState={useOwnState}
      ownState={ownState}
      setOwnState={setOwnState}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      nameForm={'file_upload'}
      hasChartChanges={hasChartChanges}
      FAB={[
        showUploadPalletPosition && {
          title: 'Upload de posição pallet',
          text: 'Importar arquivo CSV com dados de estoque',
          action: handlerUploadPalletPosition,
          modality: 'upload-files',
        },
        {
          title: 'Exportar estoque',
          text: 'Exportar arquivo (Excel) com dados de estoque',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="20px" m="10px">
        {!isMobile ? (
          /* desktop */
          <Grid w="full" gap="10px" templateColumns="repeat(3, 1fr)">
            {chartsView.map((item, index) => {
              return (
                <Card key={index} pb="20px" title={item.title}>
                  <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                </Card>
              );
            })}
          </Grid>
        ) : (
          /* mobile */
          <Accordion w="full" gap="10px" border={'0'} borderRadius={'8px'}>
            {chartsView.map((item, index) => {
              return (
                <AccordionItem
                  key={index}
                  border={'0'}
                  bg={'white'}
                  borderX={'1px solid#70707036'}
                  borderRadius={'8px'}
                  overflow={'hidden'}
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                  mb={'10px'}>
                  <AccordionButton
                    display={'flex'}
                    justifyContent={'space-between'}
                    _hover={{ bg: 'white' }}
                    borderTop={'1px solid #70707036'}
                    p={'16px 21px'}>
                    <Text textStyle="cardTitle" fontSize="16px">
                      {item.title}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <AccordionPanel>{item.content}</AccordionPanel>
                  </ScreenLoader>
                </AccordionItem>
              );
            })}
          </Accordion>
        )}
        {showMoreVisible && (
          <>
            <Collapse in={isOpen} animateOpacity>
              <Grid templateColumns={'5fr 7fr'} gridGap={'10px'}>
                <Card title={'Posição de estoque'}>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <StockCollectionStatusChart data={stockPositionConsolidation} isPreview={true} />
                  </ScreenLoader>
                </Card>

                <Card title={'Histórico de posição de estoque'}>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <StockCollectionHistoryChart data={stockPositionHistory} type="expedition" />
                  </ScreenLoader>
                </Card>
              </Grid>
            </Collapse>

            <Button background={'transparent'} color="primary" _hover={{ background: 'transparent' }} onClick={onToggle}>
              <Flex align={'center'} direction={'column'}>
                {isOpen ? (
                  <>
                    <Text>Exibir menos</Text>
                    {Icon}
                  </>
                ) : (
                  <>
                    <Text>Exibir mais</Text>
                    {Icon}
                  </>
                )}
              </Flex>
            </Button>
          </>
        )}
        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <ScreenLoader isLoading={isLoading}>
                <Box overflow="auto" h="calc(100vh - 220px)">
                  <StockTable list={list} handleSort={handleSort} sorting={sorting} />
                </Box>
              </ScreenLoader>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>

          <Box m="1.2rem">
            <ScreenLoader isLoading={isLoading}>
              <StockTable list={list} handleSort={handleSort} sorting={sorting} />
            </ScreenLoader>
          </Box>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default StockPage;
