import React, { useEffect, useRef, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsVehicle } from '../../../utils/filters/filterPresets';
import VehiclesCard from '../components/Cards/VehiclesCard';
import VehiclesTable from '../components/Tables/VehiclesTable';

import ModalCreateVehicle from './components/ModalCreateVehicle';

const VehicleManagementPage = () => {
  const hasPermission = permissions.logisticFleetManagement;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listVehicles(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        let options = [];
        data.data.map((vehicle) => {
          return options.push({
            identifier: vehicle?.identifier,
            model: vehicle?.model,
            plate: vehicle?.licensePlate,
            color: vehicle?.color,
            year: vehicle?.yearManufacture != 0 ? vehicle?.yearManufacture : '',
            type: vehicle?.typeCar,
            capacity: vehicle?.capacity,
            ownershipLabel: vehicle?.ownership == 'PROPRIO' ? 'SIM' : 'NÃO',
            status: vehicle?.status == 'ATIVO' ? true : false,
            statusText: vehicle?.status,
            brand: vehicle?.brand,
            ownership: vehicle?.ownership,
            yearModel: vehicle?.yearModel,
            modelVehicle: vehicle?.modelVehicle,
          });
        });

        setList(options);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const exportListFABAction = () => {
    //preset
    const endpoint = `/adm/vehicle/export-csv`;
    const fileName = `vehicles`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};
    const filters = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, filters);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsVehicle);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="vehicle"
      title="Gestão de frota"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Placa e propriedade veículo"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar veículo',
          text: 'Cadastro de veículo',
          action: <ModalCreateVehicle key={1} action={action} setAction={setAction} />,
          modality: 'custom',
        },
        {
          title: 'Exportar Veículos',
          text: 'Exportar arquivo CSV com dados de Veículos',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de veículos
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} veículos
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <VehiclesTable
                list={list}
                loadList={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            ) : (
              <VehiclesCard
                list={list}
                loadList={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            )}
          </ScreenLoader>
        </Box>
      </Card>
    </Page>
  );
};

export default VehicleManagementPage;
