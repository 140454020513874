import React, { useEffect, useRef, useState } from 'react';

import { MdHelp, MdRemoveRedEye } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import OrderProgressByStateChart from '../../../components/Charts/Warehouse/OrderProgressByStateChart';
import ShipmentAnalysisChart from '../../../components/Charts/Warehouse/ShipmentAnalysisChart';
import WerehouseSlaChart from '../../../components/Charts/Warehouse/WerehouseSlaChart';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { filterOptionsWarehouseExpedition } from '../../../utils/filters/filterPresets';

import ExpeditionTable from './components/ExpeditionTable';
import ExpeditionUploadDocument from './documents/ExpeditionUploadDocument';

const ExpeditionPage = () => {
  const hasPermission = permissions.warehouseExpedition;

  const [clientOptions, setClientOptions] = useState([]);
  const request = useRef(0);

  const requestExpeditionChart = useRef(0);
  const requestRemittanceChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [dataExpeditionChart, setDataExpeditionChart] = useState({});
  const [dataRemittanceChart, setDataRemittanceChart] = useState({});

  const [useOwnState, setUseOwnState] = useState(false);
  const [ownState, setOwnState] = useState(false);

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [statusGrafico, setStatusGrafico] = useState(null);

  const [sorting, setSorting] = useState(null);
  const [isOpenModalDocument, setIsOpenModalDocument] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const load = (filters, key, page) => {
    setIsLoading(true);

    filters.type_storage = 'expedition';

    var filters = {
      ...filters,
      statusGrafico,
    };

    requests
      .listPickingPack(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    filters.type_storage = 'expedition';

    var filters = {
      ...filters,
      statusGrafico,
    };

    requests.remittanceAnalysisExpedition(filters).then((data) => {
      if (requestRemittanceChart.current && requestRemittanceChart.current > key) {
        return;
      }
      requestRemittanceChart.current = key;

      setDataRemittanceChart(data.data ?? { average: '0' });
    });

    requests
      .dashboardPickingPack(filters, 'expedition')
      .then((data) => {
        if (requestExpeditionChart.current && requestExpeditionChart.current > key) {
          return;
        }
        requestExpeditionChart.current = key;

        setDataExpeditionChart(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABActionExpedition = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const extension = `xlsx`;
    const endpoint = `/pickingepacking/export`;
    const fileName = `Expedicao`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionExpeditionSerial = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const extension = `xlsx`;
    const endpoint = `/expedition/export/serial/${extension}`;
    const fileName = `Expedicao-serial`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionPickingPackingExpedicao = (filters, key) => {
    //type - optional
    filters.type_storage = 'both';

    //preset
    const extension = `xlsx`;
    const endpoint = `/pickingepacking/export`;
    const fileName = `PickingPacking-e-Expedicao`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionRomaneio = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `cargo-detail`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `receipt-comprovant`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportDocumentsFABActionRomaneioCanhoto = (filters, key) => {
    //type - optional
    filters.type_storage = 'expedition';

    //preset
    const type = `all`;
    const extension = `zip`;
    const endpoint = `/open/expedition/document/${type}/download`;
    const fileName = `Arquivos-romaneio-e-canhoto`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const uploadFABActionDocuments = (docs, config) => {
    setUseOwnState(true);

    const toastId = toast.info('Aguarde enquanto enviamos os arquivos para o servidor...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toastw
      closeButton: false, // Não exibe o botão de fechar o toast
    });
    setOwnState(true);
    requests
      .savePickingPackFile(docs, config)
      .then(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Upload efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar o upload, tente novamente ou entre em contato com o Administrador');
      })
      .finally(() => {
        setOwnState(false);
        triggerRefresh();
      });
  };

  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouseExpedition());
  };

  useEffect(() => {
    getClientOptions();
    getFilterOptions();
  }, []);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico]);

  const chartsView = [
    {
      title: 'Curva ABC',
      content: <CurveStockChart data={dataExpeditionChart} modality="EXPEDICAO" setStatusGrafico={setStatusGrafico} />,
      hasModal: false,
    },
    {
      title: 'Valor de nota em expedição por estado',
      content: <OrderProgressByStateChart data={dataRemittanceChart} filterOptions={filterOptions} type="state" />,
      hasModal: false,
    },
    {
      title: 'Análise de remessas',
      content: <ShipmentAnalysisChart data={dataRemittanceChart} />,
      hasModal: true,
      helpTooltip: (
        <Tooltip label="Análise de remessa = soma total dos itens / período de expedição">
          <Box ml="0.325rem">
            <MdHelp size={15} color="#422C76" />
          </Box>
        </Tooltip>
      ),
      modal: <WerehouseSlaChart data={dataRemittanceChart} type="expedition" onOpen={onOpen} isOpen={isOpen} onClose={onClose} />,
    },
  ];

  const actionModalDocument = () => {
    closeFloatActionButton();
    setIsOpenModalDocument(!isOpenModalDocument);
  };

  const getClientOptions = () => {
    requests
      .listClients({}, '1', '99999', 'all')
      .then((response) => {
        let options = [];
        response?.data?.length > 0 &&
          response.data.map((row) => {
            if (row.documentNumber.length === 8) {
              return;
            }

            options.push({
              label: `${row.name} - (${row.documentNumberFormatted})`,
              value: row.identifier,
            });
          });

        setClientOptions(options);
      })
      .finally(() => {});
  };

  return (
    <Page
      screen="warehouse-expedition"
      title="Expedição"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      useOwnState={useOwnState}
      ownState={ownState}
      setOwnState={setOwnState}
      hasChartChanges={hasChartChanges}
      forceLoadTrigger={forceLoadTrigger}
      additionalFormFields={[
        {
          label: 'Modalidade',
          group: 'modality',
          name: 'mod',
          required: true,
          options: [
            { value: 'CANHOTO', label: 'Canhoto' },
            { value: 'ROMANEIO', label: 'Romaneio' },
          ],
        },
      ]}
      FAB={[
        {
          title: 'Exportar Expedição por número de série',
          text: 'Exportar arquivo (Excel) com dados de Expedição separados por número de série',
          action: exportListFABActionExpeditionSerial,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Expedição',
          text: 'Exportar arquivo (Excel) com dados de Expedição',
          action: exportListFABActionExpedition,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo (Excel) com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
        {
          title: 'Exportar documentos de Romaneio',
          text: 'Exportar documentos de Romaneio anexos à Expedição',
          action: exportDocumentsFABActionRomaneio,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Canhoto',
          text: 'Exportar documentos de Canhoto anexos à Expedição',
          action: exportDocumentsFABActionCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Romaneio e Canhoto',
          text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
          action: exportDocumentsFABActionRomaneioCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Upload de documentos',
          text: 'Upload de documentos para anexar à Expedição',
          action: uploadFABActionDocuments,
          modality: 'upload-files',
        },
      ]}>
      <Modal isOpen={isOpenModalDocument} onClose={actionModalDocument} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="780px"
          px="2px"
          py="2px"
          my="0"
          overflow={'auto'}
          // h={{ sm: `${window.innerHeight - 138}px`, md: `${window.innerHeight - 590}px` }}
          top={0}
          borderRadius={'0'}>
          <ModalHeader p="25px">
            <ModalCloseButton alt="Fechar janela" title="Fechar janela" />
          </ModalHeader>
          <ModalBody bgColor="#FAFBFF" p="0" display="flex">
            <ExpeditionUploadDocument clientOptions={clientOptions} onClose={actionModalDocument} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex direction="column" gap="20px" m="10px">
        <Grid w="full" h={{ base: 'initial', md: '345px' }}>
          <Accordion display="grid" gap="10px" gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}>
            {chartsView.map((item, key) => {
              return !item.hasModal ? (
                !isMobile ? (
                  /* desktop */
                  <Card key={key} title={item.title}>
                    <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                  </Card>
                ) : (
                  /* mobile */
                  <AccordionItem key={key}>
                    <Card>
                      <AccordionButton display="flex" justifyContent="space-between" _hover="none">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                      </AccordionPanel>
                    </Card>
                  </AccordionItem>
                )
              ) : /* modal */
              !isMobile ? (
                /* desktop */
                <Card
                  key={key}
                  header={
                    <Flex justify="space-between" w="full" alignItems="center">
                      <Flex justify="center">
                        <Text textStyle="cardTitle" justifyContent="center">
                          {item.title}
                        </Text>
                        {item?.helpTooltip}
                      </Flex>

                      {!isLoadingChart && (
                        <Tooltip label={item.title}>
                          <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                        </Tooltip>
                      )}
                    </Flex>
                  }>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <Flex direction="row" justify="center" h="full">
                      {item.content}

                      {item?.modal}
                    </Flex>
                  </ScreenLoader>
                </Card>
              ) : (
                /* mobile */
                <AccordionItem key={key}>
                  <Card
                    borderBottom="0"
                    header={
                      <Flex justify="space-between" w="full" alignItems="center">
                        <Text textStyle="cardTitle">
                          <AccordionButton _hover="none" p="8px 5px">
                            <Text textStyle="cardTitle" fontSize="16px">
                              {item.title}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>

                        {!isLoadingChart && (
                          <Tooltip label={item.title}>
                            <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                          </Tooltip>
                        )}
                      </Flex>
                    }>
                    <ScreenLoader isLoading={isLoadingChart}>
                      <Flex direction="row" justify="center" h="full">
                        <AccordionPanel>{item.content}</AccordionPanel>

                        {item?.modal}
                      </Flex>
                    </ScreenLoader>
                  </Card>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Grid>

        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <Box overflow="auto" h="calc(100vh - 220px)">
                <ScreenLoader isLoading={isLoading}>
                  <ExpeditionTable list={list} handleSort={handleSort} sorting={sorting} />
                </ScreenLoader>
              </Box>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>

          <ScreenLoader isLoading={isLoading}>
            <Box overflowX="auto" m="1rem">
              <ExpeditionTable list={list} handleSort={handleSort} sorting={sorting} />
            </Box>
          </ScreenLoader>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default ExpeditionPage;
