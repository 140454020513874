import React, { useEffect, useState } from 'react';

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../services/requests';

const TableMetricsUpdate = ({ searchPeriod }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);

  const loadList = async () => {
    setIsLoading(true);

    try {
      const response = await requests.listImports({ period: searchPeriod });
      setList(response.data);
    } catch (error) {
      setIsLoading(false);

      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
      loadList();
    }
  }, [searchPeriod]);

  return (
    <ScreenLoader isLoading={isLoading}>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Descrição</Th>
            <Th>Modalidade Query</Th>
            <Th>Modalidade Arquivo</Th>
            <Th>Tempo decorrido</Th>
            <Th>Status</Th>
            <Th visibility="hidden">*</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list.length > 0 ? (
            <>
              {list.map((entity) => (
                <Tr key={entity}>
                  <Td>Documento</Td>
                  <Td>Dat Envio</Td>
                  <Td>Status </Td>
                  <Td>Ações </Td>
                  <Td>a</Td>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan={6}>
                <Flex justify="center" my="25px">
                  Não há resultados que correspondam aos filtros selecionados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </ScreenLoader>
  );
};

export default TableMetricsUpdate;
