import React, { useRef, useState } from 'react';

import moment from 'moment';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { limitChars } from '../../../utils/strings';

import ModalDeleteFaq from './components/ModalDeleteFaq';
import ModalEditFaq from './components/ModalEditFaq';
import ModalNewFaq from './components/ModalNewFaq';

const FaqPage = () => {
  const hasPermission = permissions.vendemmiaManagementFaqManagement;
  const request = useRef(0);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [action, setAction] = useState(1);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listFaqs(filters)
      .then((response) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(response.data);
        setMetadata(response.meta);

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addTopicToList = () => {
    load();
  };

  return (
    <Page
      screen="faq"
      title="Perguntas frequentes"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por título ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      showFilters={false}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar Perguntas',
          text: 'Cadastro de Perguntas',
          action: <ModalNewFaq addTopicToList={addTopicToList} />,
          modality: 'custom',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de perguntas frequentes
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} perguntas frequentes
            </Text>
          </Flex>
        }>
        {!isMobile ? (
          <Box p="15px">
            <Table className="table-default min">
              <Thead border="1px" borderBottom="1px">
                <Tr>
                  <Th>Título</Th>
                  <Th>Conteúdo</Th>
                  <Th>Data de Criação</Th>
                  <Th>Tipo</Th>
                  <Th>Modalidade</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>

              <Tbody border="1px">
                {list?.map((entity) => (
                  <React.Fragment key={entity?.identifier}>
                    <Tr>
                      <Td>{entity?.title}</Td>
                      <Td w="auto">{limitChars(entity?.description, 120)}</Td>
                      <Td>{moment(entity?.createdAt).format('DD/MM/YYYY')}</Td>
                      <Td>{entity?.typeSystem === 'SYSTEM_ANALYTICS' ? 'Painel Analytics' : 'App do Motorista'}</Td>
                      <Td>{entity?.modality === 'AUTHENTICATED' ? 'Autenticado' : 'Público'}</Td>

                      <Td>
                        <Flex gap="10px">
                          <ModalEditFaq entity={entity} onSave={triggerRefresh} />
                          <ModalDeleteFaq entity={entity} onSave={triggerRefresh} />
                        </Flex>
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <>
            <Grid templateColumns="1fr 100px" p={'8px 16px 8px 12px'} textIndent={'4px'}>
              <GridItem fontWeight={'bold'}>Título</GridItem>
              <GridItem fontWeight={'bold'}>Ações</GridItem>
            </Grid>
            <Accordion>
              {list.map((entity) => {
                return (
                  <>
                    <AccordionItem>
                      <h2>
                        <AccordionButton p={4}>
                          <Grid
                            templateColumns="1fr 100px"
                            justifyContent={'space-between'}
                            w={'100%'}
                            alignItems={'center'}
                            textAlign={'left'}
                            gap={2}>
                            <GridItem>{entity.title}</GridItem>
                            <GridItem>
                              <Flex gap="10px" justifyContent={'space-between'}>
                                <ModalEditFaq entity={entity} onSave={triggerRefresh} />
                                <ModalDeleteFaq entity={entity} onSave={triggerRefresh} />
                                <Flex align={'center'} justifyContent={'center'}>
                                  <AccordionIcon />
                                </Flex>
                              </Flex>
                            </GridItem>
                          </Grid>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={4}>
                        <Text mb={2}>{limitChars(entity.description, 120)}</Text>
                        <Text>
                          <strong>Data de Criação:</strong> {moment(entity.createdAt).format('DD/MM/YYYY')}
                        </Text>
                        <Text>
                          <strong>Tipo:</strong> {entity.typeSystem === 'SYSTEM_ANALYTICS' ? 'Painel Analytics' : 'App do Motorista'}
                        </Text>
                        <Text>
                          <strong>Modalidade:</strong> {entity.modality === 'AUTHENTICATED' ? 'Autenticado' : 'Público'}
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>
                  </>
                );
              })}
            </Accordion>
          </>
        )}
        {/* desktop */}

        <Flex className="float-button-modal"></Flex>
      </Card>
    </Page>
  );
};

export default FaqPage;
