import React, { useState } from 'react';

import moment from 'moment';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, IconButton, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

const TableProcessMetrics = ({ entity, profileRole }) => {
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  // Função para formatar campos de peso
  const formatWeight = (weight) => {
    if (!weight) return '-';
    return (
      parseFloat(weight).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + ' kg'
    );
  };

  // Função para formatar campos de invoice (exemplo genérico)
  const formatInvoice = (value) => {
    if (!value) return '-';
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  // Função para formatar valores como porcentagem
  const formatPercentage = (value) => {
    if (!value) return '-';
    return (
      parseFloat(value * 100).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + '%'
    );
  };

  return (
    <Flex h="500px" w="full" direction="column" overflow="auto">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px !important"></Box>
            </Th>

            <Th>
              <Box p="10px !important">Processo</Box>
            </Th>

            <Th>
              <Box p="10px !important"></Box>
            </Th>

            <Th>
              <Box p="10px !important">Modal</Box>
            </Th>

            <Th>
              <Box p="10px !important">Etapa atual</Box>
            </Th>

            <Th>
              <Box p="10px !important">Incoterm</Box>
            </Th>

            <Th>
              <Box p="10px !important">País de Origem</Box>
            </Th>

            <Th>
              <Box p="10px !important">Porto de Origem</Box>
            </Th>

            <Th>
              <Box p="10px !important">País de Destino</Box>
            </Th>

            <Th>
              <Box p="10px !important">Porto de Destino</Box>
            </Th>

            <Th>
              <Box p="10px !important">Peso considerado</Box>
            </Th>

            <Th>
              <Flex p="10px !important">Distância total em metros</Flex>
            </Th>

            <Th>
              <Flex p="10px !important">tCO2eq</Flex>
            </Th>

            <Th>
              <Flex p="10px !important">R$ / tCO2eq</Flex>
            </Th>

            {profileRole === 'ROLE_SYSTEM_ADMIN' && (
              <>
                <Th>
                  <Flex p="10px !important">Data de abertura</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Data de embarque esperada</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Data de embarque efetiva</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Data de chegada esperada</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Data de chegada efetiva</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Data de faturamento</Flex>
                </Th>
                <Th>
                  <Flex p="10px !important">Descrição de cálculo</Flex>
                </Th>
              </>
            )}
          </Tr>
        </Thead>

        <Tbody>
          {entity.length > 0 ? (
            entity.map((item, key) => (
              <Tr className="border" key={key}>
                <Td>
                  <Flex></Flex>
                </Td>

                <Td>
                  <Flex textColor="primary" fontWeight="bold" gap="5px">
                    {item?.process_code ?? '-'}
                  </Flex>
                </Td>

                <Td>
                  <Flex>
                    <Tooltip label="Visualizar processo">
                      <Link to={`/comex/process/detail/${item?.process_identifier}`} target="_blank">
                        <IconButton size="sm" icon={<MdOpenInNew size={20} cursor="pointer" color="#422C76" />} />
                      </Link>
                    </Tooltip>
                  </Flex>
                </Td>

                <Td>
                  <Flex w="100px">{item?.transport_modality_description ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="150px">{item?.stage_description ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="100px">{item?.incoterm ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="200px">{item?.origin_country_name ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="400px">{item?.origin_port_name ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="200px">{item?.destination_country_name ?? '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="400px">
                    <Tooltip label={item?.destination_port_name}>{item?.destination_port_name ?? '-'}</Tooltip>
                  </Flex>
                </Td>

                <Td>
                  <Flex w="200px">{item?.cts_weight_in_kg ? formatWeight(item.cts_weight_in_kg) : '-'}</Flex>
                </Td>

                <Td>
                  <Flex w="200px">
                    {item?.cts_distance_in_meters
                      ? parseFloat(item?.cts_distance_in_meters).toLocaleString('pt-BR', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })
                      : '-'}
                  </Flex>
                </Td>

                <Td>
                  <Flex w="200px">
                    {item?.cts_tco2eq
                      ? parseFloat(item?.cts_tco2eq).toLocaleString('pt-BR', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })
                      : '-'}
                  </Flex>
                </Td>

                <Td>
                  <Flex w="200px">{formatInvoice(item?.cts_tco2eq_cost_in_brl) ?? '-'}</Flex>
                </Td>

                {profileRole === 'ROLE_SYSTEM_ADMIN' && (
                  <>
                    <Td>
                      <Flex w="150px">{item?.process_started_at ? moment(item?.process_started_at).format('DD/MM/Y') : '-'}</Flex>
                    </Td>
                    <Td>
                      <Flex w="150px">
                        {item?.process_board_expected_at ? moment(item?.process_board_expected_at).format('DD/MM/Y') : '-'}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex w="150px">{item?.process_boarded_at ? moment(item?.process_boarded_at).format('DD/MM/Y') : '-'}</Flex>
                    </Td>
                    <Td>
                      <Flex w="150px">
                        {item?.process_arrival_expected_at ? moment(item?.process_arrival_expected_at).format('DD/MM/Y') : '-'}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex w="150px">{item?.process_arrived_at ? moment(item?.process_arrived_at).format('DD/MM/Y') : '-'}</Flex>
                    </Td>
                    <Td>
                      <Flex w="150px">{item?.billing_date ? moment(item?.billing_date).format('DD/MM/Y') : '-'}</Flex>
                    </Td>
                    <Td>
                      <Flex w="500px">
                        <Tooltip label={item?.cts_description}>
                          {item?.cts_description ? limitChars(item?.cts_description, 115) : '-'}
                        </Tooltip>
                      </Flex>
                    </Td>
                  </>
                )}
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td>
                <Flex></Flex>
              </Td>

              <Td colSpan={25}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} /> */}
    </Flex>
  );
};

export default TableProcessMetrics;
