import { useState } from 'react';

import { MdOutlineContentCopy, MdOutlineFileDownloadDone } from 'react-icons/md';

import { Box, Button, Text, Tooltip } from '@chakra-ui/react';

const truncateText = (value, maxLength) => {
  if (value && value.length > maxLength) {
    return value.substring(0, maxLength) + '...';
  }
  return value;
};

const ShortenText = ({ value, maxLength }) => {
  const [isCopied, setIsCopied] = useState(false);
  const truncatedText = truncateText(value, maxLength);

  const renderText = (text) => (
    <Text fontSize={'16px'} lineHeight={'20px'}>
      {(text && text === null) || text === '' ? (
        '-'
      ) : text && text.length > maxLength ? (
        <Tooltip label={text}>
          <Box>
            <span>{truncatedText}</span>
            <Button
              size="sm"
              ml={2}
              onClick={(e) => {
                e.stopPropagation();
                handleCopyToClipboard(value);
              }}>
              {isCopied ? <MdOutlineFileDownloadDone color="#6C48C2" size={15} /> : <MdOutlineContentCopy size={15} color="#6C48C2" />}
            </Button>
          </Box>
        </Tooltip>
      ) : (
        <Box>
          <span>{text}</span>
          <Button
            size="sm"
            ml={2}
            onClick={(e) => {
              e.stopPropagation();
              handleCopyToClipboard(value);
            }}>
            {isCopied ? <MdOutlineFileDownloadDone color="#6C48C2" size={15} /> : <MdOutlineContentCopy size={15} color="#6C48C2" />}
          </Button>
        </Box>
      )}
    </Text>
  );

  const handleCopyToClipboard = (text) => {
    copyToClipboard(text);
    setIsCopied(true);

    // Reseta o estado após alguns segundos
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // Função para copiar para a área de transferência
  const copyToClipboard = (text) => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
  };

  return renderText(value);
};

export default ShortenText;
