import { Text } from '@chakra-ui/react';

// Função para renderizar valores de um array
const renderArrayValues = (array) =>
  array.map((item, subIndex) => (
    <Text key={subIndex} fontSize={{ base: '0.75rem', md: '1.1rem' }}>
      {item}
    </Text>
  ));

export default renderArrayValues;
