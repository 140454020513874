import React, { useState } from 'react';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const TableClientMetrics = ({ entity, profileRole }) => {
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  // Função para verificar e formatar valores numéricos
  const formatNumber = (value, isCurrency = false, isPercentage = false) => {
    if (value === undefined || value === null || isNaN(value)) return '-';

    const numberValue = parseFloat(value);
    if (isPercentage) {
      return (
        numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + '%'
      );
    }
    return isCurrency
      ? numberValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      : numberValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  return (
    <Flex h="500px" w="full" direction="column" overflow="auto">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box p="10px!important"></Box>
            </Th>

            <Th>
              <Box p="10px!important">Cliente</Box>
            </Th>

            <Th>
              <Box p="10px!important">Peso</Box>
            </Th>

            <Th>
              <Box p="10px!important">CO2 (tCO2eq)</Box>
            </Th>

            <Th>
              <Box p="10px!important">Custo CO₂</Box>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {entity.length > 0 ? (
            entity.map((item, key) => (
              <Tr className="border" key={key}>
                <Td>
                  <Flex></Flex>
                </Td>

                <Td>
                  <Flex textColor="primary" fontWeight="bold">
                    {item?.client_name ?? '-'}
                  </Flex>
                </Td>

                <Td>
                  <Flex>{formatNumber(item?.cts_weight_in_kg)}</Flex>
                </Td>

                <Td>
                  <Flex>{formatNumber(item?.cts_tco2eq)}</Flex>
                </Td>

                <Td>
                  <Flex>{formatNumber(item?.cts_tco2eq_cost_in_brl, true)}</Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr className="border">
              <Td>
                <Flex></Flex>
              </Td>

              <Td colSpan={25}>
                <Flex justify="center">Não há resultados que correspondam aos filtros selecionados.</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} /> */}
    </Flex>
  );
};

export default TableClientMetrics;
