import { DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';

import CommonList from '../../../../components/CommonList/CommonList';

const NcmHistoryViewer = ({ identifier, action, metadata, setAction, setMetadata, ncmLogs }) => {
  return (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderLeft={'4px solid #A885F9'} m={'15px 20px'} color={'primary'} fontSize={'32px'}>
          Histórico
        </DrawerHeader>
        <DrawerBody>
          <CommonList
            categories={['Ação', 'Usuário']}
            list={ncmLogs}
            hasCollapsed={false}
            hasPagination={true}
            action={action}
            metadata={metadata}
            setAction={setAction}
            setMetadata={setMetadata}
          />
        </DrawerBody>
      </DrawerContent>
    </>
  );
};

export default NcmHistoryViewer;
