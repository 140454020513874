import { MdHelp } from 'react-icons/md';

import { Box, Grid, GridItem, Tooltip } from '@chakra-ui/react';

import SortableListHeader from './components/SortableListHeader';

const CommonListCategories = ({ children, ...props }) => {
  //default properties
  const categories = props?.categories;

  //responsiveness
  const isMobile = props?.isMobile;

  //length
  const gridTemplateLength = props?.gridTemplateLength;

  //category
  const tooltipCategories = props?.tooltipCategories;

  //ordering
  const sorting = props?.sorting;
  const handleSort = props?.handleSort;

  //rowSize
  const cellRowSize = props?.cellRowSize;

  return (
    !isMobile && (
      <Grid gridTemplateColumns={`repeat(${gridTemplateLength}, ${cellRowSize})`} data-stycky>
        {categories &&
          categories.map((category, key) => {
            return typeof category === 'string' ? (
              <GridItem as={'strong'} key={key} display={'flex'} textTransform={'uppercase'} fontSize={'13px'}>
                {category}{' '}
                {tooltipCategories &&
                  tooltipCategories.map((categoryObj) => {
                    return (
                      categoryObj.category === category && (
                        <Tooltip label={categoryObj.describe}>
                          <Box ml="0.325rem">
                            <MdHelp size={15} color="#422C76" />
                          </Box>
                        </Tooltip>
                      )
                    );
                  })}
              </GridItem>
            ) : (
              <GridItem as={'strong'} key={key} display={'flex'} textTransform={'uppercase'} fontSize={'13px'}>
                <SortableListHeader column={category.field} label={category.title} sorting={sorting} handleSort={handleSort} />
              </GridItem>
            );
          })}
      </Grid>
    )
  );
};

export default CommonListCategories;
