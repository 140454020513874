import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';

import useResponsiveCheck from '../../hooks/useResponsiveCheck';
import Paginate from '../Paginate/Paginate';

import CommonListCategories from './CommonListCategories';
import CommonListItemContent from './CommonListItemContent';
import CommonListStyles from './CommonListStyles';
import addRadioInputPreset from './Helpers/addRadioInputPreset';
import extractIdentifiers from './Helpers/extractIdentifiers';
import removeKeysFromList from './Helpers/removeKeysFromList';

/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  title titulo do componente (opcional - desejável)
 * @param  subtitle subtitle do componente (opcional)
 * @param  categories categorias da tabela (obrigatório, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  subCategories subcategorias da tabela (opcional, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  hiddenCategories categorias e subcategorias que deverão ser ocultas(opcional, exemplo: ['identifier', 'code'])
 * @param  mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * @param  tooltipCategories Aplica tooltips com descrição a categorias específicas.
 * @param  hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  customButtons gerar lista de botões personalizados (opcional, exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 * @param  customFields gerar lista de campos personalizados (opcional, exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }]
 * type: 'select | radio'
 * name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
 * action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
 *
 * options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
 * roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
 * bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 *
 * @param  collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), exemplo: ['status', 'situacao']
 * @param  statusPreset necessário para o funcionamento das tags (opcional)
 * @param  rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  list envio da lista a ser exibida (obrigatório)
 * @param  subList renderizar a lista secundária (opcional)
 * @param  pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 *
 * @param  action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 *
 */

const CommonList = ({ children, ...props }) => {
  //default properties
  const { list, categories } = props;

  //data
  const data = props?.rawData;

  //responsiveness
  const responsiveCheck = useResponsiveCheck();
  const isMobile = responsiveCheck.isMobile;
  const deviceType = responsiveCheck.deviceType;
  const viewport = responsiveCheck.viewport;
  const mainCategoryMobile = props?.mainCategoryMobile;

  //methods
  const setMetadata = props?.setMetadata;
  const setAction = props?.setAction;

  //conditionally
  //buttonDetails
  const hasDetailsButton = props?.hasDetailsButton;
  const detailsRedirectEndpoint = props?.detailsRedirectEndpoint;
  const hasDetailsButtonSublist = props?.hasDetailsButtonSublist;
  const detailsRedirectEndpointSublist = props?.detailsRedirectEndpointSublist;
  //buttonDelete
  const hasDeleteButton = props?.hasDeleteButton;
  const deleteButtonAction = props?.deleteButtonAction;
  //customButtons
  const customButtons = props?.customButtons;

  //customFields
  const customFields = props?.customFields;
  const radioBgColor = addRadioInputPreset(customFields);

  //pagination
  const action = props?.action;
  const metadata = props?.metadata;
  const hasPagination = props?.hasPagination;
  const hasPages = props?.metadata.total_pages > 1;
  //collapsed
  const hasCollapsed = props?.hasCollapsed;
  const collapsedContentType = props?.collapsedContentType;
  //category
  const hiddenCategories = props?.hiddenCategories;
  const tooltipCategories = props?.tooltipCategories;
  //status
  const tags = props?.tags;
  const statusPreset = props?.statusPreset;

  //ordering
  const sorting = props?.sorting;
  const handleSort = props?.handleSort;

  //instance
  const title = props?.title;
  const subtitle = props?.subtitle;
  const subcategories = props?.subCategories;
  const subList = props?.subList ? props.subList : [];
  const pathSublist = props?.pathSublist;

  //extrai todos os identifiers para utilizar em ações como da página de editar
  const identifier = list && extractIdentifiers(list);

  //formatar a lista removendo as categorias ocultas
  const formattedList = list && removeKeysFromList(list, hiddenCategories ? hiddenCategories : []);
  const formattedSubList = subList && removeKeysFromList(subList, hiddenCategories ? hiddenCategories : []);

  //messages
  const emptyListMessage = props?.emptyListMessage ?? 'Não existem registros disponíveis para exibição.';

  //resume
  const maxLengthResume = props?.maxLengthResume ?? 70;

  //rowSize
  const cellRowSize = props?.cellRowSize ?? '1fr';

  //length
  let categoryLength = categories?.length;
  let hiddenCategoriesLength = hiddenCategories?.length ? hiddenCategories.length : null;

  let gridTemplateLength = categoryLength + 1;
  let listLength;

  //calcula as colunas corretamente quando se adiciona/vazia o titulo ou children
  if (list && hiddenCategoriesLength && hiddenCategoriesLength != null) {
    listLength = list.length !== 0 ? Object.keys(list[0]).length - hiddenCategoriesLength : 0;
  } else {
    listLength = list && list.length !== 0 ? Object.keys(list[0]).length : 0;
  }

  children || hasDetailsButton || hasCollapsed ? listLength++ : listLength;
  listLength < categoryLength ? (listLength = categoryLength) : (categoryLength = listLength);
  gridTemplateLength = categoryLength === listLength ? categoryLength : categoryLength + 1;
  //calcula as colunas corretamente quando se adiciona/vazia o titulo ou children

  return (
    <Card m="10px" border={'1px solid #70707036'}>
      <Box overflow={'auto'}>
        {
          // title
          title && <Heading>{title}</Heading>
        }
        {
          //subtitle
          subtitle && <Text as={'h3'}>{subtitle}</Text>
        }
        <CommonListStyles radioBgColor={radioBgColor} viewport={viewport} cellRowSize={cellRowSize} />
        <Flex m={'15px'} flexDirection={'column'} gap={'20px'} width={{ md: 'max-content' }} minW={{ md: 'calc(100% - 30px)' }}>
          {/* Heading */}
          <CommonListCategories
            categories={categories}
            tooltipCategories={tooltipCategories}
            gridTemplateLength={gridTemplateLength}
            cellRowSize={cellRowSize}
            isMobile={isMobile}
            sorting={sorting}
            handleSort={handleSort}
          />
          <List display={'flex'} flexDirection={'column'} gap={'10px'}>
            {
              //mobile
              isMobile ? (
                <Accordion display={'flex'} gap={'15px'} flexDirection={'column'} isMobile={isMobile}>
                  {formattedList &&
                    formattedList?.map((item, key) => (
                      <AccordionItem
                        key={key}
                        border={'1px solid #70707036'}
                        borderRadius={'10px'}
                        columnGap={'15px'}
                        p={'0 10px'}
                        lineHeight={'40px'}
                        boxShadow="sm">
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {
                              /* exibe a categoria especificada na propriedade mainCategoryMobile se não existir exibe por padrão a primeira da lista */
                              mainCategoryMobile === (null || '' || undefined)
                                ? Object.entries(item)[0][1]
                                : item.hasOwnProperty(mainCategoryMobile) && item[mainCategoryMobile]
                            }
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel padding={'10px 0'}>
                          <CommonListItemContent
                            list={formattedList}
                            hasCollapsed={hasCollapsed}
                            collapsedContentType={collapsedContentType}
                            item={item}
                            content={children}
                            hiddenCategories={hiddenCategories}
                            gridTemplateLength={gridTemplateLength}
                            subList={subList}
                            subcategories={subcategories}
                            identifier={identifier[key]} // envia o identificador para a lista mapear a posição
                            hasDetailsButton={hasDetailsButton}
                            hasDetailsButtonSublist={hasDetailsButtonSublist}
                            detailsRedirectEndpoint={detailsRedirectEndpoint}
                            detailsRedirectEndpointSublist={detailsRedirectEndpointSublist}
                            hasDeleteButton={hasDeleteButton}
                            deleteButtonAction={deleteButtonAction}
                            customButtons={customButtons}
                            customFields={customFields}
                            isMobile={isMobile}
                            deviceType={deviceType}
                            viewport={viewport}
                            categories={categories}
                            statusPreset={statusPreset}
                            maxLengthResume={maxLengthResume}
                            emptyListMessage={emptyListMessage}
                            pathSublist={pathSublist}
                            tooltipCategories={tooltipCategories}
                            formattedSubList={formattedSubList}
                            cellRowSize={cellRowSize}
                            listIndex={key}
                            tags={tags}
                            data={data}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                </Accordion>
              ) : //desktop
              formattedList && formattedList.length > 0 ? (
                formattedList?.map((item, key) => (
                  <ListItem key={key}>
                    <CommonListItemContent
                      list={formattedList}
                      hasCollapsed={hasCollapsed}
                      collapsedContentType={collapsedContentType}
                      item={item}
                      content={children}
                      hiddenCategories={hiddenCategories}
                      gridTemplateLength={gridTemplateLength}
                      subList={subList}
                      subcategories={subcategories}
                      identifier={identifier[key]} // envia o identificador para a lista mapear a posição
                      hasDetailsButton={hasDetailsButton}
                      hasDetailsButtonSublist={hasDetailsButtonSublist}
                      detailsRedirectEndpoint={detailsRedirectEndpoint}
                      detailsRedirectEndpointSublist={detailsRedirectEndpointSublist}
                      hasDeleteButton={hasDeleteButton}
                      deleteButtonAction={deleteButtonAction}
                      customButtons={customButtons}
                      customFields={customFields}
                      isMobile={isMobile}
                      deviceType={deviceType}
                      viewport={viewport}
                      categories={categories}
                      statusPreset={statusPreset}
                      maxLengthResume={maxLengthResume}
                      tooltipCategories={tooltipCategories}
                      pathSublist={pathSublist}
                      emptyListMessage={emptyListMessage}
                      formattedSubList={formattedSubList}
                      cellRowSize={cellRowSize}
                      listIndex={key}
                      tags={tags}
                      data={data}
                    />
                  </ListItem>
                ))
              ) : (
                <Text alignSelf={'center'}>{emptyListMessage}</Text>
              )
            }
          </List>
        </Flex>
      </Box>
      {
        //Paginate
        hasPagination && hasPages && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )
      }
    </Card>
  );
};

export default CommonList;
