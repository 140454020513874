import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Avatar, Card, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import LabelWithText from '../../../components/Generic/LabelWithText';
import Tag from '../../../components/Generic/Tag';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import requests from '../../../services/requests';
import { driver } from '../../../utils/exports';
import { convertColorUser, descriptionStatusUser } from '../../../utils/translate';

import DocumentsTab from './components/Documents/DocumentsTab';
import PersonalDataTab from './components/PersonalData/PersonalDataTab';
import ProfileButtons from './components/ProfileButtons';
import ReportsTab from './components/Reports/ReportsTab';
import TravelsTab from './components/Travels/TravelsTab';
import VehiclesTab from './components/Vehicles/VehiclesTab';

const DriverProfilePage = () => {
  const { identifier } = useParams();
  const [entity, setEntity] = useState([]);
  const [load, setLoad] = useState(false);
  const [lastAcess, setLastAcess] = useState(null);

  const listUser = () => {
    setLoad(true);
    requests.showUserDriver(identifier).then((response) => {
      setEntity(response);

      requests.lastAccessProfile(identifier).then((response) => {
        if (response?.date != null) {
          setLastAcess(response.date);
        }
      });
      setLoad(false);
    });
  };

  useEffect(() => {
    listUser();
  }, []);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: '/logistic/drivers', title: 'Gestão de motoristas' },
        ]}
        placeholder="Pesquisar motoristas..."
        title={entity?.name}
        icon={driver}
        hideAllFilters={true}
      />
      <Flex minH="calc(100vh - 254px)" flexDirection={{ base: 'column', md: 'row' }}>
        <Card m="10px" p="10px" w={{ base: 'initial', md: '500px' }}>
          <Flex direction="column" alignItems="center" justify="space-around">
            <Avatar
              src={`${process.env.REACT_APP_API_URL}${entity?.profilePictureUri?.replace('//profile', '/profile')}`}
              size="xl"
              mb="15px"
            />
            <Tag type={convertColorUser(entity?.status)}>{descriptionStatusUser(entity?.status)}</Tag>
            <Text fontSize={{ base: '20px', md: '30px' }} fontWeight="500" lineHeight="30px" color="#422C76" mt="15px">
              {entity?.name}
            </Text>
            <Text fontSize="14px" color="#422C76" mb="15px">
              {entity?.type == 'EXTERNAL' ? 'Parceiro externo' : 'Motorista Vendemmia'}
            </Text>
            <ProfileButtons user={entity} refresh={listUser} />

            <Tabs mt="20px" position="relative" variant="unstyled">
              <TabList justifyContent="center">
                <Tab>
                  <Text fontSize="14px" color="#6C48C2">
                    Dados de acesso
                  </Text>
                </Tab>
                <Tab>
                  <Text fontSize="14px" color="#6C48C2">
                    Dados de endereço
                  </Text>
                </Tab>
              </TabList>
              <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />
              <TabPanels>
                <TabPanel>
                  <Flex flexFlow="wrap" justifyContent="space-between" gap="15px">
                    <LabelWithText title="E-mail" content={entity?.email} />
                    <LabelWithText title="Telefone" content={entity?.cellphone} />
                    {!load &&
                      entity?.additional?.length > 0 &&
                      entity?.additional?.map(function (item, index) {
                        if (item.group == 'access') {
                          return <LabelWithText key={index} title={item.name} content={item.description} />;
                        }
                      })}
                    <LabelWithText title="Último acesso" content={lastAcess} />
                    {entity?.lastVersionApp != null && <LabelWithText title="Última versão app" content={entity.lastVersionApp} />}
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex flexFlow="wrap" justifyContent="space-between" gap="15px">
                    {!load &&
                      entity?.additional?.length > 0 &&
                      entity?.additional?.map(function (item, index) {
                        if (item.group == 'address') {
                          return <LabelWithText key={index} title={item.name} content={item.description} />;
                        }
                      })}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Card>
        <Card m="10px" p="10px" w={{ base: 'initial', md: '100%' }}>
          <Tabs position="relative" variant="unstyled">
            <TabList display={{ base: 'flex', md: 'row' }} flexDirection={{ base: 'column', md: 'row' }}>
              <Tab>Documentação</Tab>
              <Tab>Dados Pessoais</Tab>
              <Tab>Viagens</Tab>
              <Tab>Veículos</Tab>
              <Tab>Relatórios</Tab>
              {/*<Tab>Localização</Tab>
                  <Tab>Checklist</Tab>*/}
            </TabList>
            <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />
            <TabPanels overflow={'scroll'}>
              <DocumentsTab user={entity} refresh={listUser} />
              <PersonalDataTab user={entity} refresh={listUser} />
              <TravelsTab user={entity} />
              <VehiclesTab user={entity} />
              <ReportsTab user={entity} />
              {/*<LocationTab />
                <ChecklistTab />*/}
            </TabPanels>
          </Tabs>
        </Card>
      </Flex>
    </Layout>
  );
};

export default DriverProfilePage;
