import React, { useEffect, useState } from 'react';

import { Bar, CartesianGrid, ComposedChart, LabelList, XAxis, YAxis } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const ShipmentAnalysisChart = ({ data }) => {
  const windowWidth = window.innerWidth;
  let chartWidth = windowWidth < 768 ? 280 : 350;

  const [entity, setEntity] = useState([]);

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  useEffect(() => {
    const formatValue = data.average ? data.average.toLocaleString('pt-BR') : '';

    const fetchData = async () => {
      try {
        const chartData = [
          {
            average: data.average,
            color: '#422C76',
            formatValue: formatValue,
          },
        ];

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" direction={{ base: 'column', md: 'row' }}>
      <ComposedChart data={entity} width={chartWidth} height={250} margin={{ left: 60, bottom: -10 }}>
        <CartesianGrid vertical={false} />
        <XAxis tick={false} />
        <YAxis tickFormatter={tickFormatter} />
        <Bar dataKey="average" barSize={135} fill={entity.length > 0 ? entity[0].color : null}>
          <LabelList dataKey="formatValue" position="center" fill="#FFFFFF" />
        </Bar>
      </ComposedChart>

      <Flex direction="column" gap="20px">
        {entity.map((entry, key) => (
          <Flex direction="column" align="center" key={key}>
            <Flex gap="10px" align="center">
              <Box w="17px" h="17px" bgColor="#422C76" borderRadius="3px" />
              <Text>Remessas</Text>
            </Flex>

            <Text textStyle="tableTitle" textColor="#0F0A1D" fontWeight="bold">
              {entry.formatValue || 0}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ShipmentAnalysisChart;
