import React, { useRef, useState } from 'react';

import moment from 'moment';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import Tag from '../../../../components/Generic/Tag';
import Page from '../../../../components/Page';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';

import ModalCancelPolicy from './components/ModalCancelPolicy';
import ModalEditPolicy from './components/ModalEditPolicy';
import ModalNewPolicy from './components/ModalNewPolicy';
import ModalPublishPolicy from './components/ModalPublishPolicy';
import ModalViewPolicy from './components/ModalViewPolicy';

const PolicyPrivacyPage = () => {
  const hasPermission = permissions.vendemmiaManagementPolicy;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const request = useRef(0);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    requests.listPolicyPrivacy(filters, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const status = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'green';
      case 'DEPRECATED':
        return 'gray';
      case 'CANCELLED':
        return 'red';
      default:
        return 'yellow';
    }
  };

  const textStatus = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'Publicado';
      case 'DEPRECATED':
        return 'Obsoleto';
      case 'CANCELLED':
        return 'Cancelado';
      default:
        return 'Rascunho';
    }
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  return (
    <Page
      screen="policy-privacy"
      title="Politicas de Privacidade"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      showFilters={false}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}
      allowEmptySearchString={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar política de privacidade',
          text: 'Cadastro de oolítica de privacidade',
          action: <ModalNewPolicy addPolicyToList={triggerRefresh} />,
          modality: 'custom',
        },
      ]}>
      <Card m="10px">
        <Box p="15px">
          {!isMobile ? (
            <Table className="table-default min">
              <Thead border="1px" borderBottom="1px">
                <Tr>
                  <Th>Conteúdo</Th>
                  <Th>Data de criação</Th>
                  <Th>Ultima atualização</Th>
                  <Th>Status</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>

              <Tbody border="1px">
                {list?.map((entity) => (
                  <React.Fragment key={entity?.identifier}>
                    <Tr>
                      <Td w="60%">{limitChars(entity?.description, 100)}</Td>
                      <Td>{moment(entity?.createdAt).format('DD/MM/YYYY ')}</Td>
                      <Td>{moment(entity?.updatedAt).format('DD/MM/YYYY ')}</Td>
                      <Td>
                        <Tag type={status(entity?.status)}>{textStatus(entity?.status)}</Tag>
                      </Td>

                      <Td>
                        <Flex gap="10px" align="center" h="30px">
                          {entity.status === 'DRAFT' && <ModalEditPolicy entity={entity} onSave={triggerRefresh} />}
                          {entity.status === 'DRAFT' && <ModalPublishPolicy entity={entity} onSave={triggerRefresh} />}
                          {entity.status === 'DRAFT' && <ModalCancelPolicy entity={entity} onSave={triggerRefresh} />}
                          {(entity.status === 'PUBLISHED' || entity.status === 'DEPRECATED') && <ModalViewPolicy entity={entity} />}
                        </Flex>
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex flexDirection={'column'}>
              <Grid p={'10px'} fontWeight={'bold'}>
                <GridItem>Conteúdo</GridItem>
              </Grid>
              <Accordion>
                {list?.map((entity, key) => (
                  <AccordionItem key={key} _odd={{ bg: '#eee' }} borderBottom={'1px solid #E2E8F0'} borderTop={0} mb={'10px'}>
                    <AccordionButton
                      display="flex"
                      flexDirection={'column'}
                      gap={'15px'}
                      p={'10px 10px 15px'}
                      _hover={{ bg: 'transparent' }}>
                      <Flex gap={'10px'} textAlign={'left'} color={'#707070'}>
                        {limitChars(entity?.description, 100)}
                        <AccordionIcon />
                      </Flex>

                      <Flex gap="10px" w={'full'} align="center">
                        <Tag type={status(entity?.status)}>{textStatus(entity?.status)}</Tag>
                        <Flex gap={'10px'}>
                          <ModalEditPolicy entity={entity} onSave={triggerRefresh} />
                          <ModalPublishPolicy entity={entity} onSave={triggerRefresh} />
                          <ModalCancelPolicy entity={entity} onSave={triggerRefresh} />
                          {(entity.status === 'PUBLISHED' || entity.status === 'DEPRECATED') && <ModalViewPolicy entity={entity} />}
                        </Flex>
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel display={'grid'} gridTemplateColumns={'1fr 1fr'} bg={'white'} p={'8px 16px 15px'}>
                      <Text fontSize={'14px'}>
                        <strong>Data de criação: </strong>
                        {moment(entity?.createdAt).format('DD/MM/YYYY')}
                      </Text>
                      <Text fontSize={'14px'}>
                        <strong>Ultima atualização: </strong>
                        {moment(entity?.updatedAt).format('DD/MM/YYYY')}
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Flex>
          )}
        </Box>
      </Card>
    </Page>
  );
};

export default PolicyPrivacyPage;
