import { toast } from 'react-toastify';

import { saveImportFile } from '../../services/requests';

/**
 * Função para realizar a importação de arquivos.
 * @param {string} endpoint - O endpoint da API para enviar os arquivos.
 * @param {Array} docs - A lista de documentos a serem importados.
 * @param {object} config - A configuração para a importação.
 */
export const importFileAction = async (endpoint, docs, config) => {
  const toastId = toast.info('Aguarde enquanto enviamos os arquivos para o servidor...', {
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
  });

  try {
    await saveImportFile(endpoint, docs, config);

    toast.dismiss(toastId);
    toast.success('Upload efetuado com sucesso!', { autoClose: 1500 });
  } catch (error) {
    toast.dismiss(toastId);
    toast.error('Problemas ao realizar o upload. Tente novamente ou entre em contato com o administrador.');
    console.error('Erro durante a importação:', error.message || error);
  }
};
