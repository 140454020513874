import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { MdEditDocument, MdEditNote, MdOutlineFileDownload } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Divider, Flex, Icon, Tab, TabIndicator, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import Layout from '../../../../components/Layout';
import Menubar from '../../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../services/permissions';
import getProfile from '../../../../services/profile';
import requests from '../../../../services/requests';
import commercialValidation from '../Helpers/commercialValidation';
import contactValidation from '../Helpers/contactValidation';
import financialValidation from '../Helpers/financialValidation';
import initialValues from '../Helpers/initialValues';
import operationalValidation from '../Helpers/operationalValidation';
import registrationValidation from '../Helpers/registrationValidation';
import responsibleValidation from '../Helpers/responsibleValidation';
import tributaryValidation from '../Helpers/tributaryValidation';
import ValidationsSpecification from '../Helpers/validations';

import CommercialDataForm from './CommercialDataForm';
import ContactDataForm from './ContactDataForm';
import FinancialDataForm from './FinancialDataForm';
import OperationalDataForm from './OperationalDataForm';
import RegistrationDataForm from './RegistrationDataForm';
import ResponsibleUsersDataForm from './ResponsibleUsersDataForm';
import TributariesDataForm from './TributariesDataForm';

const ContractManagementPage = () => {
  const hasManagementPermission = permissions.vendemmiaOperationSpecificationManagement;

  const { identifier, isPreviwer } = useParams();
  const initialIconErrors = identifier == null ? true : false;
  const [isEditMode, setIsEditMode] = useState(false);
  const [entity, setEntity] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isSecondaryZone, setIsSecondaryZone] = useState(false);
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [isDownloadingOriginalFile, setIsDownloadingOriginalFile] = useState(false);
  const [isLoadingSign, setIsLoadingSign] = useState(false);

  const [tabDataErrors, setTabDataErrors] = useState(initialIconErrors);
  const [tabContactErrors, setTabContactErrors] = useState(initialIconErrors);
  const [tabCommercialErrors, setTabCommerciaErrors] = useState(initialIconErrors);
  const [tabTaxErrors, setTabTaxErrors] = useState(initialIconErrors);
  const [tabFinancialErrors, setTabFinancialErrors] = useState(initialIconErrors);
  const [tabOperationalErrors, setTabOperationalErrors] = useState(initialIconErrors);
  const [tabResponsibleErrors, setTabResponsibleErrors] = useState(initialIconErrors);

  const getContract = (identifier) => {
    setIsLoadingEntity(true);

    requests.showOperation(identifier).then((data) => {
      setEntity(data);

      setIsLoadingEntity(false);
    });
  };

  const getDownloadContract = (isOriginal = true) => {
    if (isOriginal) {
      setIsDownloadingOriginalFile(true);
      requests
        .operationDownloadFileAction(entity.identifier)
        .then((response) => {
          const fileType = response.headers['content-type'];
          const blob = new Blob([response.data], { fileType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', entity?.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          toast.error('Problemas ao baixar contrato, tente novamente ou entre em contato com o Administrador');
          setIsDownloadingOriginalFile(false);
        })
        .finally(() => {
          setIsDownloadingOriginalFile(false);
        });
    }
  };

  const getConsent = () => {
    setIsLoadingSign(true);
    requests
      .operationConsentAction(entity.identifier)
      .then(() => {
        toast.success('Assinatura efetuada com sucesso');
        setIsLoadingSign(false);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(() => {
        setIsLoadingSign(false);
        toast.error('Problemas ao realizar assinatura, tente novamente ou entre em contato com o Administrador');
      });
  };

  const validateSchema = (schema, schemaName, values, showIconWarning) => {
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        showIconWarning(false);
      })
      .catch((errors) => {
        showIconWarning(true);
      });
  };

  const validateForm = (values) => {
    const registrationSchema = registrationValidation();
    validateSchema(registrationSchema, 'registrationSchema', values.registration, setTabDataErrors);

    const contactSchema = contactValidation(isDraft);
    validateSchema(contactSchema, 'contactSchema', values.contact, setTabContactErrors);

    const commercialSchema = commercialValidation(isDraft);
    validateSchema(commercialSchema, 'commercialSchema', values.commercial, setTabCommerciaErrors);

    const tributarySchema = tributaryValidation(isDraft);
    validateSchema(tributarySchema, 'tributarySchema', values.tributary, setTabTaxErrors);

    const operationalSchema = operationalValidation(isDraft, isSecondaryZone);
    validateSchema(operationalSchema, 'operationalSchema', values.operational, setTabOperationalErrors);

    const financialSchema = financialValidation(isDraft);
    validateSchema(financialSchema, 'financialSchema', values.financial, setTabFinancialErrors);

    const responsibleSchema = responsibleValidation(isDraft);
    validateSchema(responsibleSchema, 'responsibleSchema', values.responsibleUsers, setTabResponsibleErrors);
  };

  useEffect(() => {
    localStorage.setItem('edit', false);

    if (identifier !== undefined) {
      getContract(identifier);
    }
  }, []);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Vendemmia' },
          { link: '/vendemmia/operation-specification', title: 'Especificação operacional' },
        ]}
        title={
          entity?.identifier && isPreviwer
            ? 'Detalhes da especificação operacional'
            : entity?.identifier
            ? 'Edição da especificação operacional'
            : 'Nova especificação operacional'
        }
        hideAllFilters={true}
      />
      {isLoadingEntity ? (
        <ScreenLoader isLoading={isLoadingEntity} />
      ) : (
        <Tabs position="relative" overflow="hidden" variant="unstyled" isFitted>
          <Formik
            initialValues={initialValues(entity)}
            validationSchema={ValidationsSpecification(isDraft, isSecondaryZone)}
            onSubmit={(values, { setSubmitting }) => {
              if (isDraft) {
                setIsLoadingDraft(true);
              } else {
                setIsLoadingPublish(true);
              }

              var sumPercent = 0;
              if (values?.hasCashFlow === true) {
                if (values?.operational?.exchanges?.length > 0) {
                  values.operational.exchanges.map((exchange) => {
                    sumPercent += parseFloat(exchange.percent.replace('%', '').replaceAll(',', '.'));
                  });

                  if (!isDraft && (sumPercent < 100.0 || sumPercent > 100.0)) {
                    toast.error("Preencha corretamente o campo 'Porcentagem do pagamento do câmbio' na aba 'Dados operacionais'");
                    setSubmitting(false);
                    return;
                  }
                }
              }

              if (!isDraft && values?.operational?.ncmRelations?.length === 0) {
                toast.error("Preencha corretamente o campo 'Relação dos NCMs' na aba 'Dados operacionais'");
                setSubmitting(false);
                return;
              }

              let responsibleUsers = [
                {
                  identifier: values?.responsibleUsers?.comercial?.value,
                  type: values?.responsibleUsers?.comercial?.type,
                },
                {
                  identifier: values?.responsibleUsers?.tax?.value,
                  type: values?.responsibleUsers?.tax?.type,
                },
                {
                  identifier: values?.responsibleUsers?.operator?.value,
                  type: values?.responsibleUsers?.operator?.type,
                },
                {
                  identifier: values?.responsibleUsers?.financial?.value,
                  type: values?.responsibleUsers?.financial?.type,
                },
                {
                  identifier: values?.responsibleUsers?.director?.value,
                  type: values?.responsibleUsers?.director?.type,
                },
                {
                  identifier: values?.responsibleUsers?.logistic?.value,
                  type: values?.responsibleUsers?.logistic?.type,
                },
                {
                  identifier: values?.responsibleUsers?.additionalComercial?.value,
                  type: 'additionalComercial',
                },
              ];

              let registration = {
                vendemmiaOperationUnit: values?.registration?.vendemmiaOperationUnit,
                operationType: values?.registration?.operationType,
                companyName: values?.registration?.companyName,
                companyFantasyName: values?.registration?.companyFantasyName,
                companyOperationDocumentNumber: values?.registration?.companyOperationDocumentNumber.value,
                companyOperationSubscriptionState: values?.registration?.companyOperationSubscriptionState,
                companyBranches: values?.registration?.companyBranches,
                companyOperationName: values?.registration?.companyOperationName,
              };

              let contact = {
                commercial: values?.contact?.commercial,
                operation: values?.contact?.operation,
                financial: values?.contact?.financial,
                legal: values?.contact?.legal,
              };

              let commercial = {
                additionalInformation: values?.commercial?.additionalInformation,
                annualBilling: values?.commercial?.annualBilling,
                monthlyBilling: values?.commercial?.monthlyBilling,
                isIndefiniteContract: values?.commercial?.isIndefiniteContract,
                contractValidUntil: values?.commercial?.contractValidUntil,
                isProject: values?.commercial?.isProject,
                generalRestrictionsInformation: values?.commercial?.generalRestrictionsInformation,
                profitabilityInformation: values?.commercial?.profitabilityInformation,
              };

              let tributary = {
                additionalInformation: values?.tributary?.additionalInformation,
                fiscalBenefit: values?.tributary?.fiscalBenefit,
                isIcmsTaxpayer: values?.tributary?.isIcmsTaxpayer,
                operationPurpose: values?.tributary?.operationPurpose,
                taxRegime: values?.tributary?.taxRegime,
              };

              let operational = {
                customsClearanceLocation: values?.operational?.customsClearanceLocation,
                customsClearanceLocationAdditionalInformation: values?.operational?.customsClearanceLocationAdditionalInformation,
                dispatchValue: values?.operational?.dispatchValue,
                useSystemBravos: values?.operational?.useSystemBravos,
                forwardingAgent: values?.operational?.forwardingAgent,
                forwardingAgentAdditionalInformation: values?.operational?.forwardingAgentAdditionalInformation,
                goodsEntranceFederativeAdditionalInformation: values?.operational?.goodsEntranceFederativeAdditionalInformation,
                goodsEntranceFederativeUnit: values?.operational?.goodsEntranceFederativeUnit,
                hasInternationalTransportQuote: values?.operational?.hasInternationalTransportQuote,
                importingLicenseAdditionalInformation: values?.operational?.importingLicenseAdditionalInformation,
                importingLicenseConsentingEntity: values?.operational?.importingLicenseConsentingEntity,
                importingLicenseValue: values?.operational?.importingLicenseValue,
                incoterm: values?.operational?.incoterm,
                incotermAdditionalInformation: values?.operational?.incotermAdditionalInformation,
                insuranceAdditionalInformation: values?.operational?.insuranceAdditionalInformation,
                insuranceCalculationBasisClient: values?.operational?.insuranceCalculationBasisClient,
                insuranceCalculationBasisVendemmia: values?.operational?.insuranceCalculationBasisVendemmia,
                isAntidumping: values?.operational?.isAntidumping,
                nationalFreightAdditionalInformation: values?.operational?.nationalFreightAdditionalInformation,
                transportType: values?.operational?.transportType,
                nationalFreightBilling: values?.operational?.nationalFreightBilling,
                nationalFreightModality: values?.operational?.nationalFreightModality,
                freightAdditionalInformation: values?.operational?.freightAdditionalInformation,
                ncmRelations: values?.operational?.ncmRelations,
                operationNature: values?.operational?.operationNature,
                productTypeDescription: values?.operational?.productTypeDescription,
                storageAfterBilling: values?.operational?.storageAfterBilling,
                goodsEntranceFederativeUnitModality: values?.operational?.goodsEntranceFederativeUnitModality,
                storageAfterBillingAdditionalInformation: values?.operational?.storageAfterBillingAdditionalInformation,
                transportModality: values?.operational?.transportModality,
                nationalizationFactor: values?.operational?.nationalizationFactor,
                nationalizationDays: values?.operational?.nationalizationDays,
                expensesFactor: values?.operational?.expensesFactor,
                expensesDays: values?.operational?.expensesDays,
                billingFactor: values?.operational?.billingFactor,
                billingDays: values?.operational?.billingDays,
                exchanges: values?.operational?.exchanges || [],
                exchangeSumPercent: sumPercent,
                additionalInformation: values?.operational?.additionalInformation,
                hasCashFlow: values?.operational?.hasCashFlow,
              };

              let financial = {
                administrateTax: values?.financial?.administrateTax,
                commercialDiscount: values?.financial?.commercialDiscount,
                commercialDiscountAdditionalInformation: values?.financial?.commercialDiscountAdditionalInformation,
                discountValue: values?.financial?.discountValue,
                exporterPayment: values?.financial?.exporterPayment,
                exporterPaymentAdditionalInformation: values?.financial?.exporterPaymentAdditionalInformation,
                financialCharges: values?.financial?.financialCharges,
                financialChargesAddition: values?.financial?.financialChargesAddition,
                financialChargesAdditionalInformation: values?.financial?.financialChargesAdditionalInformation,
                financialChargesModality: values?.financial?.financialChargesModality,
                icmsRates: values?.financial?.icmsRates,
                paymentTermsAdditionalInformation: values?.financial?.paymentTermsAdditionalInformation,
                paymentTermsCommodity: values?.financial?.paymentTermsCommodity,
                paymentTermsCommodityDays: values?.financial?.paymentTermsCommodityDays,
                paymentTermsExpenses: values?.financial?.paymentTermsExpenses,
                paymentTermsExpensesDays: values?.financial?.paymentTermsExpensesDays,
                paymentTermsServiceInvoice: values?.financial?.paymentTermsServiceInvoice,
                paymentTermsServiceInvoiceDays: values?.financial?.paymentTermsServiceInvoiceDays,
                paymentTermsTax: values?.financial?.paymentTermsTax,
                paymentTermsTaxDays: values?.financial?.paymentTermsTaxDays,
                serviceInvoice: values?.financial?.serviceInvoice,
                serviceInvoiceAdditionalInformation: values?.financial?.serviceInvoiceAdditionalInformation,
                serviceInvoiceMinValue: values?.financial?.serviceInvoiceMinValue,
                serviceInvoiceModality: values?.financial?.serviceInvoiceModality,
                upFrontPaymentWarranty: values?.financial?.upFrontPaymentWarranty,
                upFrontPaymentWarrantyAdditionalInformation: values?.financial?.upFrontPaymentWarrantyAdditionalInformation,
                extraCommercialDiscount: values?.financial?.extraCommercialDiscount,
                hasAdministrativeFee: values?.financial?.hasAdministrativeFee,
                administrativeFee: values?.financial?.administrativeFee,
                minAdministrativeFee: values?.financial?.minAdministrativeFee,
                administrativeFeeAdditionalInformation: values?.financial?.administrativeFeeAdditionalInformation,
              };

              let aux = {
                status: isDraft ? 'RASCUNHO' : 'AGUARDANDO_ASSINATURA',
                responsibleUsers: responsibleUsers,
                registration: registration,
                contact: contact,
                commercial: commercial,
                tributary: tributary,
                operational: operational,
                financial: financial,
                vendemmiaOperationUnit: values?.registration?.vendemmiaOperationUnit,
                operationType: values?.registration?.operationType,
                companyName: values?.registration?.companyName,
                companyFantasyName: values?.registration?.companyFantasyName,
                companyOperationDocumentNumber: values?.registration?.companyOperationDocumentNumber.value,
                companyOperationSubscriptionState: values?.registration?.companyOperationSubscriptionState,
                companyBranches: values?.registration?.companyBranches,
              };

              if (entity?.identifier) {
                requests
                  .editOperationAction(entity.identifier, aux)
                  .then((response) => {
                    setSubmitting(false);
                    toast.success('Especificação da operação editada com sucesso!');

                    if (values.documents.length > 0) {
                      const data = new FormData();
                      for (var i = 0; values.documents.length > i; i++) {
                        data.append(`file_upload_multiple[files][${i}]`, values.documents[i]);
                      }

                      requests.importOperationDocumentsAction(response.identifier, data);
                    }

                    setTimeout(() => {
                      window.location.href = '/vendemmia/operation-specification';
                    }, 2000);
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setLoadingEntity(false);
                    setIsLoadingPublish(false);
                    setIsLoadingDraft(false);
                  });
              } else {
                requests
                  .newOperationAction(aux)
                  .then((response) => {
                    setSubmitting(false);
                    setLoadingEntity(false);

                    if (values.documents.length > 0) {
                      const data = new FormData();
                      for (var i = 0; values.documents.length > i; i++) {
                        data.append(`file_upload_multiple[files][${i}]`, values.documents[i]);
                      }

                      requests.importOperationDocumentsAction(response.identifier, data);
                    }

                    setTimeout(() => {
                      window.location.href = '/vendemmia/operation-specification';
                      toast.success('Especificação da operação cadastrada com sucesso!');
                    }, 2000);
                  })
                  .catch((error) => {
                    setLoadingEntity(false);
                    setSubmitting(false);
                    setIsLoadingPublish(false);
                    setIsLoadingDraft(false);
                  });
              }
            }}>
            {({ values, setFieldValue, handleSubmit, handleChange, errors }) => (
              <form onSubmit={handleSubmit}>
                <Card
                  m="10px"
                  height="100%"
                  header={
                    <Flex position="relative" w="full">
                      <TabList w="full">
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados cadastrais
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabDataErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados de contato
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabContactErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados comerciais
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabCommercialErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados tributários
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabTaxErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados financeiros
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabFinancialErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Dados operacionais
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabOperationalErrors ? 'red' : 'gray-100'} />
                        </Tab>
                        <Tab
                          px="0px"
                          py="10px"
                          onClick={(decision) => {
                            if (decision) {
                              validateForm(values);
                            }
                          }}>
                          <Text fontSize="14px" color="#6C48C2">
                            Usuários responsáveis
                          </Text>
                          <Icon ml="1" as={RiErrorWarningLine} boxSize={4} color={tabResponsibleErrors ? 'red' : 'gray-100'} />
                        </Tab>
                      </TabList>

                      <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                    </Flex>
                  }>
                  <Flex overflowY="auto" h="calc(100vh - 290px)">
                    <TabPanels p="15px" pt="0">
                      <RegistrationDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                      />

                      <ContactDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                      />

                      <CommercialDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                      />

                      <TributariesDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                      />

                      <FinancialDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                      />

                      <OperationalDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        setIsSecondaryZone={setIsSecondaryZone}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                        isSecondaryZone={isSecondaryZone}
                      />

                      <ResponsibleUsersDataForm
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isPreviwer={isPreviwer}
                        isDraft={isDraft}
                      />
                    </TabPanels>
                  </Flex>

                  <Divider />

                  <Flex justify="flex-end" gap="20px" p="10px">
                    {isPreviwer ? (
                      <>
                        <Link to="/vendemmia/operation-specification">
                          <Button variant="secundary" loadingText="Salvando...">
                            Voltar
                          </Button>
                        </Link>

                        {hasManagementPermission && (
                          <Link onClick={setIsEditMode(true)} to={`/vendemmia/operation-specification/detail/${entity.identifier}`}>
                            <Button variant="secundary" leftIcon={<MdEditNote />} loadingText="Salvando...">
                              Editar contrato
                            </Button>
                          </Link>
                        )}

                        <Button
                          variant="primary"
                          type="submit"
                          onClick={() => getDownloadContract(true)}
                          isLoading={isDownloadingOriginalFile}
                          leftIcon={<MdOutlineFileDownload />}
                          loadingText="Baixando...">
                          Baixar contrato em .pdf
                        </Button>

                        {entity.status === 'AGUARDANDO_ASSINATURA' &&
                          ((getProfile.identifier === entity.responsibleUsers.comercial?.identifier &&
                            !entity.responsibleUsers.comercial.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.additionalComercial?.identifier &&
                              !entity.responsibleUsers.additionalComercial.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.tax?.identifier &&
                              !entity.responsibleUsers.tax.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.operator?.identifier &&
                              !entity.responsibleUsers.operator.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.financial?.identifier &&
                              !entity.responsibleUsers.financial.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.director?.identifier &&
                              !entity.responsibleUsers.director.signedAt) ||
                            (getProfile.identifier === entity.responsibleUsers.logistic?.identifier &&
                              !entity.responsibleUsers.logistic.signedAt)) && (
                            <Button
                              variant="primary"
                              type="submit"
                              isLoading={isLoadingSign}
                              onClick={() => getConsent()}
                              leftIcon={<MdEditDocument />}
                              loadingText="Assinando...">
                              Assinar
                            </Button>
                          )}
                      </>
                    ) : (
                      <>
                        <Link to="/vendemmia/operation-specification">
                          <Button variant="secundary">Voltar</Button>
                        </Link>

                        <Button
                          variant="secundary"
                          type="submit"
                          onClick={() => {
                            setIsDraft(true);
                          }}
                          isLoading={isLoadingDraft}
                          loadingText="Salvando como rascunho...">
                          Salvar como rascunho
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          onClick={() => {
                            setIsDraft(false);
                          }}
                          isLoading={isLoadingPublish}
                          loadingText="Publicando...">
                          Publicar
                        </Button>
                      </>
                    )}
                  </Flex>
                </Card>
              </form>
            )}
          </Formik>
        </Tabs>
      )}
    </Layout>
  );
};
export default ContractManagementPage;
