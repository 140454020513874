import { useState } from 'react';

//methods
export const commonListMethods = () => {
  const [action, setAction] = useState(1);
  const [sorting, setSorting] = useState(null);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const handleSort = (column, direction) => {
    setSorting({
      column,
      direction,
    });
  };

  return {
    action,
    setAction,
    sorting,
    setSorting,
    metadata,
    setMetadata,
    handleSort,
  };
};

//component preset
