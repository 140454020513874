import { Flex, ListItem, Text } from '@chakra-ui/react';

import CommonListCategories from '../CommonListCategories';
import CommonListItemLine from '../CommonListItemLine';
import removeKeysFromList from '../Helpers/removeKeysFromList';

const CommonListTable = ({ ...props }) => {
  //data
  const data = props?.data;

  //default properties
  const { categories, subcategories } = props;

  const pathSublist = props?.pathSublist;
  const identifier = props?.identifier;

  //instance
  const listIndex = props.listIndex;

  //status
  const tags = props?.tags;
  const statusPreset = props?.statusPreset;

  const gridTemplateLength = props?.gridTemplateLength;

  //buttonDelete
  const hasDeleteButton = props?.hasDeleteButton;
  const deleteButtonAction = props?.deleteButtonAction;

  const children = props?.content;

  //customButtons
  const customButtons = props?.customButtons;

  //customFields
  const customFields = props?.customFields;

  //resume
  const maxLengthResume = props?.maxLengthResume;

  const tooltipCategories = props?.tooltipCategories;

  //buttonDetails
  const hasDetailsButton = props?.hasDetailsButton;
  const detailsRedirectEndpoint = props?.detailsRedirectEndpoint;

  //responsiveness
  const isMobile = props?.isMobile;
  const deviceType = props?.deviceType;
  const viewport = props?.viewport;

  //rowSize
  const cellRowSize = props?.cellRowSize;

  //messages
  const emptyListMessage = props?.emptyListMessage;

  //formatação padrão
  const formattedSubList = props?.formattedSubList;

  //formatação com a remoção das categorias(hiddenCategories) no caminho especifico(pathSublist)
  const hiddenCategories = props?.hiddenCategories;
  const sublistPathItens = props?.sublistPathItens;

  const formattedPathSublist = sublistPathItens && removeKeysFromList(sublistPathItens, hiddenCategories ? hiddenCategories : []);

  return (
    <Flex direction={'column'} gap={'15px'} p={'15px'}>
      <CommonListCategories
        categories={subcategories}
        tooltipCategories={tooltipCategories}
        gridTemplateLength={subcategories ? subcategories.length : '1'}
        cellRowSize={cellRowSize}
        isMobile={isMobile}
        /* sorting={sorting}
      handleSort={handleSort} */
      />

      {
        pathSublist ? (
          formattedPathSublist.length > 0 ? (
            formattedPathSublist?.map((subitem, index) => (
              <ListItem key={index}>
                <CommonListItemLine
                  item={subitem}
                  content={children}
                  hiddenCategories={hiddenCategories}
                  gridTemplateLength={subcategories ? subcategories.length : '1'}
                  isMobile={isMobile}
                  deviceType={deviceType}
                  viewport={viewport}
                  /* identifier={identifier[key]} */
                  categories={categories}
                  statusPreset={statusPreset}
                  hasDetailsButton={hasDetailsButton}
                  detailsRedirectEndpoint={detailsRedirectEndpoint}
                  hasDeleteButton={hasDeleteButton}
                  deleteButtonAction={deleteButtonAction}
                  customButtons={customButtons}
                  customFields={customFields}
                  maxLengthResume={maxLengthResume}
                  tooltipCategories={tooltipCategories}
                  listIndex={listIndex}
                  tags={tags}
                  data={data[listIndex][pathSublist]}
                  cellRowSize={cellRowSize}
                />
              </ListItem>
            ))
          ) : (
            <Text alignSelf={'left'}>{emptyListMessage}</Text>
          )
        ) : formattedSubList && formattedSubList.length > 0 ? (
          formattedSubList?.map((subitem, key) => {
            return (
              <ListItem key={key}>
                <CommonListItemLine
                  item={subitem}
                  content={children}
                  hiddenCategories={hiddenCategories}
                  gridTemplateLength={gridTemplateLength}
                  isMobile={isMobile}
                  deviceType={deviceType}
                  viewport={viewport}
                  /* identifier={identifier[key]} */
                  categories={categories}
                  statusPreset={statusPreset}
                  hasDetailsButton={hasDetailsButton}
                  detailsRedirectEndpoint={detailsRedirectEndpoint}
                  hasDeleteButton={hasDeleteButton}
                  deleteButtonAction={deleteButtonAction}
                  customButtons={customButtons}
                  customFields={customFields}
                  maxLengthResume={maxLengthResume}
                  tooltipCategories={tooltipCategories}
                  listIndex={key}
                  tags={tags}
                  data={data}
                  cellRowSize={cellRowSize}
                />
              </ListItem>
            );
          })
        ) : (
          <Text alignSelf={'left'}>{emptyListMessage}</Text>
        ) /* executa caso seja uma lista normal */
      }
    </Flex>
  );
};

export default CommonListTable;
