import { extendTheme } from '@chakra-ui/react';

import { buttonStyles } from './buttons';
import { colors } from './colors';
import { inputStyles } from './inputs';
import { selectStyles } from './select';
import { typographyStyles } from './typography';

export const themeDefault = extendTheme({
  textStyles: typographyStyles,
  colors: colors,

  breakpoints: {
    sm: '320px',
    md: '760px',
    lg: '1024px',
    lgr: '1200px',
    xl: '1400px',
    xlr: '1500px',
    xx: '1800px',
  },

  components: {
    Button: buttonStyles,
    Input: inputStyles,
    Select: selectStyles,
  },
});
