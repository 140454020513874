import { Image } from '@chakra-ui/react';

function getExtension(filename) {
  const extension = filename.split('.').pop().toLowerCase();
  return extension === filename.toLowerCase() ? '' : extension;
}

export const handleDocuments = (url, name) => {
  const link = (process.env.REACT_APP_API_URL + url).replace('//open', '/open');
  const type = name ? getExtension(name) : '';

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

  return imageExtensions.includes(type) ? (
    <Image w="auto" h="auto" maxWidth="100%" src={link} />
  ) : (
    <iframe width="100%" height="700em" src={link} allowFullScreen={true}></iframe>
  );
};
