import React from 'react';

import { MdHelp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Image, List, ListItem, TabPanel, Table, Tbody, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import iconEfetiva from '../../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../../assets/svgs/origem-esperada.svg';
import permissions from '../../../../../services/permissions';

const InformationTab = ({ entity, setCenter, setZoom }) => {
  let navigate = useNavigate();
  const hasPermission = permissions.comexProcess;
  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  return (
    <TabPanel>
      <Table className="table-striped">
        <Tbody>
          <Tr>
            <Td style={{ color: '#854FFF', fontSize: 14, verticalAlign: 'top' }}>Empresas vinculadas</Td>

            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.clientsFormatted?.length === 0 ? (
                  'Nenhuma empresa vinculada'
                ) : (
                  <List spacing={3}>
                    {entity?.clientsFormatted?.map((item, key) => {
                      return <ListItem key={key}>{item}</ListItem>;
                    })}
                  </List>
                )}
              </Flex>
            </Td>
          </Tr>

          {entity?.processesFiltered?.length > 0 && (
            <Tr>
              <Td style={{ color: '#854FFF', fontSize: 14, verticalAlign: 'top' }}>Processos</Td>

              <Td color="#0F0A1DB3" fontSize={14}>
                <Flex alignItems="flex-start" direction="column">
                  <List spacing={3}>
                    {entity?.processesFiltered?.map((item, key) => {
                      return (
                        <ListItem key={key}>
                          {' '}
                          <Text
                            textColor={hasPermission ? 'linkColor' : 'textColor3'}
                            title={hasPermission ? 'Visualizar detalhe do processo' : 'Número do processo'}
                            cursor={hasPermission ? 'pointer' : 'textColor3'}
                            onClick={(decision) => {
                              if (decision && hasPermission) {
                                navigate(`/comex/process/detail/${item?.identifier}`);
                              }
                            }}>
                            {item?.code}
                          </Text>
                        </ListItem>
                      );
                    })}
                  </List>
                </Flex>
              </Td>
            </Tr>
          )}
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Transportadora
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.shippingCompanyName ? entity.shippingCompanyName : '-'}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Início
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                <Flex title="Data esperada" alignItems="center">
                  <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
                  {entity?.startsAtFormatted ?? '-'}
                </Flex>
                {entity?.startsEtdFormatted != null && (
                  <Flex alignItems="center">
                    <Flex title="Data efetiva" alignItems="center">
                      <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                      {entity?.startsEtdFormatted}
                    </Flex>

                    <Tooltip label={tooltipStart}>
                      <Box style={{ paddingLeft: '2px' }}>
                        <MdHelp color="#422C76" />
                      </Box>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Final
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.endsAtFormatted != null && (
                  <Flex title="Data esperada" alignItems="center">
                    <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
                    {entity?.endsAtFormatted ?? '-'}
                  </Flex>
                )}
                {entity?.endsEtdFormatted != null && (
                  <Flex alignItems="center">
                    <Flex title="Data efetiva" alignItems="center">
                      <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                      {entity?.endsEtdFormatted}
                    </Flex>

                    <Tooltip label={tooltipEnd}>
                      <Box style={{ paddingLeft: '2px' }}>
                        <MdHelp color="#422C76" />
                      </Box>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Valor da mercadoria
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.merchandiseValueFormatted ? entity.merchandiseValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Frete
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.freightValueFormatted ? entity.freightValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Código Externo
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.externalCode ? entity.externalCode : '-'}
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default InformationTab;
