import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import formatDocument from '../../../../utils/fields/masks/formatDocument';

const CreditTable = ({ list, action, setAction, metadata, setMetadata }) => {
  const handleColor = (status) => {
    switch (status) {
      case 'CLEARED':
        return 'green';
      case 'ATTENTION':
        return 'yellow-500';
      case 'REJECTED':
        return 'red';
      default:
        return 'gray-200';
    }
  };

  return (
    <>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>CNPJ</Th>
            <Th>Nome Fantasia</Th>
            <Th>Razão Social</Th>
            <Th>Tipo de consulta</Th>
            <Th>Status</Th>
            <Th>Visualizar</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list?.map((entity, key) => (
            <>
              <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                <Td>{formatDocument(entity.documentNumber) ?? '-'}</Td>
                <Td>{entity.businessName ?? '-'}</Td>
                <Td>{entity.companyName ?? '-'}</Td>
                <Td>{entity?.modalityText ?? '-'}</Td>
                <Td>
                  <Text
                    p="1px 12px"
                    border="solid 1px"
                    borderRadius="7px"
                    alignItems="center"
                    align="center"
                    color={handleColor(entity?.status)}
                    fontSize={{ base: '12px', md: '14px' }}>
                    {entity.statusText ?? '-'}
                  </Text>
                </Td>
                <Td>
                  <Tooltip label="Visualizar">
                    <Box>
                      <Link to={`/vendemmia/credit-analysis/detail/${entity?.identifier}`}>
                        <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                      </Link>
                    </Box>
                  </Tooltip>
                </Td>
              </Tr>
            </>
          ))}

          {list?.length == 0 && (
            <Tr>
              <Td colSpan={9}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default CreditTable;
