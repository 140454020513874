import React from 'react';

import { Flex, TabPanel, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';

import { descriptionStatusTrip } from '../../../../../utils/translate';

const EventsTab = ({ events }) => {
  const generateDescription = (event) => {
    switch (event?.modality?.id) {
      case 5:
        if (event?.section == 'DRAFT') {
          return 'Viagem criada';
        }

        return 'Status da viagem alterado para ' + descriptionStatusTrip(event.section);
      default:
        return event?.observation != null ? event.observation : event.description;
    }
  };

  function getLabelSection(section, modality) {
    switch (section) {
      case 'emergency':
        return 'Emergência';
      case 'stop':
        return 'Parada';
      case 'low':
        return 'Movimento lento';
      case 'no-face':
        return 'Reconhecimento facial';
    }

    switch (modality) {
      case 'LOG':
        return 'Log';
      case 'MOVING':
        return 'Movimento';
      case 'PAUSED':
        return 'Parada';
      case 'STATUS':
        return 'Status';
      case 'WARNING':
        return 'Aviso';
    }

    return null;
  }

  return (
    <TabPanel>
      <Table className="table-striped">
        <Tbody>
          {events?.length > 0 &&
            events?.map(function (event, key) {
              const section = getLabelSection(event?.section, event?.modality?.description);
              return (
                <Tr key={key}>
                  <Td color="#854FFF" fontSize={14}>
                    {event?.occuredAtFormatted}
                  </Td>
                  <Td color="#0F0A1DB3" fontSize={14}>
                    <Flex direction="column" pl="40px">
                      {section != null && (
                        <Text fontSize={14} color="#0F0A1D">
                          {section}
                        </Text>
                      )}
                      <Text fontWeight="bold" fontSize={14} color="#0F0A1D">
                        {generateDescription(event)}
                      </Text>
                      <Text mt="5px" fontSize={14} color="#0F0A1DB3">
                        {event?.observation}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default EventsTab;
