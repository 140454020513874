import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import SortableTableHeader from '../../components/SortableTableHeader';

import ExpeditionList from './ExpeditionList';

const ExpeditionTable = ({ list, handleSort, sorting }) => {
  let profileRole = localStorage.getItem('profile-role');
  return (
    <Table w="full">
      <Thead>
        <Tr>
          <Th>
            <Box></Box>
          </Th>
          {profileRole === 'ROLE_SYSTEM_ADMIN' && (
            <Th>
              <Box></Box>
            </Th>
          )}
          <Th>
            <Text>Status</Text>
          </Th>
          <SortableTableHeader column="e.usuarioConferencia" label="Conferente" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufArmazem" label="Armazém" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nuNota" label="Nota Fiscal" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.serieNf" label="Série" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idPedido" label="Pedido" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeDestinatario" label="Destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufDestinatario" label="UF Destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.recipientCity" label="Cidade destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeDepositante" label="Depositante" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeTransportadora" label="Transportadora" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtFaturamento" label="Faturado em" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtFimColeta" label="Coletado em" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.embLiberado" label="Ag. de coleta" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.carga" label="Carga" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.qtVolumes" label="Volumes" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.valorNf" label="Valor da NF" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.cubagemM3" label="Cub." sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.pesoBrutoKg" label="Peso Bruto" sorting={sorting} handleSort={handleSort} />
          <Th>
            <Text>Observação</Text>
          </Th>
          <Th>
            <Text>Ações</Text>
          </Th>
        </Tr>
      </Thead>

      <Tbody>
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => (
              <ExpeditionList key={entity.id} count={key} entity={entity} />
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={22}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default ExpeditionTable;
