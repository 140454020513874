import React from 'react';

import moment from 'moment';
import { MdHelp, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react'
import { Box, Flex, Grid, GridItem, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Tag from '../../../../components/Generic/Tag';

import ModalDeleteContract from './ModalDeleteContract';

const OperationCard = ({ data }) => {
  const status = (value) => {
    switch (value) {
      case 'AGUARDANDO_ASSINATURA':
        return 'yellow';
      case 'ASSINADO':
        return 'green';
      default:
        return 'gray';
    }
  };

  const textStatus = (value) => {
    switch (value) {
      case 'AGUARDANDO_ASSINATURA':
        return 'Aguardando assinatura';
      case 'ASSINADO':
        return 'Assinado';
      default:
        return 'Rascunho';
    }
  };

  return (
    <Flex w="full" flexDirection={'column'}>
      <Grid 
        border="1px" 
        borderBottom="0" 
        borderColor="#7070703d"
        gridTemplateColumns={'20px 50px 1fr 23px 20px'} 
        gap={'10px'}
        p={'10px'}
      >
        <GridItem>{' '}</GridItem>
        <GridItem fontWeight={'bold'}>Status</GridItem>
        <GridItem fontWeight={'bold'}>CNPJ</GridItem>
        <GridItem>{' '}</GridItem>
        <GridItem>{' '}</GridItem>
      </Grid>

      <Grid 
        border="1px"
        borderColor="#7070703d"
      >
        {data.length > 0 ? (
          <>
            <Accordion w={'full'}>
            {data.map((entity, key) => {
              return (
                <AccordionItem key={key} _odd={{ bg: "#eee" }}>
                  <AccordionButton p={'10px'} _hover={'transparent'}>
                    <Grid 
                        gridTemplateColumns={'20px 50px 1fr 23px 20px'} 
                        alignItems="center"
                        w="100%"
                        gap={'10px'}
                        align="center"
                    >
                        <GridItem>
                          <Tooltip label="Visualizar">
                            <Link to={`/vendemmia/operation-specification/detail/${entity.identifier}`}>
                              <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                            </Link>
                          </Tooltip>  
                        </GridItem>
                        <GridItem>
                          <Tag textAlign="center" borderRadius="10px" p={0} w={'20px'} h={'20px'} fontSize="17px" type={status(entity.status)}></Tag>
                        </GridItem>
                        <GridItem color={'#707070'}>
                          {entity.registration.companyOperationDocumentNumber}
                        </GridItem>
                        <GridItem>
                          <Flex gap="15px">
                            <ModalDeleteContract />
                          </Flex>
                        </GridItem>
                        <GridItem>
                          <AccordionIcon />
                        </GridItem>
                    </Grid>
                  </AccordionButton>
                  <AccordionPanel bg={'white'}>
                    <Text fontSize={'14px'}><strong>Razão Social: </strong>{entity.registration.companyName}</Text>
                    <Text fontSize={'14px'}><strong>Versão: </strong>{entity.version}</Text>
                    <Text fontSize={'14px'}><strong>Data de Cadastro: </strong>{moment(entity.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
                    <Text fontSize={'14px'}>
                        <Flex align="center" gap="3px">
                        {(entity.status === 'RASCUNHO' || entity.status === 'ASSINADO') && <Text><strong>Pendências: </strong>sem pendências</Text>}

                        {entity.status === 'CONFIRMADO' && <Text>Aguardando envio para assinatura</Text>}

                        {entity.status === 'CADASTRADO' && (
                            <>
                            {entity?.responsibleUsers?.status?.qttPending}

                            {entity?.responsibleUsers?.status?.qttPending > 0 ? (
                                <Tooltip label={entity?.responsibleUsers?.status?.whoIsMissingApproval}>
                                <Box>
                                    <MdHelp />
                                </Box>
                                </Tooltip>
                            ) : (
                                <Text>
                                    <strong>Pendências: </strong>sem pendências
                                </Text>
                            )}
                            </>
                        )}

                        {entity.status === 'AGUARDANDO_ASSINATURA' && (
                            <>
                            {entity?.responsibleUsers?.status?.qttPendingSign}

                            {entity?.responsibleUsers?.status?.qttPendingSign > 0 ? (
                                <Tooltip label={entity?.responsibleUsers?.status?.whoIsMissingSign}>
                                <Box>
                                    <MdHelp color="#422C76" />
                                </Box>
                                </Tooltip>
                            ) : (
                                <Text>
                                    <strong>Pendências: </strong>sem pendências
                                </Text>
                            )}
                            </>
                        )}
                        </Flex>
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
            </Accordion>
          </>
        ): (
          <GridItem>
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          </GridItem>
        )}
      </Grid>
    </Flex>
  );
};

export default OperationCard;
