import { useCallback, useEffect, useRef, useState } from 'react';

import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import { Box, Button, Flex, Switch, Text } from '@chakra-ui/react';

import iconProcess from '../../assets/svgs/maps_process.svg';
import permissions from '../../services/permissions';

import { CommonRoutes } from './components/CommonRoutes';
import { MaritimeRoutes } from './components/MaritimeRoutes';
import { usePopulateRoutes } from './Hooks/usePopulateRoutes';
import { MapDashboardStyles } from './MapDashboardStyles';
import MapPopup from './MapPopup';

function MapDashboard({
  defaultMapModality = 'origin-clusters',
  w,
  h,
  processDetails,
  points = [],
  lines = [],
  information = null,
  shouldShowCenterMapButton = true,
  gestureHandling = true,
  fullscreenControl = true,
  mapTypeControl = true,
  maxZoom = 6,
}) {
  const defaultZoom = 2;
  const defaultCenter = {
    lat: 23.73339640721276,
    lng: 2.9533781737686615,
  };
  const maxZoomClustered = 2;

  const [bounds, setBounds] = useState([]);
  const [mapModality, setMapModality] = useState(defaultMapModality);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [isClustered, setIsClustered] = useState(true);
  const [showCenterMapButton, setShowCenterMapButton] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [showInfoWindowCluster, setShowInfoWindowCluster] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [arrowPathOffset, setArrowPathOffset] = useState(5);

  const [showCheckpoints, setShowCheckpoints] = useState(false);

  const { positionsRender } = usePopulateRoutes(information);

  const isSystemAdmin = permissions.isSystemAdmin;
  const hasPositions = positionsRender.length > 0;

  const mapRef = useRef(null);

  const containerStyle = {
    width: '100%',
    height: '500px',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [viewport, setViewport] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    zoom: defaultZoom,
  });

  const markerClusterShowHandler = useCallback((cluster, show) => {
    setShowInfoWindowCluster(show);
    if (show) {
      const markers = cluster.getMarkers();
      let processCodes = markers.map((marker) => marker.label).join(', ');

      let offset = ((500 / 6378) * 180) / Math.PI; // Distance in KM / Earth circumference * Degrees / PI
      setShowInfoWindowCluster({
        position: {
          lat: cluster.center.lat() + offset,
          lng: cluster.center.lng(),
        },
        content: `Quantidade ${markers.length}: Processos: ${processCodes}`,
      });
    } else {
      setShowInfoWindowCluster(null);
    }
  }, []);

  const markerShowHandler = useCallback((index) => {
    setShowInfoWindow(index);
  }, []);

  const updateBounds = useCallback((points) => {
    if (mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      points.forEach((point) => bounds.extend(point));
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    let processListAux = [];
    let positionListAux = [];

    let aux = [];
    for (let i = 0; i < points.length; i++) {
      aux.push(new google.maps.LatLng(points[i].latitude, points[i].longitude));

      // Pra clusters, deve ter somente os pontos com com processos
      if (points[i].qtt === 0 && mapModality === 'origin-clusters') {
        continue;
      }

      positionListAux.push(points[i]);

      for (let j = 0; j < points[i].process.length; j++) {
        let aux = {
          code: points[i].process[j].code,
          identifier: points[i].process[j].identifier,
          position: {
            lat: points[i].latitude,
            lng: points[i].longitude,
          },
        };
        processListAux.push(aux);
      }
    }

    setBounds(aux);

    setProcessList(processListAux);
    setPositionList(positionListAux);
  }, [points, mapModality]);

  useEffect(() => {
    const shouldCluster = currentZoom < maxZoomClustered;

    setIsClustered(shouldCluster);
    if (shouldCluster) {
      setShowInfoWindow(null);
    }
    setShowCenterMapButton(currentZoom !== defaultZoom);
  }, [currentZoom]);

  return (
    <>
      <MapDashboardStyles />

      <Box w={w} h={h} style={{ '--transition': `all .1s linear ` }} position="relative">
        {defaultMapModality === 'origin-to-destination' && (!processDetails?.originPortName || !processDetails?.destinyPortName) && (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bgColor="rgba(0, 0, 0, 0.5)"
            zIndex="100"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Box bg="white" p="20px" borderRadius="8px" shadow="dark-lg" maxW="90%" maxH="90%" overflow="auto">
              <Flex direction="column" gap="10px" align="center">
                <Text fontSize="17px" color="#707070">
                  Aguardando informações de rota.
                </Text>
                <Text fontSize="12px" color="#707070">
                  Mapa desabilitado devido falta de informação de origem e destino.
                </Text>
              </Flex>
            </Box>
          </Box>
        )}

        {isLoaded && (
          <GoogleMap
            onLoad={(map) => {
              mapRef.current = map;
            }}
            mapContainerStyle={containerStyle}
            center={viewport}
            zoom={viewport.zoom}
            options={{
              mapId: '1fd5e647f1d25aae',
              minZoom: 2,
              max: maxZoom,
              zoomControl: true,
              streetViewControl: false,
              keyboardShortcuts: false,
              scaleControl: false,
              fullscreenControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
              },
              mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
              },
              gestureHandling,
              fullscreenControl,
              mapTypeControl,
            }}
            onZoomChanged={() => {
              if (mapRef.current) {
                const zoom = mapRef.current.getZoom();
                const center = mapRef.current.getCenter();
                setCurrentZoom(zoom);

                setViewport({
                  lat: center.lat(),
                  lng: center.lng(),
                  zoom,
                });
              }
            }}>
            {shouldShowCenterMapButton && showCenterMapButton && (
              <Button
                position="absolute"
                top="10px"
                right="260px"
                zIndex="15"
                bg="white"
                p="10px 15px"
                shadow="lg"
                textColor="rgb(26, 32, 44)"
                borderRadius="2px"
                alignItems="center"
                gap="10px"
                fontSize="18px"
                onClick={() => {
                  setViewport({
                    lat: defaultCenter.lat,
                    lng: defaultCenter.lng,
                    zoom: defaultZoom,
                  });
                  mapRef.current.setCenter(defaultCenter);
                  mapRef.current.setZoom(defaultZoom);
                }}>
                <Text color="rgb(26, 32, 44)" fontSize="18px" fontFamily="Roboto, Arial, sans-serif">
                  Centralizar
                </Text>
              </Button>
            )}

            {mapModality === 'origin-clusters' && (
              <>
                {isClustered && (
                  <MarkerClusterer
                    gridSize={15}
                    title="Clique para ver de perto"
                    maxZoom={maxZoomClustered}
                    minimumClusterSize={1}
                    zoomOnClick={false}
                    onClick={(cluster) => {
                      const zoom = mapRef.current.getZoom() * 1.5 || 1;
                      setViewport({
                        lat: cluster.center.lat(),
                        lng: cluster.center.lng(),
                        zoom,
                      });
                      mapRef.current.setZoom(zoom);
                      mapRef.current.setCenter(cluster.center);
                    }}
                    styles={[
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                        textColor: '#fff',
                        height: 53,
                        width: 53,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
                        textColor: '#fff',
                        height: 40,
                        width: 40,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png',
                        textColor: '#fff',
                        height: 66,
                        width: 66,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m4.png',
                        textColor: '#fff',
                        height: 78,
                        width: 78,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m5.png',
                        textColor: '#fff',
                        height: 90,
                        width: 90,
                      },
                    ]}>
                    {(clusterer) => (
                      <>
                        {processList.map((item) => (
                          <Marker key={item.identifier} position={item.position} clusterer={clusterer} opacity={0} label={item.code} />
                        ))}
                      </>
                    )}
                  </MarkerClusterer>
                )}

                {!isClustered &&
                  positionList.map((item, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 15,
                        strokeColor: '#FF1493',
                        fillColor: '#FF1493',
                        fillOpacity: 1,
                        strokeWeight: 1,
                      }}
                      label={{
                        text: `${item.qtt}`,
                        color: '#fff',
                      }}
                      opacity={1}
                      onClick={() => {
                        markerShowHandler(index);

                        setViewport({
                          lat: item.latitude,
                          lng: item.longitude,
                          zoom: mapRef.current.getZoom(),
                        });
                        mapRef.current.setCenter({ lat: item.latitude, lng: item.longitude });
                      }}>
                      {showInfoWindow === index && (
                        <MapPopup
                          key={index}
                          point={{
                            header: {
                              title: item.description,
                              icon: iconProcess,
                              color: '#4B0082',
                            },
                            lat: item.latitude,
                            lng: item.longitude,
                            processes: item.process,
                          }}
                          onCloseClick={() => markerShowHandler(null)}
                        />
                      )}
                    </Marker>
                  ))}
              </>
            )}

            {mapModality === 'origin-to-destination' &&
              information &&
              (processDetails.transportModality === 'Marítimo' ? (
                <MaritimeRoutes information={information} updateBounds={updateBounds} showCheckpoints={showCheckpoints} />
              ) : (
                <CommonRoutes lines={lines} information={information} updateBounds={updateBounds} />
              ))}

            {showInfoWindowCluster && (
              <InfoWindow position={showInfoWindowCluster.position}>
                <>{showInfoWindowCluster.content}</>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </Box>

      {isSystemAdmin && hasPositions && (
        <Flex top="10px" left="10px" bg="white" zIndex="99" p="10px 15px" borderRadius="5px" direction="column" gap="10px">
          <Text color="primary" fontSize="12px" display={'flex'} gap={'10px'}>
            Mostrar todos os dados de posição do navio?
            <Switch
              size="md"
              colorScheme="teal"
              onChange={() => {
                setShowCheckpoints(!showCheckpoints);
              }}
            />
          </Text>
        </Flex>
      )}
    </>
  );
}

export default MapDashboard;
