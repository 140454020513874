import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import MapRegisterFreight from '../../../../../components/Map/MapRegisterFreight';
import useResponsiveCheck from '../../../../../hooks/useResponsiveCheck';

import RegisterFreight from './RegisterFreight';

const ModalFreight = ({ entity, isOpen, onClose, title, subTitle, tab, reloadKanban, reloadList }) => {
  const [geolocationList, setGeolocationList] = useState({});
  const [mapWidth, setMapWidth] = useState(100);
  const [mapHeight, setMapHeight] = useState(100);
  const mapContainerRef = useRef(null);

  const mobile = useResponsiveCheck();
  const isMobile = mobile.isMobile;

  // useLayoutEffect(() => {
  //   if (mapContainerRef && mapContainerRef.current) {
  //     setMapWidth(mapContainerRef?.current?.clientWidth);
  //     setMapHeight(mapContainerRef?.current?.clientHeight);
  //   }
  // }, [mapContainerRef]);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setMapWidth(mapContainerRef?.current?.clientWidth);
  //     setMapHeight(mapContainerRef?.current?.clientHeight);
  //   }

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent
        maxW="none"
        px="2px"
        py="2px"
        my="0"
        overflow={'auto'}
        h={{ sm: `${window.innerHeight - 138}px`, md: `${window.innerHeight - 90}px` }}
        top={0}
        borderRadius={'0'}
        position={'absolute'}>
        <ModalBody bgColor="#FAFBFF" p="0" display="flex" flexDirection={{ sm: 'column', md: 'row' }}>
          <RegisterFreight
            title={title}
            subTitle={subTitle}
            entity={entity}
            onCancelled={onClose}
            tab={tab}
            geolocationList={geolocationList}
            setGeolocationList={setGeolocationList}
            order={{ sm: '2', md: 'initial' }}
            reloadKanban={reloadKanban}
            reloadList={reloadList}
          />

          <Flex ref={mapContainerRef} width="100%" overflow="hidden" order={{ sm: '1', md: 'initial' }}>
            <MapRegisterFreight w={`${mapWidth}%`} h={`${mapHeight}%`} points={geolocationList} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalFreight;
