import { Image } from '@chakra-ui/image';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

import { processCalculated, processRetired } from '../../../../utils/exports';
import { toPercentage } from '../../../../utils/text/format/toPercentage';

import ProcessInfoTag from './ProcessInfoTag';

export const ProcessCarbonTag = ({ process }) => {
  const calcCO2 =
    process?.carbonTrackDeliverStage.calculated +
    process?.carbonTrackTransitStage.calculated +
    process?.carbonTrackTransitStage.calculating;

  const calcCO2Formatted = calcCO2.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const calcRetired = process?.carbonTrackDeliverStage.retired + process?.carbonTrackTransitStage.retired;

  return (
    process.carbonTrackTransitStage.status != 'PREVENTED' && (
      <Box cotag="true">
        <ProcessInfoTag key={process.qtt}>
          <style>
            {`
              [cotag] p{
                border-color: #70B961;
              }
            `}
          </style>
          <Flex gap={'5px'} color={'#70B961'}>
            <Tooltip
              label={
                !process.carbonTrackDeliverStage.retired || process.carbonTrackTransitStage.retired
                  ? `O cálculo estimado de sua pegada de carbono para essa etapa foi de ${calcCO2Formatted} toneladas de CO₂eq com ${toPercentage(
                      calcRetired / calcCO2Formatted
                    )} compensados`
                  : 'O cálculo para esta etapa está indisponível'
              }>
              <Flex alignItems={'center'} gap={'10px'}>
                {process.carbonTrackTransitStage.status === 'RETIRED' ? (
                  <Image src={processRetired} opacity={'1'} w={'14px'} />
                ) : (
                  <Image src={processCalculated} opacity={'1'} w={'8px'} />
                )}
                <Text>
                  {`Pegada CO₂: ${
                    !(process.carbonTrackDeliverStage.calculated || process.carbonTrackTransitStage.calculated)
                      ? calcCO2Formatted + ' tCO₂eq'
                      : 'Calculando...'
                  }`}
                </Text>
              </Flex>
            </Tooltip>
            {/* {!process?.qtt ? <Image src={processCheck} opacity={'1'} /> : <Image src={processUncheck} opacity={'1'} />} */}
          </Flex>
        </ProcessInfoTag>
      </Box>
    )
  );
};
