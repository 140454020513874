import React, { useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import PieChartComponent from '../PieChartComponent';

const CollectionStatusChart = ({ data, setStatusGrafico, isPreview }) => {
  const [entity, setEntity] = useState([]);
  const [total, setTotal] = useState(0);

  const handleClickStatus = (status) => {
    setStatusGrafico(status);
  };

  const formatValue = (value) => {
    if (value === undefined) {
      return '';
    }
    return value.toLocaleString('pt-BR');
  };

  const calculatePercentage = (value) => {
    if (value === undefined || total === 0) {
      return '0%';
    }
    const percentage = (value / total) * 100;
    return percentage.toFixed(2) + '%';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prazo = parseInt(data.onTime, 10) || 0;
        const atrasados = parseInt(data.outOfTime, 10) || 0;

        const chartData = [
          {
            name: 'No prazo',
            qtt: prazo,
            color: '#2ECC71',
            status: 'NoPrazo',
          },
          {
            name: 'Atrasados',
            qtt: atrasados,
            color: '#E74C3C',
            status: 'ForaDoPrazo',
          },
        ];

        const sum = prazo + atrasados;

        setTotal(sum);
        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" flexDirection={{ base: 'column', md: 'row' }}>
      <PieChartComponent
        entity={entity}
        total={total}
        formatValue={formatValue}
        handleClickStatus={handleClickStatus}
        isPreview={isPreview}
      />

      <Flex direction={{ base: 'row', md: 'column' }} gap="20px">
        {entity.map((entry, index) => (
          <Flex key={`item-${index}`} direction="column" borderRadius="17px" bgColor={entry.color} p="15px" gap="5px">
            <Flex gap="10px" align="center">
              <Text textStyle="subtitle" textColor="#FFFFFF" opacity="0.8">
                {entry.name}
              </Text>
            </Flex>

            <Flex align="center" gap="9px">
              <Text textStyle="tableTitle" textColor="#FFFFFF" fontWeight="bold">
                {formatValue(entry.qtt)}
              </Text>

              <Text textStyle="paragraph" textColor="#FFFFFF" opacity="0.8">
                {calculatePercentage(entry.qtt)}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default CollectionStatusChart;
