import React, { useCallback, useEffect, useState } from 'react';

import { MdAirplanemodeActive, MdLocalShipping, MdWarehouse } from 'react-icons/md';

import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';

import escritorio from '../../../assets/images/escritorio.svg';
import logo_vendem from '../../../assets/images/logo_vendem.svg';
import footprint from '../../../assets/svgs/process-calculated.svg';
import neutral from '../../../assets/svgs/process-retired.svg';
import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsCarbonFootprint } from '../../../utils/filters/filterPresets';

import CarbonFootprintChart from './components/CarbonFootprintChart';
import DetailsProcessMetrics from './components/DetailsProcessMetrics';
import LogisticsInfoCard from './components/LogisticsInfoCard';
import TableClientMetrics from './components/TableClientMetrics';
import TableProcessMetrics from './components/TableProcessMetrics';
import TableTransportMetrics from './components/TableTransportMetrics';

const MetricsCarbonPage = () => {
  const hasPermission = permissions.metricsKpiCarbon;
  const profileRole = localStorage.getItem('profile-role');

  const [filterOptions, setFilterOptions] = useState([]);

  const [metrics, setMetrics] = useState({
    internationalTransport: null,
    warehouseAndOffice: null,
    nationalTransport: null,
    clientCarbonFootprint: null,
    dataMonthMetrics: [],
    processMetricsTable: [],
    roadTransportMetricsTable: [],
    clientMetricsTable: [],
  });
  const [loadingStates, setLoadingStates] = useState({
    isLoadingInternationalTransit: true,
    isLoadingWarehouseMetrics: true,
    isLoadingNationalTransportMetrics: true,
    isLoadingDataMonthMetrics: true,
    isLoadingProcessMetrics: true,
    isLoadingTransportMetrics: true,
    isLoadingClientMetrics: true,
  });

  const setLoading = (key, value) => {
    setLoadingStates((prev) => ({ ...prev, [key]: value }));
  };

  const loadInternationalTransit = useCallback(async (filters) => {
    setLoading('isLoadingInternationalTransit', true);
    try {
      const data = await requests.getInternationalTransitMetrics(filters);
      setMetrics((prev) => ({ ...prev, internationalTransport: data }));
    } catch (error) {
      console.error('Erro ao carregar dados de transporte internacional:', error);
    } finally {
      setLoading('isLoadingInternationalTransit', false);
    }
  }, []);

  const loadWarehouseMetrics = useCallback(async (filters) => {
    setLoading('isLoadingWarehouseMetrics', true);
    try {
      const data = await requests.getWarehouseMetrics(filters);
      setMetrics((prev) => ({ ...prev, warehouseAndOffice: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de armazém:', error);
    } finally {
      setLoading('isLoadingWarehouseMetrics', false);
    }
  }, []);

  const loadNationalTransportMetrics = useCallback(async (filters) => {
    setLoading('isLoadingNationalTransportMetrics', true);
    try {
      const data = await requests.getNationalTransportMetrics(filters);
      setMetrics((prev) => ({ ...prev, nationalTransport: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de transporte nacional:', error);
    } finally {
      setLoading('isLoadingNationalTransportMetrics', false);
    }
  }, []);

  const loadDataMonthMetrics = useCallback(async (filters) => {
    setLoading('isLoadingDataMonthMetrics', true);
    try {
      const data = await requests.getMonthMetrics(filters);
      setMetrics((prev) => ({ ...prev, dataMonthMetrics: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas mensais:', error);
    } finally {
      setLoading('isLoadingDataMonthMetrics', false);
    }
  }, []);

  const loadProcessMetrics = useCallback(async (filters) => {
    setLoading('isLoadingProcessMetrics', true);
    try {
      const data = await requests.getProcessMetrics(filters);
      setMetrics((prev) => ({ ...prev, processMetricsTable: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de processo:', error);
    } finally {
      setLoading('isLoadingProcessMetrics', false);
    }
  }, []);

  const loadTransportMetrics = useCallback(async (filters) => {
    setLoading('isLoadingTransportMetrics', true);
    try {
      const data = await requests.getTransportMetrics(filters);
      setMetrics((prev) => ({ ...prev, roadTransportMetricsTable: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de transporte:', error);
    } finally {
      setLoading('isLoadingTransportMetrics', false);
    }
  }, []);

  const loadClientMetrics = useCallback(async (filters) => {
    setLoading('isLoadingClientMetrics', true);
    try {
      const data = await requests.getClientMetrics(filters);
      setMetrics((prev) => ({ ...prev, clientMetricsTable: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de cliente:', error);
    } finally {
      setLoading('isLoadingClientMetrics', false);
    }
  }, []);

  const loadTotalMetricsClient = useCallback(async (filters) => {
    try {
      const data = await requests.getTotalMetricsClient(filters);
      setMetrics((prev) => ({ ...prev, clientCarbonFootprint: data }));
    } catch (error) {
      console.error('Erro ao carregar métricas de cliente:', error);
    }
  }, []);

  const load = useCallback(
    async (filters) => {
      await Promise.all([
        loadInternationalTransit(filters),
        loadWarehouseMetrics(filters),
        loadNationalTransportMetrics(filters),
        loadDataMonthMetrics(filters),
        loadProcessMetrics(filters),
        loadTransportMetrics(filters),
        loadClientMetrics(filters),
        loadTotalMetricsClient(filters),
      ]);
    },
    [
      loadInternationalTransit,
      loadWarehouseMetrics,
      loadNationalTransportMetrics,
      loadDataMonthMetrics,
      loadProcessMetrics,
      loadTransportMetrics,
      loadClientMetrics,
      loadTotalMetricsClient,
    ]
  );

  const getFilterOptions = useCallback(async () => {
    const options = await filterOptionsCarbonFootprint();
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  return (
    <Page
      screen="kpi-carbon"
      title="Pegada de carbono"
      breadcrumbs={[{ link: '#', title: 'Métricas' }]}
      hasPermission={hasPermission}
      filterOptions={filterOptions}
      load={load}>
      <Card m="10px" header={<CardHeader title="Resumo" />}>
        <Grid templateColumns="2fr 1.5fr 2fr" gap="24px" m="20px">
          <ScreenLoader isLoading={loadingStates.isLoadingInternationalTransit}>
            <LogisticsMetricCard
              title="Transporte internacional"
              icon={<MdAirplanemodeActive color="#AA88F9" />}
              generated={metrics.internationalTransport?.calculated.toLocaleString('pt-BR', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
              compensated={metrics.internationalTransport?.retired.toLocaleString('pt-BR', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
            />
          </ScreenLoader>

          <ScreenLoader isLoading={loadingStates.isLoadingWarehouseMetrics}>
            <LogisticsInfoCard
              title="Vendemmia: Armazém e escritório"
              icon={
                <Flex gap="10px">
                  <Image src={logo_vendem} h="15px" />
                  <MdWarehouse color="#AA88F9" size="16px" />
                  <Image src={escritorio} h="15px" />
                </Flex>
              }
              gap="30px"
              bgColor="#6C48C214">
              <WarehouseMetricDetails
                generatedAndCompensated={metrics.warehouseAndOffice?.retired.toLocaleString('pt-BR', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
              />
            </LogisticsInfoCard>
          </ScreenLoader>

          <ScreenLoader isLoading={loadingStates.isLoadingNationalTransportMetrics}>
            <LogisticsMetricCard
              title="Transporte nacional"
              icon={<MdLocalShipping color="#AA88F9" />}
              generated={metrics.nationalTransport?.calculated.toLocaleString('pt-BR', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
              compensated={metrics.nationalTransport?.retired.toLocaleString('pt-BR', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
            />
          </ScreenLoader>
        </Grid>
      </Card>

      {metrics.dataMonthMetrics.length !== 0 && (
        <ScreenLoader isLoading={loadingStates.isLoadingDataMonthMetrics}>
          <Card m="10px" colSpan={3} header={<CardHeader title="Linha do tempo" />}>
            <CarbonFootprintChart data={metrics.dataMonthMetrics} />
          </Card>
        </ScreenLoader>
      )}

      <ScreenLoader isLoading={loadingStates.isLoadingProcessMetrics}>
        <MetricDetailsCard
          title="Métricas por processos"
          profileRole={profileRole}
          entity={metrics.processMetricsTable}
          totalProcess={metrics.processMetricsTable.length.toLocaleString('pt-BR')}
          totalCarbon={metrics.internationalTransport?.calculated.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          totalNeutral={metrics.internationalTransport?.retired.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          Component={TableProcessMetrics}
        />
      </ScreenLoader>

      <ScreenLoader isLoading={loadingStates.isLoadingTransportMetrics}>
        <MetricDetailsCard
          title="Métricas por transporte rodoviário"
          profileRole={profileRole}
          entity={metrics.roadTransportMetricsTable}
          totalProcess={metrics.roadTransportMetricsTable.length.toLocaleString('pt-BR')}
          totalCarbon={metrics.nationalTransport?.calculated.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          totalNeutral={metrics.nationalTransport?.retired.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          Component={TableTransportMetrics}
        />
      </ScreenLoader>

      <ScreenLoader isLoading={loadingStates.isLoadingClientMetrics}>
        <MetricDetailsCard
          title="Métricas por clientes"
          profileRole={profileRole}
          entity={metrics.clientMetricsTable}
          totalProcess={metrics.clientMetricsTable.length.toLocaleString('pt-BR')}
          totalCarbon={metrics.clientCarbonFootprint?.calculated.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          totalNeutral={metrics.clientCarbonFootprint?.retired.toLocaleString('pt-BR', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          Component={TableClientMetrics}
        />
      </ScreenLoader>
    </Page>
  );
};

const CardHeader = ({ title }) => (
  <Flex gap="15px">
    <Box w="4px" bgColor="linkColor" />

    <Text textStyle="cardTitle">{title}</Text>
  </Flex>
);

const LogisticsMetricCard = ({ title, icon, generated, compensated }) => (
  <LogisticsInfoCard title={title} icon={icon} gap="20px" w="full" h="full">
    <Flex w="full" justify="space-between" bgColor="#F8F8F8" p="18px 39px" align="center">
      <Text fontSize="21px" fontWeight="medium" textColor="#2ECC71">
        Toneladas geradas
      </Text>

      <Flex align="flex-start" textColor="#2ECC71">
        <Text fontSize="55px" fontWeight="bold">
          {generated}
        </Text>

        <Image src={footprint} w="20px" h="20px" />
      </Flex>
    </Flex>

    <Flex w="full" justify="space-between" bgColor="#F8F8F8" p="18px 39px" align="center">
      <Text fontSize="21px" fontWeight="medium" textColor="#6C48C2">
        Toneladas compensadas
      </Text>

      <Flex align="flex-start" justify="flex-end" textColor="#2ECC71">
        <Text fontSize="55px" fontWeight="bold" textColor="#6C48C2">
          {compensated}
        </Text>

        <Image src={neutral} w="15px" h="20px" />
      </Flex>
    </Flex>
  </LogisticsInfoCard>
);

const WarehouseMetricDetails = ({ generatedAndCompensated }) => (
  <Flex h="full" w="full" bgColor="#F8F8F8" p="18px 39px" direction="column" justify="space-between" textColor="#6C48C2">
    <Text fontSize="21px" fontWeight="medium">
      Toneladas geradas e compensadas
    </Text>

    <Text fontSize="55px" fontWeight="bold">
      {generatedAndCompensated}
    </Text>

    <Text fontSize="12px">Os armazéns e escritório da Vendemmia estão com o carbono 100% neutralizado (Escopo 1 e 2).</Text>
  </Flex>
);

const MetricDetailsCard = ({ title, entity, profileRole, totalProcess, totalCarbon, totalNeutral, Component }) => (
  <Card m="10px" header={<CardHeader title={title} />}>
    <Flex h="full" justify="space-between" m="25px" gap="20px">
      <Component entity={entity} profileRole={profileRole} />

      <DetailsProcessMetrics
        title={`Total de ${title.split(' ')[2].toLowerCase()}`}
        totalProcess={totalProcess}
        totalCarbon={totalCarbon}
        totalNeutral={totalNeutral}
      />
    </Flex>
  </Card>
);

export default MetricsCarbonPage;
