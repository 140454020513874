import React from 'react';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import SortableTableHeader from '../../components/SortableTableHeader';

import StockList from './StockList';

const StockTable = ({ list, handleSort, sorting }) => {
  return (
    <Table className="table-default" w="full">
      <Thead h="3rem" style={{ borderBottom: '1px solid #DFDFDF' }}>
        <Tr>
          <Th>
            <Box></Box>
          </Th>
          <SortableTableHeader column="e.codProduto" label="Cód. Produto" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.setor" label="Setor" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader
            tooltip="Identifica o estado do produto"
            column="e.situacao"
            label="Estado"
            sorting={sorting}
            handleSort={handleSort}
          />

          <SortableTableHeader
            markgroup={true}
            column="e.qtdeEstoqueDisponivel"
            label="Disponível"
            sorting={sorting}
            handleSort={handleSort}
          />
          <SortableTableHeader
            markName={'Estoque'}
            markgroup={true}
            column="e.qtdeSeparacao"
            label="Separação"
            sorting={sorting}
            handleSort={handleSort}
          />
          <SortableTableHeader markgroup={true} column="e.qtdeEstoque" label="Total" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader markgroup={true} column="e.qtdeAvariada" label="Avariada" sorting={sorting} handleSort={handleSort} />

          <SortableTableHeader column="e.unityValue" label="Valor" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idLoteFabricacao" label="Lote indústria" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufArmazem" label="Armazém" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtVencimento" label="Vencimento" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.inAbc" label="Curva ABC" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="c.name" label="Depositante" sorting={sorting} handleSort={handleSort} />
        </Tr>
      </Thead>
      <br />
      <Tbody border="1px">
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => (
              <StockList key={entity.id} count={key} entity={entity} />
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={14}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default StockTable;
