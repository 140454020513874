import { MdOpenInNew } from 'react-icons/md';

import { Box, Button, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';

import { default as ShortenText } from '../../utils/text/format/shortenText';

import CustomFields from './components/CustomFields';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import renderArrayValues from './Helpers/renderArrayValues';
import renderObjectArrayValues from './Helpers/renderObjectArrayValues';

const CommonListItemLine = ({ children, ...props }) => {
  //default properties
  const categories = props?.categories;

  //data
  const data = props?.data;

  //instance
  const item = props.item;
  /* const subitem = props?.subitem; */
  const content = props.content;
  const listIndex = props.listIndex;

  //conditionally
  const hiddenCategories = props?.hiddenCategories;
  //status
  const tags = props?.tags;
  const statusPreset = props?.statusPreset;

  const identifier = props?.identifier;

  //buttonDetails
  const hasDetailsButton = props?.hasDetailsButton;
  const detailsRedirectEndpoint = props?.detailsRedirectEndpoint;
  //buttonDelete
  const hasDeleteButton = props?.hasDeleteButton;
  const deleteButtonAction = props?.deleteButtonAction;
  //customButtons
  const customButtons = props?.customButtons;

  //customFields
  const customFields = props?.customFields;

  //resume
  const maxLengthResume = props?.maxLengthResume;

  /* styles */
  const gridTemplateLength = hasDetailsButton ? props.gridTemplateLength : props.gridTemplateLength;

  //rowSize
  const cellRowSize = props?.cellRowSize;

  //responsiveness
  const isMobile = props?.isMobile;
  const deviceType = props?.deviceType;
  const viewport = props?.viewport;

  // Função para substituir ${identifier} pelo valor real
  const replaceIdentifier = (endpoint, value) => {
    return endpoint.replace(/\${identifier}/g, value);
  };

  const redirectToDetailsOnClick = () => {
    window.location.href = replaceIdentifier(detailsRedirectEndpoint, identifier);
  };

  // Verifica se o valor é um array de objetos ou um array normal
  const isArrayOrArrayOfObjects = (value) => {
    return typeof value[0] === 'object' && value[0] !== null ? renderObjectArrayValues(value) : renderArrayValues(value);
  };

  return isMobile ? (
    <Grid
      gridTemplateColumns={'1fr'}
      alignItems={'center'}
      border={'1px solid #70707036'}
      borderRadius={'10px'}
      columnGap={'12px'}
      p={'20px 20px 10px'}
      lineHeight={'40px'}
      boxShadow="sm"
      data-screen-width>
      {/* 
        exibir os dados dinamicamente sem se referir explicitamente a item.algumacoisa 
        e excluir as categorias desejadas 
      */}
      <Flex flexDirection={'column'}>
        <Flex gap={'15px'} flexDir={'column'} align={'flex-start'}>
          {Object.keys(item).map((property, index) => {
            const currentCategory = categories[index];
            const currentProperty = item[property];

            if (!currentCategory) {
              return null;
            }

            return (
              <Text
                key={property}
                lineHeight={'20px'}
                display={'grid'}
                gridTemplateColumns={'auto auto'}
                alignItems={'baseline'}
                gap={'10px'}
                fontSize={'14px'}>
                <Text as={'strong'} fontSize={'14px'}>
                  {typeof currentCategory === 'string' ? `${currentCategory}:` : `${currentCategory.title}:`}
                </Text>
                <ShortenText key={property} value={currentProperty} maxLength={maxLengthResume} />
              </Text>
            );
          })}
        </Flex>
        {customFields &&
          customFields.length > 0 &&
          customFields.map((field, key) => {
            const initialValue = data[listIndex][field.name];

            return (
              <>
                <Text>
                  <CustomFields key={key} field={field} selected={initialValue} identifier={identifier} />
                </Text>
              </>
            );
          })}
        {(hasDetailsButton || hasDeleteButton || customButtons) && (
          <Flex pt={'10px'} mt={'15px'} borderTop={'1px solid #70707036'} gap={'10px'}>
            {customButtons &&
              customButtons.map((button, key) => {
                return (
                  <Button
                    variant={'commonTable'}
                    onClick={(e) => {
                      e.stopPropagation();
                      button.action(identifier);
                    }}
                    key={key}>
                    {button.icon ? button.icon : button.label}
                  </Button>
                );
              })}
            {hasDetailsButton && (
              <Tooltip label={'Ver detalhes'}>
                <Box>
                  <Button
                    variant={'commonTable'}
                    onClick={(e) => {
                      e.stopPropagation(); // isso impede a propagação do evento de clique
                      redirectToDetailsOnClick();
                    }}>
                    <MdOpenInNew size={20} />
                  </Button>
                </Box>
              </Tooltip>
            )}
            {hasDeleteButton && <DeleteConfirmationModal deleteButtonAction={deleteButtonAction} identifier={identifier} />}
          </Flex>
        )}
      </Flex>
    </Grid>
  ) : (
    <Grid
      gridTemplateColumns={`repeat(${gridTemplateLength}, ${cellRowSize})`}
      alignItems={'center'}
      border={'1px solid #70707036'}
      borderRadius={'10px'}
      columnGap={'15px'}
      p={'8px 25px'}
      lineHeight={'40px'}
      boxShadow="sm"
      data-screen-width>
      {/* 
        exibir os dados dinamicamente sem se referir explicitamente a item.algumacoisa 
        e excluir as categorias desejadas 
      */}

      {Object.entries(item).map(([property, value], index) => {
        if (!hiddenCategories || !hiddenCategories.includes(property)) {
          return (
            <Text key={index} fontSize={'16px'} lineHeight={'20px'} data-email-break={property === 'email' ? true : false}>
              {
                //verifica se propriedade é uma tag
                tags && tags.includes(property) ? (
                  statusPreset ? (
                    statusPreset.some((item) => item.value === value) ? (
                      <Text key={index} bg={statusPreset.find((item) => item.value === value).color} data-status-tag>
                        {viewport < 1500 ? (
                          <Tooltip label={statusPreset.find((item) => item.value === value).label}>
                            <Box>{statusPreset.find((item) => item.value === value).label}</Box>
                          </Tooltip>
                        ) : (
                          statusPreset.find((item) => item.value === value).label
                        )}
                      </Text>
                    ) : Array.isArray(value) ? (
                      isArrayOrArrayOfObjects(value)
                    ) : (
                      <Text key={index} fontSize={{ base: '0.75rem', md: '1.1rem' }}>
                        {value}
                      </Text>
                    )
                  ) : (
                    <ShortenText key={index} value={value} maxLength={maxLengthResume} />
                  )
                ) : Array.isArray(value) ? (
                  isArrayOrArrayOfObjects(value) // Verifica se o valor é um array de objetos ou um array normal
                ) : (
                  <ShortenText key={index} value={value} maxLength={maxLengthResume} />
                )
              }
            </Text>
          );
        } else {
          return null; // Renderiza null para as categorias ocultas
        }
      })}

      {customFields &&
        customFields.length > 0 &&
        customFields.map((field, key) => {
          return (
            <>
              <Text>
                <CustomFields key={key} field={field} selected={data[listIndex][field.name]} identifier={identifier} />
              </Text>
            </>
          );
        })}

      <Text>
        {(hasDetailsButton || hasDeleteButton || customButtons) && (
          <>
            {customButtons &&
              customButtons.map((button, key) => {
                return (
                  <Button
                    variant={'commonTable'}
                    onClick={(e) => {
                      e.stopPropagation();
                      button.action(identifier);
                    }}
                    key={key}>
                    {button.icon ? button.icon : button.label}
                  </Button>
                );
              })}
            {hasDetailsButton && (
              <Button
                variant={'commonTable'}
                onClick={(e) => {
                  e.stopPropagation(); // isso impede a propagação do evento de clique
                  redirectToDetailsOnClick();
                }}>
                <MdOpenInNew size={20} />
              </Button>
            )}
            {hasDeleteButton && <DeleteConfirmationModal deleteButtonAction={deleteButtonAction} identifier={identifier} />}
          </>
        )}
        {content}
      </Text>
    </Grid>
  );
};

export default CommonListItemLine;
