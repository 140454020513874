import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const ColorfullCard = ({ title, content, color = '#422C76' }) => {
  return (
    <Flex
      bg={color}
      borderRadius="5px"
      p="5px"
      color="white"
      direction="column"
      alignItems="center"
      grow="1"
      flex="1"
      shadow="0px 3px 6px #00000029"
      justifyContent="center">
      <Text className="up-anim" fontSize="0.7rem">
        {title}
      </Text>
      <Text className="down-anim" fontSize="1.1rem">
        {content}
      </Text>
    </Flex>
  );
};

export default ColorfullCard;
