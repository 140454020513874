import React from 'react';

import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

import Toggle from '../../../../components/Generic/Toggle';

const ModalChangeToggle = ({ text, status = false, callback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = (userDecision) => {
    callback(userDecision);
    onClose();
  };
  return (
    <>
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          onOpen();
        }}>
        <Toggle status={status} />
        <Text fontWeight="bold" ml="4px" fontSize="14px">
          {status ? 'Ativado' : 'Desativado'}
        </Text>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text
                textStyle="secondaryTitle"
                fontWeight="medium"
                textColor="#0F0A1D"
                textAlign="center"
                mb="20px"
                className="up-anim">
                Você tem certeza que deseja <strong>{!status ? 'a' : 'desa'}tivar</strong>
                {text}?
              </Text>
              <Flex>
                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(false)}>
                  Cancelar
                </Button>
                <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" onClick={() => handleClick(true)}>
                  Continuar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalChangeToggle;
