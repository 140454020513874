import React, { useEffect, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdHistory, MdOutlineSave } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Drawer,
  Flex,
  HStack,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import FloatActionButton from '../../../../components/Generic/FloatActionButton';
import Page from '../../../../components/Page';
import requests from '../../../../services/requests';
import closeFloatActionButton from '../../../../utils/actions/modal/closeFloatActionButton';
import formatNCM from '../../../../utils/fields/masks/formatNCM';
import { initialValues } from '../Helpers/initialValues';
import { validationSchema } from '../Helpers/validationSchema';

import NcmDetailPageStyles from './NcmDetailPageStyles';
import NcmHistoryViewer from './NcmHistoryViewer';

const NcmDetailPage = () => {
  let navigate = useNavigate();

  const { identifier } = useParams();
  const [isMobile, setIsMobile] = useState();
  const [deviceType, setDeviceType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);
  const [entity, setEntity] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [ncmLogs, setNcmLogs] = useState();

  const [listTags, setListTags] = useState([]);
  const [tagInput, setTagInput] = useState({});

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const fetchNCMQueryData = async () => {
    setIsLoading(true);

    try {
      const res = await requests.fetchNCMQuery(identifier);
      setEntity(res);
      setListTags({ exTariffs: res.exTariffs, exTipis: res.exTipis });
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
      setIsLoading(false);
    }
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  const checkDisabled = (name) => {
    if (identifier === undefined && name !== 'status') {
      return false;
    } else if (entity?.status === 'ACTIVE') {
      return false;
    }

    return true;
  };

  const showButtons = () => {
    if (identifier === undefined) {
      return true;
    } else if (entity?.status === 'ACTIVE') {
      return true;
    }

    return false;
  };

  const viewNCMLogsData = async (identifier) => {
    try {
      const res = await requests.viewNCMLogs(identifier);
      setNcmLogs(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  useEffect(() => {
    if (identifier !== undefined) {
      fetchNCMQueryData(identifier);
    }
  }, []);

  const load = (filters) => {
    viewNCMLogsData(identifier);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  const statusOptions = [
    { value: 'ACTIVE', label: 'Ativo' },
    { value: 'DISCONTINUED', label: 'Descontinuado' },
  ];

  const handleTagAdd = (modality) => {
    if (tagInput[modality].trim() !== '') {
      setListTags((prevState) => {
        const currentTags = Array.isArray(prevState[modality]) ? prevState[modality] : [];
        return {
          ...prevState,
          [modality]: [...currentTags, tagInput[modality].trim()],
        };
      });

      setTagInput({ [modality]: '' });
    }
  };

  const handleTagRemove = (modality, index) => {
    setListTags((prevState) => {
      const newTags = Array.isArray(prevState[modality]) ? [...prevState[modality]] : [];
      newTags.splice(index, 1);

      return {
        ...prevState,
        [modality]: newTags,
      };
    });
  };

  return (
    <Page
      title={entity.identifier ? `NCM ${entity?.code}` : 'Nova consulta'}
      breadcrumbs={[
        { link: '#', title: 'Gestão' },
        { link: '/vendemmia/ncm-management', title: 'Gestão de NCM' },
      ]}
      setIsMobile={setIsMobile}
      setDeviceType={setDeviceType}
      load={load}
      isContentLoading={isLoading}
      hideAllFilters={true}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}>
      <NcmDetailPageStyles />
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues(entity)}
          validationSchema={validationSchema()}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            const aux = {
              code: values?.code,
              description: values?.description,
              startsAtFormatted: values?.startsAtFormatted,
              exTariffs: listTags?.exTariffs?.length > 0 ? listTags?.exTariffs : [],
              exTipis: listTags?.exTipis?.length > 0 ? listTags?.exTipis : [],
            };

            if (entity?.identifier) {
              requests
                .editNCMQuery(entity.identifier, aux)
                .then(() => {
                  toast.success(`Consulta NCM editada com sucesso`);
                  setSubmitting(false);

                  setTimeout(() => {
                    navigate('/vendemmia/ncm-management');
                  }, 1000);
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(`Problema ao editar consulta NCM`);
                });
            } else {
              requests
                .createNCMQuery(aux)
                .then(() => {
                  toast.success(`Consulta NCM cadastrada com sucesso`);
                  setSubmitting(false);

                  setTimeout(() => {
                    navigate('/vendemmia/ncm-management');
                  }, 1000);
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(`Problema ao cadastrar consulta NCM`);
                });
            }
          }}>
          {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, errors }) => (
            <form id="form" onSubmit={handleSubmit}>
              <Card
                m="10px"
                header={
                  <Flex gap="2px" direction="column">
                    <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                      Detalhes de NCM {entity?.code}
                    </Text>
                  </Flex>
                }>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados da NCM
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="code" text="NCM" />
                        <InputDefault
                          width={'full'}
                          setFieldValue={setFieldValue}
                          value={values.code}
                          maxLength={10}
                          onChange={(event) => {
                            const formattedNCM = formatNCM(event.target.value);
                            handleChange({ target: { name: 'code', value: formattedNCM } });
                          }}
                          disabled={checkDisabled('code')}
                          name="code"
                          placeholder="Digite o código NCM"
                        />
                        <ErrorMessage component="p" className="error-message-error" name="code" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex data-form-card>
                    <Flex gap="1.875rem" direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column">
                        <LabelDefault name="description" text="Descrição" />

                        <Textarea
                          name="description"
                          disabled={checkDisabled('description')}
                          width={{ base: 'full', md: '66.25rem' }}
                          height="11.25rem"
                          resize="none"
                          bg="#F2F2F2"
                          mt="1"
                          value={values.description}
                          onChange={(event) => setFieldValue('description', event.target.value)}
                        />

                        <ErrorMessage component="p" className="error-message-error" name="description" />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados Ex Tárifario
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="exTariffs" text="Códigos" />
                        <HStack spacing={4}>
                          <Flex direction="column">
                            <Input
                              type="number"
                              placeholder="Digite o código e pressione Enter"
                              value={tagInput.exTariffs}
                              disabled={checkDisabled('tags')}
                              onChange={(e) => setTagInput({ exTariffs: e.target.value })}
                              width={{ base: '10rem', md: '18rem' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleTagAdd('exTariffs');
                                }
                              }}
                            />
                            <Flex gap="10px" mt="4">
                              {listTags?.exTariffs?.map((tag, index) => (
                                <Tag size="md" key={index} variant="subtle" colorScheme="cyan">
                                  <TagLabel>{tag}</TagLabel>
                                  {showButtons() && <TagCloseButton onClick={() => handleTagRemove('exTariffs', index)} />}
                                </Tag>
                              ))}
                            </Flex>

                            {errors?.tags && <Text className="error-message-error">{errors.tags}</Text>}
                          </Flex>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex data-form-wrapper>
                  <Flex data-form-card mt="0">
                    <Text mb="4" color="secondary">
                      Dados Ex Tipi
                    </Text>
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <Flex direction="column" flexWrap={'wrap'} width={{ sm: 'full', md: 'initial' }}>
                        <LabelDefault name="exTipis" text="Códigos" />
                        <HStack spacing={4}>
                          <Flex direction="column">
                            <Input
                              type="number"
                              placeholder="Digite o código e pressione Enter"
                              value={tagInput.exTipis}
                              disabled={checkDisabled('tags')}
                              onChange={(e) => setTagInput({ exTipis: e.target.value })}
                              width={{ base: '10rem', md: '18rem' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleTagAdd('exTipis');
                                }
                              }}
                            />
                            <Flex gap="10px" mt="4">
                              {listTags?.exTipis?.map((tag, index) => (
                                <Tag size="md" key={index} variant="subtle" colorScheme="cyan">
                                  <TagLabel>{tag}</TagLabel>
                                  {showButtons() && <TagCloseButton onClick={() => handleTagRemove('exTipis', index)} />}
                                </Tag>
                              ))}
                            </Flex>

                            {errors?.tags && <Text className="error-message-error">{errors.tags}</Text>}
                          </Flex>
                        </HStack>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>

              {showButtons() && (
                <FloatActionButton
                  saveButton={
                    <Button
                      rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                      type="submit"
                      h="46px"
                      px="24px"
                      py="14px"
                      bgColor="primary"
                      borderRadius="27px"
                      color="#FFFFFF"
                      loadingText="Salvando"
                      _hover={{ bgColor: '#8067DC' }}
                      isLoading={isSubmitting}>
                      <Text>Salvar</Text>
                    </Button>
                  }
                  options={[
                    <>
                      <Button
                        variant={'floatingGreenButton'}
                        onClick={() => {
                          onOpen();
                          closeFloatActionButton();
                        }}>
                        <Text mr="10px" color="white">
                          Ver Historico
                        </Text>
                        <MdHistory size={20} color="#ffffff" />
                      </Button>
                    </>,
                  ]}
                  isOpen={isOpenFloatButton}
                  setIsOpen={setIsOpenFloatButton}
                />
              )}
            </form>
          )}
        </Formik>
      </Box>
      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
        <NcmHistoryViewer
          identifier={identifier}
          ncmLogs={ncmLogs}
          action={action}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
        />
      </Drawer>
    </Page>
  );
};

export default NcmDetailPage;
