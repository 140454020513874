import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Text, Toast } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsOperationSpecification } from '../../../utils/filters/filterPresets';

import OperationCard from './components/OperationCard';
import OperationTable from './components/OperationTable';

const OperationSpecification = () => {
  const hasPermissionView = permissions.vendemmiaOperationSpecificationView;
  const hasManagementPermission = permissions.vendemmiaOperationSpecificationManagement;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  const handleNewContract = () => {
    localStorage.removeItem('edit');
  };

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listOperation(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsOperationSpecification());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .importOperationNcmsAction(docs, config)
        .then(() => {
          resolve();
          Toast.success('Arquivo importado com sucesso.');
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <Page
      screen="operation-specificaiton"
      title="Especificação operacional"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Pesquise pelo conteúdo..."
      setIsMobile={setIsMobile}
      hasPermission={hasPermissionView || hasManagementPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      showFilters={true}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={
        hasManagementPermission && [
          {
            title: 'Cadastrar especificação operacional',
            text: 'Cadastro de especificação operacional',
            action: (
              <Link onClick={handleNewContract} to="/vendemmia/operation-specification/new" key={2}>
                <Button bgColor="green" color="white" pt="23px" pb="23px" borderRadius="50px" _hover={{ bgColor: '#70D499' }}>
                  <MdAdd color="#FFFFFF" size={20} />
                  <Text textStyle="paragraph" ml="10px">
                    Nova especificação operacional
                  </Text>
                </Button>
              </Link>
            ),
            modality: 'custom',
          },
        ]
      }>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Especificação operacional
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} registros
            </Text>
          </Flex>
        }>
        <ScreenLoader isLoading={isLoading}>
          <Box p="15px">
            {!isMobile ? (
              <OperationTable data={list} loadList={triggerRefresh} hasManagementPermission={hasManagementPermission} />
            ) : (
              <OperationCard data={list} />
            )}
          </Box>
        </ScreenLoader>

        {metadata.total_pages > 1 && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}
      </Card>
    </Page>
  );
};

export default OperationSpecification;
