import React, { useEffect, useState } from 'react';

import { MdLens } from 'react-icons/md';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const PercentagePeriodChart = ({ data }) => {
  const [entity, setEntity] = useState([]);

  const valueFormatter = (value) => {
    return value.toLocaleString('pt-BR');
  };

  const formatFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <Flex direction="row" align="center" key={key} gap="5px">
              <Text fontSize="14px" textColor={entity.color}>
                {formatFirstLetter(entity.name)}:
              </Text>

              <Text fontSize="14px" textColor={entity.color}>
                {entity.value.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}%
                {entity.payload[`${entity.name.toLowerCase()}Qtt`] ? ` (${entity.payload[`${entity.name.toLowerCase()}Qtt`]})` : ''}
              </Text>
            </Flex>
          ))}
        </Flex>
      );
    }
    return null;
  };

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;

    return (
      <svg x={cx - 4} y={cy - 5} width={20} height={20}>
        <MdLens color={stroke} size={8} />;
      </svg>
    );
  };

  useEffect(() => {
    const processData = () => {
      const chartData = Object.entries(data).map(([period, values]) => {
        return {
          period,

          echo: values['Família Echo'].percent * 100,
          echoQtt: values['Família Echo'].qtt,

          fire: values['Família Fire'].percent * 100,
          fireQtt: values['Família Fire'].qtt,

          kindle: values['Família Kindle'].percent * 100,
          kindleQtt: values['Família Kindle'].qtt,

          geral: values['total'].percent * 100,
          geralQtt: values['total'].qtt,
        };
      });

      setEntity(chartData);
    };

    processData();
  }, [data]);

  return (
    <Box m="10px">
      <ResponsiveContainer width="100%" height={260}>
        <ComposedChart data={entity}>
          <XAxis dataKey="period" padding={{ left: 15 }} />
          <YAxis tickFormatter={valueFormatter} ticks={[0, 20, 40, 60, 80, 100]} tickLine={false} unit="%" />

          <CartesianGrid vertical={false} />

          <Tooltip content={<CustomTooltip />} />

          <Legend verticalAlign="top" formatter={formatFirstLetter} />

          <Line
            type="monotone"
            dataKey="echo"
            name="echo"
            stroke="#422C76"
            strokeWidth={1}
            strokeDasharray="5 5"
            dot={<CustomizedDot />}
            activeDot={false}
          />
          <Line
            type="monotone"
            dataKey="fire"
            name="fire"
            stroke="#C767DC"
            strokeWidth={1}
            strokeDasharray="5 5"
            dot={<CustomizedDot />}
            activeDot={false}
          />
          <Line
            type="monotone"
            dataKey="kindle"
            name="kindle"
            stroke="#6576FF"
            strokeWidth={1}
            strokeDasharray="5 5"
            dot={<CustomizedDot />}
            activeDot={false}
          />
          <Line
            type="monotone"
            dataKey="geral"
            name="geral"
            stroke="#2ECC71"
            strokeWidth={1}
            strokeDasharray="5 5"
            dot={<CustomizedDot />}
            activeDot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PercentagePeriodChart;
