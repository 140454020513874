import React, { useEffect, useRef, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsManagementUser } from '../../../utils/filters/filterPresets';
import { getFilter } from '../../../utils/storageFilter';

import ModalManagementUser from './components/ModalManagementUser';
import roleOptions from './Helpers/roleOptions';
import statusOptions from './Helpers/statusOptions';

const UserManagementPage = () => {
  const request = useRef(0);

  const [action, setAction] = useState(1);

  const hasPermission = permissions.vendemmiaManagementUserManagement;
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);

  const [identifier, setIdentifier] = useState();

  const [sorting, setSorting] = useState(null);
  const handleSort = (column, direction) => {
    setSorting({
      column,
      direction,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [fields, setFields] = useState({
    role: {
      slug: '',
      value: '',
      label: '',
    },
    status: {
      label: '',
      value: '',
    },
  });

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsManagementUser());
  };

  const handleCreateModalClose = () => {
    onCreateModalClose();
    triggerRefresh();
  };

  const handleEditModalClose = () => {
    onEditModalClose();
    load();
  };

  const handleOpenEditModal = (identifier) => {
    onEditModalOpen();
    setIdentifier(identifier);
  };

  const handleOpenCreateModal = () => {
    onCreateModalOpen();
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests.listUsers(filters, page).then((data) => {
      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const exportListAction = (filters, key) => {
    //preset
    const endpoint = `/adm/user/export-csv`;
    const fileName = `users`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  //actions
  const handleClickChangeRole = async (identifier, fields) => {
    try {
      const res = await requests.editUserRole(identifier, { role: fields.value });

      toast.success(`Perfil do usuário ${res.name} alterado para ${roleOptions.find((e) => e.value === res?.role)?.label}`);
    } catch (error) {
      console.error('Problemas ao alterar perfil do usuário, tente novamente ou entre em contato com o Administrador', error);
    }
  };

  const handleClickChangeStatus = async (identifier, fields) => {
    try {
      const res = await requests.deleteUser(identifier, { statusUser: fields.value });

      toast.success(`Status do usuário ${res.name} alterado para ${statusOptions.find((e) => e.value === res?.status)?.label}`);
    } catch (error) {
      console.error('Problemas ao alterar status do usuário, tente novamente ou entre em contato com o Administrador', error);
    }
  };
  //actions

  useEffect(() => {
    getFilterOptions();
  }, []);

  //component preset
  const title = 'Listagem de usuários';
  const subtitle = `Existe um total de ${metadata.total_count} usuários`;
  const emptyListMessage = 'Nenhum dado encontrado para os parâmetros filtrados';
  const cellRowSize = '240px';
  /* const mainCategoryMobile = 'codeReadable'; */
  const hiddenCategories = [
    'cellphone',
    'enableFacialRecognition',
    'id',
    'identifier',
    'isChecked',
    'roleHumanReadable',
    'phone',
    'role',
    'roleHumanReadable',
    'documentNumber',
    'status',
    'type',
  ];
  /* const subCategories = ['NCM', 'Descrição', 'Data de inicio da vigência', 'Status']; */

  const categories = [
    {
      title: 'Nome',
      field: 'e.name',
      width: '200',
    },
    'Permissões',
    'Empresas vinculadas',
    {
      title: 'E-mail',
      field: 'e.email',
    },
    'Perfil do usuário',
    'Status',
    {
      title: 'Ações',
      width: '80',
    },
  ];

  /* const tooltipCategories = [
    { category: 'NCM', describe: 'identificador de NCM' },
    { category: 'Status', describe: 'Status de atividade' },
  ]; */

  const customFields = [
    { type: 'select', title: 'Perfil', options: roleOptions, name: 'role', action: handleClickChangeRole },
    { type: 'select', title: 'Status', options: statusOptions, name: 'status', action: handleClickChangeStatus },
  ];

  const customButtons = [
    { label: 'Prévia', tooltip: 'Visualizar contrato', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenEditModal },
  ];

  /*  const statusPreset = [
    { color: '#6BDD9E', label: 'Ativado', value: 'Ativo' },
    { color: '#E74C3C', label: 'Inativo', value: 'Inativo' },
  ]; */
  //component preset

  return (
    <Page
      screen="user"
      title="Gestão de usuários"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Nome ou email"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar usuário',
          text: 'Cadastro de usuário',
          action:
            (handleOpenCreateModal,
            (
              /* create */
              <ModalManagementUser
                loadList={load}
                filter={getFilter}
                fields={fields}
                setFields={setFields}
                isEditMode={false}
                handleClose={handleCreateModalClose}
                handleOpen={onCreateModalOpen}
                isOpen={isCreateModalOpen}
              />
            )),
          modality: 'custom',
        },
        {
          title: 'Exportar usuários',
          text: 'Exportar arquivo CSV com dados de usuários',
          action: exportListAction,
          modality: 'export-csv',
        },
      ]}>
      {/* edit */}
      <ModalManagementUser
        loadList={load}
        filter={getFilter}
        fields={fields}
        setFields={setFields}
        isEditMode={true}
        handleClose={handleEditModalClose}
        handleOpen={onEditModalOpen}
        isOpen={isEditModalOpen}
        identifier={identifier}
      />

      <CommonList
        title={title}
        subtitle={subtitle}
        categories={categories}
        /* subCategories={subCategories} */
        //conditionals
        hiddenCategories={hiddenCategories}
        /* mainCategoryMobile={mainCategoryMobile} */
        /* tooltipCategories={tooltipCategories} */
        /* hasCollapsed={true} */
        hasPagination={true}
        /* hasDeleteButton={true} */
        /* hasDetailsButton={true} */
        sorting={sorting}
        handleSort={handleSort}
        /* detailsRedirectEndpoint={detailsRedirectEndpoint} */
        /* hasDetailsButtonSublist={true} */
        /* detailsRedirectEndpointSublist={detailsRedirectEndpointSublist} */
        /* deleteButtonAction={deleteAction} */
        customFields={customFields}
        customButtons={customButtons}
        /* statusPreset={statusPreset} */
        /* collapsedContentType={collapsedContentType} */
        list={list}
        rawData={list}
        /* subList={list} */
        emptyListMessage={emptyListMessage}
        maxLengthResume={50}
        cellRowSize={cellRowSize}
        //paginate
        action={action}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
      />
    </Page>
  );
};

export default UserManagementPage;
