import React, { useCallback, useEffect, useState } from 'react';

import { MdDelete, MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, IconButton, TabPanel, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../../services/permissions';
import requests from '../../../../../services/requests';
import { handleDocuments } from '../../../../../utils/documents/handleDocuments';
import { limitChars } from '../../../../../utils/strings';
import { getStatusTransition } from '../../../../../utils/translate';
import ModalChangeStatus from '../Modals/ModalChangeStatus';

const DocumentTab = ({ entity = {}, getEntity = () => {} }) => {
  let profileRole = localStorage.getItem('profile-role');
  const hasPermissionView = permissions.logisticDocumentView;
  const hasPermissionManagement = permissions.logisticDocumentManagement;

  const [files, setFiles] = useState([]);
  const [cteOptions, setCteOptions] = useState([]);
  const [field, setField] = useState({});
  const [isLoadingListFiles, setIsLoadingListFiles] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDownload = (fileIdentifier, uploadName) => {
    const link = document.createElement('a');
    link.href = (
      process.env.REACT_APP_API_URL + `/open/freight/${entity.identifier}/file/${fileIdentifier}/download?${new Date().getTime()}`
    ).replace('//open', '/open');
    link.target = '_blank';
    link.download = entity.identifier;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadDriverFreightFile(entity?.identifier, docs, config)
        .then(() => {
          resolve();
          getEntity();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          getEntity();
        });
    });
  };

  const handleClickChangeStatus = (fileIdentifier) => {
    let transition = getStatusTransition(field?.status?.old, field?.status?.new);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      return;
    }

    requests
      .changeStatusDriverFreightFile(entity.identifier, fileIdentifier, transition)
      .then((response) => {
        toast.success(`Status do ${response.name} alterado para ${field?.status?.new}`);
      })
      .catch(() => {
        toast.error('Problemas ao alterar status do CTe, tente novamente ou entre em contato com o Administrador');
      });
  };

  const loadDriverFreightFiles = () => {
    setIsLoadingListFiles(true);

    requests
      .showDriverFreightFiles(entity.identifier)
      .then((response) => {
        let result = [];
        response?.data?.map((value) => {
          if (value?.isCte) {
            result.push({
              label: value.number,
              value: value.identifier,
            });
          }
        });
        setCteOptions(result);
        setFiles(response?.data);
        setIsLoadingListFiles(false);
      })
      .catch(() => {
        setIsLoadingListFiles(false);
      });
  };

  const handleDelete = useCallback((fileIdentifier) => {
    setIsLoadingListFiles(true);

    requests
      .removeCTEFile(entity.identifier, fileIdentifier)
      .then(() => {
        toast.success(`Arquivo removido com sucesso`);
        onClose();
      })
      .finally(() => {
        loadDriverFreightFiles();
      });
  });

  const isShowButtonsView = (file) => {
    if (!hasPermissionView) {
      return;
    }
    return (
      <>
        {' '}
        <DefaultModal
          label="Visualizar arquivo"
          width="70vw"
          ctaButton={
            <IconButton
              bg="#6b6b6b"
              p="0"
              mr="2"
              icon={<MdOutlineOpenInNew size={20} color="#FFFFFF" />}
              transition="0.2s"
              _hover={{ bgColor: '#b8b8b8' }}
            />
          }
          title={file.modalityReadable}
          showButtons={false}>
          {handleDocuments(`/open/freight/${entity?.identifier}/file/${file?.identifier}/view`, file?.name)}
        </DefaultModal>
        <Tooltip label="Baixar arquivo">
          <IconButton
            bg="#633dbe"
            p="0"
            icon={<MdOutlineFileDownload size={20} color="#FFFFFF" />}
            mr="2"
            transition="0.2s"
            _hover={{ bgColor: '#ab88ff' }}
            onClick={(decision) => {
              if (decision) {
                handleDownload(file.identifier, file.uploadName);
              }
            }}
          />
        </Tooltip>
      </>
    );
  };

  const isShowButtonDelete = (identifier, modality) => {
    if (!hasPermissionManagement || modality == 'LOG') {
      return;
    }
    return (
      <DefaultModal
        ctaButton={
          <IconButton
            bg="#EF8484"
            p="0"
            icon={<MdDelete size={20} color="#FFFFFF" />}
            transition="0.2s"
            _hover={{ bgColor: '#f6a4a4' }}
          />
        }
        title={'Certeza que deseja excluir ?'}
        loading={isLoadingListFiles}
        subtitle={<>Esta ação é permanente, deseja prosseguir?</>}
        buttonColor="red"
        txtButton="Excluir"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete(identifier);
          }
        }}
      />
    );
  };

  useEffect(() => {
    if (entity?.identifier !== undefined) {
      loadDriverFreightFiles();
    }
  }, [entity]);

  return (
    <>
      {/* <ModalViewFileStyles /> */}
      <ModalUpload
        title="Documentos da viagem"
        subtitle="Upload de documentos da viagem"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        multiple={true}
        requestAction={sendUpload}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'MDFE_XML', label: 'XML do MDF-e' },
              { value: 'MDFE_PDF', label: 'PDF do MDF-e' },
              { value: 'CTE_XML', label: 'XML do CT-e' },
              { value: 'CTE_PDF', label: 'PDF do CT-e' },
              { value: 'NF_XML', label: 'XML da Nota fiscal' },
              { value: 'NF_PDF', label: 'PDF da Nota fiscal' },
              { value: 'OUTROS', label: 'Outros' },
            ],
          },
          {
            label: 'CT-e',
            group: 'parent',
            name: 'par',
            required: false,
            options: cteOptions,
          },
        ]}
      />
      <TabPanel>
        <ScreenLoader isLoading={isLoadingListFiles}>
          <Table className="table-default">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Modalidade</Th>
                <Th>Número</Th>
                {entity?.modality == 'MANUAL' && <Th>Status</Th>}
                {(hasPermissionView || hasPermissionManagement) && <Th>Ações</Th>}
              </Tr>
            </Thead>

            <Tbody>
              {!files ||
                (files?.length === 0 && (
                  <Flex justify="center" my="25px">
                    Nenhum documento encontrado
                  </Flex>
                ))}
              {files &&
                files.map((file, key) => {
                  if (profileRole !== 'ROLE_SYSTEM_ADMIN' && file?.modality == 'LOG') {
                    return;
                  }

                  return (
                    <Tr key={key}>
                      <Td color="#854FFF" fontSize={14}>
                        <Tooltip label={file?.name}>{file?.name ? limitChars(file?.name, 15) : '-'}</Tooltip>
                      </Td>
                      <Td>{file?.modalityReadable ?? '-'}</Td>
                      <Td>{file?.number}</Td>
                      {entity?.modality == 'MANUAL' && (
                        <Td w={{ md: '30%', sm: '100%' }}>
                          <ModalChangeStatus
                            options={file?.statusAvailable}
                            driverFreight={entity}
                            file={file}
                            field={field}
                            setField={setField}
                            callback={(decision) => {
                              if (decision) {
                                handleClickChangeStatus(file.identifier);
                              }
                            }}
                          />
                        </Td>
                      )}
                      {(hasPermissionView || hasPermissionManagement) && (
                        <Td>
                          <Flex>
                            {isShowButtonsView(file)}
                            {isShowButtonDelete(file.identifier, file?.modality)}
                          </Flex>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ScreenLoader>
        <Flex gap="35px" justify="flex-end" mt="10">
          <Button variant="primary" mb="5" onClick={() => onOpen()}>
            <Text fontSize="14px" textColor="#FFFFFF">
              Anexar documento
            </Text>
          </Button>
        </Flex>
      </TabPanel>
    </>
  );
};

export default DocumentTab;
