import React from 'react';

import { Box, Flex, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';

import TavelListCollapse from './TavelListCollapse';

import './styles.css';

const TableTravel = ({ list, load, loadData = () => {} }) => {
  return (
    <ScreenLoader isLoading={load} overflowY="auto" w="auto" m="10px">
      <Table w="full">
        <Thead>
          <Tr className="border">
            <Th>
              <Box></Box>
            </Th>

            <Th>
              <Box></Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Número
                </Text>
              </Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Empresas vinculadas
                </Text>
              </Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Motorista
                </Text>
              </Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Início
                </Text>
              </Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Conclusão
                </Text>
              </Box>
            </Th>

            <Th>
              <Box></Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Arquivos
                </Text>
              </Box>
            </Th>

            <Th>
              <Box>
                <Text p="10px" textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                  Status
                </Text>
              </Box>
            </Th>

            <Th>
              <Box></Box>
            </Th>

            <Th>
              <Box></Box>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {list?.map((entity, key) => {
            return <TavelListCollapse entity={entity} key={key} loadData={loadData} />;
          })}
        </Tbody>
      </Table>

      {!load && list?.length === 0 && (
        <Flex justify="center" my="25px">
          Nenhum dado encontrado para os parâmetros filtrados.
        </Flex>
      )}
    </ScreenLoader>
  );
};

export default TableTravel;
