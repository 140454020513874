import React, { useState } from 'react';

import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';

import add from '../../../../assets/images/add.svg';
import requests from '../../../../services/requests';

const ModalCreateVehicle = ({ action, setAction }) => {
  const [load, setLoad] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editInfos, setEditInfos] = useState({
    licensePlate: '',
    color: '',
    modelVehicle: '',
    typeCar: '',
    capacity: '',
    brand: '',
    yearManufacture: '',
    model: '',
    yearModel: '',
    modelVehicle: '',
    ownership: 'VENDEMMIA',
  });

  const addVehicle = () => {
    setLoad(true);

    requests
      .addVehicle(editInfos)
      .then((list) => {
        setAction(action + 1);
        toast.success('Veículo criado com sucesso!');
        setLoad(false);
        onClose();
      })
      .catch(() => {
        setLoad(false);
      });
  };

  const handleClose = () => {
    setEditInfos({
      licensePlate: '',
      color: '',
      modelVehicle: '',
      typeCar: '',
      capacity: '',
      brand: '',
      yearManufacture: '',
      model: '',
      yearModel: '',
      modelVehicle: '',
      ownership: 'VENDEMMIA',
    });
    onClose();
  };

  return (
    <>
      <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={onOpen}>
        <Image src={add} />
        <Text ml="10px">Novo veículo</Text>
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalBody p="25px" position="relative">
            <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" mb="20px">
              Dados do veículo
            </Text>
            <Wrap className="down-anim">
              <Flex direction="column">
                <label color="#422C76" htmlFor="licensePlate">
                  Placa
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.licensePlate}
                  name="licensePlate"
                  maxLength={8}
                  id="licensePlate"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      licensePlate: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="color">
                  Cor
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.color}
                  name="color"
                  maxLength={30}
                  id="color"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      color: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="modelVehicle">
                  Tipo Veículo
                </label>
                <Select
                  w="140px"
                  h="40px"
                  boxShadow={`0px;`}
                  borderRadius={`5px;`}
                  name="modelVehicle"
                  id="modelVehicle"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      modelVehicle: event.target.value,
                    })
                  }>
                  <option value="Caminhão">Caminhão</option>
                  <option value="Carreta">Carreta</option>
                  <option value="Caminhonete">Caminhonete</option>
                </Select>
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="typeCar">
                  Tipo carreta
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.typeCar}
                  name="typeCar"
                  id="typeCar"
                  maxLength={30}
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      typeCar: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="capacity">
                  Capacidade
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.capacity}
                  name="capacity"
                  id="capacity"
                  maxLength={100}
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      capacity: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="brand">
                  Marca
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.brand}
                  name="brand"
                  maxLength={30}
                  id="brand"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      brand: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="yearManufacture">
                  Ano fabricação
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.yearManufacture}
                  name="yearManufacture"
                  id="yearManufacture"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      yearManufacture: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="model">
                  Modelo
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.model}
                  name="model"
                  maxLength={30}
                  id="model"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      model: event.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction="column">
                <label color="#422C76" htmlFor="yearModel">
                  Ano modelo
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.yearModel}
                  name="yearModel"
                  id="yearModel"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      yearModel: event.target.value,
                    })
                  }
                />
              </Flex>

              <Flex direction="column">
                <label color="#422C76" htmlFor="ownership">
                  Propriedade
                </label>
                <Select
                  w="140px"
                  h="40px"
                  boxShadow={`0px;`}
                  borderRadius={`5px;`}
                  name="ownership"
                  id="ownership"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      ownership: event.target.value,
                    })
                  }>
                  <option value="VENDEMMIA">Vendemmia</option>
                  <option value="PROPRIO">Próprio</option>
                  <option value="TERCEIRO">Terceiro</option>
                </Select>
              </Flex>
              {/*<Flex direction="column">
                <label color="#422C76" htmlFor="cnpj">
                  CNPJ
                </label>
                <Input
                  variant="primary"
                  w="120px"
                  type="text"
                  value={editInfos.cnpj}
                  name="cnpj"
                  id="cnpj"
                  onChange={(event) =>
                    setEditInfos({
                      ...editInfos,
                      cnpj: event.target.value,
                    })
                  }
                />
                </Flex>*/}
            </Wrap>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button variant="secundary" borderRadius="7px" onClick={handleClose}>
              Cancelar e Fechar
            </Button>

            <Button
              variant="primary"
              borderRadius="7px"
              marginLeft="15px"
              isLoading={load}
              loadingText="Salvando..."
              onClick={addVehicle}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCreateVehicle;
