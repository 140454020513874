import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';
import formatDocument from '../../../../utils/fields/masks/formatDocument';
import validateCNPJ from '../../../../utils/fields/validadeCNPJ';

const yup = require('yup');

const initialValues = {
  cnpj: '', // Inicializar como string vazia
};

const CreditDrawerNew = ({ isOpen, onClose, action, setAction }) => {
  const btnRef = React.useRef();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" finalFocusRef={btnRef}>
      <DrawerOverlay />

      <DrawerContent maxW={'100% !important'} w={{ base: 'initial', md: '50% !important' }} px="30px" py="30px">
        <DrawerCloseButton />

        <Flex justify="space-between" align="center" mt="10px">
          <Flex direction="column" gap="5px">
            <Flex align="center" textStyle="tableTitle" gap="5px">
              <Text textColor="primary" fontWeight="bold">
                Novo Cadastro
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <DrawerBody p="0" mt="20px">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={yup.object().shape({
              cnpj: yup
                .string()
                .required('Campo obrigatório')
                .test('cpf-validate', 'CNPJ inválido', (value) => {
                  // Se o campo estiver vazio, não execute a validação
                  if (!value) {
                    return true;
                  }
                  return validateCNPJ(value);
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              requests
                .addCreditAnalysis({
                  documentNumber: values.cnpj,
                })
                .then(() => {
                  toast.success('Análise de crédito cadastrada com sucesso!');
                  setSubmitting(false);
                  onClose();
                  setAction(action + 1);
                })
                .catch(() => {
                  setSubmitting(false);
                });
            }}>
            {({ handleChange, handleSubmit, isSubmitting, values }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <FormLabel textColor="primary">CNPJ</FormLabel>
                  <Input
                    bg="#F2F2F2"
                    variant="primary"
                    className="input-register"
                    name="cnpj"
                    width="15.625rem"
                    maxLength={18}
                    onChange={(event) => {
                      const formatted = formatDocument(event.target.value);
                      handleChange({ target: { name: 'cnpj', value: formatted } });
                    }}
                    value={values.cnpj}
                  />

                  <ErrorMessage name="cnpj" component="p" className="error-message-error" />
                </FormControl>

                <Flex mt="20px" justify="flex-start">
                  <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting} loadingText="Salvando...">
                    Consultar
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CreditDrawerNew;
