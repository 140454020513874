const yup = require('yup');

const responsibleValidation = (isDraft = false) => {
  let validation = yup.object().shape({
    comercial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    additionalComercial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    tax: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    operator: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    financial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    director: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    logistic: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
  });

  return validation;
};

export default responsibleValidation;
