import React, { useState } from 'react';

import { FaDownload, FaEye } from 'react-icons/fa';

import { Box, Flex, IconButton, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';
import DocumentModalView from '../../components/ModalViewFile';

const ExpeditionDocumentTable = ({ list, action, setAction, metadata, setMetadata }) => {
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const handleDownload = (identifier) => {
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/expedition/document/${identifier}/download-all`).replace('//open', '/open');
    link.target = '_blank';
    link.download = currentEntity?.identifier;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenModal = (entity, files) => {
    setCurrentEntity(entity);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  return (
    <>
      <DocumentModalView isOpen={isOpenModalViewFile} entity={currentEntity} onClose={() => handleOpenModal([])} />
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th fontWeight="bold">Nota fiscal</Th>
            <Th fontWeight="bold">Número da carga</Th>
            <Th fontWeight="bold">Empresa</Th>
            <Th fontWeight="bold" textAlign="center">Arquivos</Th>
            <Th fontWeight="bold" textAlign="center">Data de upload</Th>
            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list?.map((entity, key) => (
            <Tr key={key} style={{ '--delay': `0.${key}s` }}>
              <Td>{entity?.noteNumber ?? '-'}</Td>
              <Td>{entity?.cargoNumber ?? '-'}</Td>
              <Td>
                {entity?.clientName ?? '-'}<br />
                <small>({entity.clientDocumentNumber})</small>
              </Td>
              <Td textAlign="center">{entity?.files?.length > 0 ? entity.files.length : '-'}</Td>
              <Td textAlign="center">{entity?.createdAtFormatted ?? '-'}</Td>
              <Td>
                <Flex gap="0.62rem" alignItems="center" justify="center">
                  <Tooltip label="Visualizar todos">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<FaEye size={26} color="#6C48C2" />}
                        onClick={(decision) => {
                          if (decision) {
                            handleOpenModal(entity, entity?.files);
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label="Baixar todos">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<FaDownload size={23} color="#6C48C2" />}
                        onClick={() => {
                          handleDownload(entity?.identifier);
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Td>
            </Tr>
          ))}

          {list?.length == 0 && (
            <Tr>
              <Td colSpan={9}>
                <Flex justify="center" my="1.563rem">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default ExpeditionDocumentTable;
