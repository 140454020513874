export default function validateCPF(cpf) {
    // Remova caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');
  
    // Verifique se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }
  
    // Verifique dígitos verificadores
    const cpfArray = cpf.split('').map(Number);
    const validateDigit = (cpf, positions) => {
      const total = positions.reduce((acc, position, index) => {
        acc += cpf[index] * position;
        return acc;
      }, 0);
      const remainder = total % 11;
      return remainder < 2 ? 0 : 11 - remainder;
    };
  
    const firstDigit = validateDigit(cpfArray, [10, 9, 8, 7, 6, 5, 4, 3, 2]);
    const secondDigit = validateDigit(cpfArray, [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]);
  
    return cpfArray[9] === firstDigit && cpfArray[10] === secondDigit;
}