import React, { useState } from 'react';

import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import add from '../../../../assets/images/add.svg';

import RegistrationData from './RegistrationData';

const ModalDriver = ({ handleOpen, handleClose, handleCloseRefresh, isOpen, onOpen, onClose }) => {
  const [entity, setEntity] = useState({});
  return (
    <>
      <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={handleOpen}>
        <Image src={add} />
        <Text ml="10px">Novo motorista</Text>
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>Cadastro de novo motorista</ModalHeader>
          <ModalCloseButton zIndex="100000" />
          <ModalBody p="25px" position="relative">
            <RegistrationData entity={entity} handleClose={handleClose} handleCloseRefresh={handleCloseRefresh} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDriver;
