import React from 'react';

import { MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import DefaultModal from '../../../components/Modal/Template/DefaultModal';
import { handleDocuments } from '../../../utils/documents/handleDocuments';

const DocumentModalView = ({ isOpen, onClose, entity }) => {
  const handleDownload = (fileIdentifier, uploadName) => {
    const link = document.createElement('a');

    link.href = (process.env.REACT_APP_API_URL + `/open/expedition/document/${fileIdentifier}/view`).replace('//open', '/open');

    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>
            {entity?.noteNumber ? `Número de nota: ${entity?.noteNumber}` : `Número de carga: ${entity?.cargoNumber}`}
          </ModalHeader>
          <ModalCloseButton zIndex="100000" />
          <ModalBody p="25px" position="relative">
            <Table className="table-default" w="100%">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Modalidade</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
              {entity?.files?.length > 0 ?
                entity.files?.map((file, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Tr>
                        <Td>{file?.name ?? '-'}</Td>
                        <Td>{file?.modality ?? '-'}</Td>
                        <Td>
                          <Flex>
                            <DefaultModal
                              label="Visualizar arquivo"
                              width="70vw"
                              ctaButton={
                                <IconButton
                                  bg="#6b6b6b"
                                  p="0"
                                  mr="2"
                                  icon={<MdOutlineOpenInNew size={20} color="#FFFFFF" />}
                                  transition="0.2s"
                                  _hover={{ bgColor: '#b8b8b8' }}
                                />
                              }
                              title={file?.modalityReadable}
                              showButtons={false}>
                              {handleDocuments(
                                `/open/expedition/document/${file?.identifier}/view?${new Date().getTime()}`,
                                file.uploadName
                              )}
                            </DefaultModal>
                            <Tooltip label="Baixar arquivo">
                              <IconButton
                                bg="#633dbe"
                                p="0"
                                icon={<MdOutlineFileDownload size={20} color="#FFFFFF" />}
                                mr="2"
                                transition="0.2s"
                                _hover={{ bgColor: '#ab88ff' }}
                                onClick={() => {
                                  handleDownload(file?.identifier, file?.uploadName);
                                }}
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    </React.Fragment>
                  );
                }) : (
                  <Tr>
                    <Td colSpan={3}>
                      <Flex justify="center" my="25px">
                        Nenhum dado encontrado.
                      </Flex>
                    </Td>
                  </Tr>
                )
              }
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentModalView;
