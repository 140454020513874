import React, { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const ModalDeleteFaq = ({ entity, onSave }) => {
  const [load, setLoad] = useState(false);

  const handleDelete = async () => {
    setLoad(true);
    try {
      const response = await requests.deleteFaq(entity.identifier);
      onSave(response.data);
      setLoad(false);

      toast.success('Tópico excluído com sucesso!');
    } catch (error) {
      setLoad(false);

      toast.error('Erro ao excluído o tópico, tente novamente!');
    }
  };

  return (
    <>
      <DefaultModal
        ctaButton={<MdDeleteOutline size={25} color="#E74C3C" />}
        title={'Certeza que deseja excluir ?'}
        loading={load}
        subtitle={
          <>
            Deseja mesmo excluir esse tópico?
            <br />
            Ele sumirá automaticamente da página de FAQ.
          </>
        }
        buttonColor="red"
        txtButton="Excluir"
        loadingText="Excluindo"
        callback={async (decision) => {
          if (decision) {
            await handleDelete();
          }
        }}
      />
    </>
  );
};

export default ModalDeleteFaq;
