import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { useDisclosure } from '@chakra-ui/react';

import requests from '../../../../services/requests';

import ModalFormFaq from './ModalFormFaq';

const ModalEditFaq = ({ onSave, entity }) => {
  const { onClose } = useDisclosure();
  const [typeSystem, setTypeSystem] = useState(entity?.typeSystem);
  const [modality, setModality] = useState(entity?.modality);
  const [title, setTitle] = useState(entity?.title);
  const [description, setDescription] = useState(entity?.description);
  const [load, setLoad] = useState(false);

  const handlesetTypeSystemChange = (event) => {
    setTypeSystem(event.target.value);
  };

  const handleModalityChange = (event) => {
    setModality(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.editFaq(entity.identifier, {
        title,
        description,
        typeSystem,
        modality,
        videoUrl: null,
        status: 'DRAFT',
      });
      onSave();
      onClose();
      setLoad(false);

      toast.success('Tópico editado com sucesso');
    } catch (error) {
      toast.error('Erro ao criar o tópico, tente novamente!');
      onClose();
      setLoad(false);
    }
  };

  return (
    <>
      <>
        <ModalFormFaq
          txtButton="Editar tópico"
          txtHeader="Edição do Tópico"
          textButtonConfirm="Salvar"
          onSubmit={onSubmit}
          title={title}
          description={description}
          typeSystem={typeSystem}
          modality={modality}
          handlesetTypeSystemChange={handlesetTypeSystemChange}
          handleModalityChange={handleModalityChange}
          handleTitleChange={handleTitleChange}
          handleContentChange={handleContentChange}
          load={load}
          isEdit={true}
        />
      </>
    </>
  );
};

export default ModalEditFaq;
