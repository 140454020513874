import React from 'react';

import { MdHelp, MdOutlineExpandLess, MdOutlineExpandMore, MdOutlineUnfoldMore } from 'react-icons/md';

import { Box, Flex, Text, Th, Tooltip } from '@chakra-ui/react';

const ArrowIcon = ({ isActive, direction }) => {
  if (!isActive) {
    return <MdOutlineUnfoldMore size={20} />;
  }
  return direction === 'ASC' ? <MdOutlineExpandMore size={20} /> : <MdOutlineExpandLess size={20} />;
};

const SortableTableHeader = ({ column, label, sorting, handleSort, markgroup, markName, tooltip }) => {
  const isActive = sorting?.column === column;

  const markNameFormatted = markName ?? '';
  const markNameLower = markName && markName.toLowerCase();

  return (
    <Th
      style={{ borderTop: markgroup ? '1px solid #6C48C2' : '0' }}
      className={markName ? `sortable_table_header_${markNameLower}` : 'sortable_table_header'}
      overflow={'initial'}>
      <style>
        {`
          .sortable_table_header_${markNameLower}::before {
            content: "${markNameFormatted}";  
            position: absolute;
            right: 0;
            top: -20px;
            font-size: 11px;
            color: #6C48C2;
          }

          body .chakra-collapse {
            background-color: transparent;
          }
      `}
      </style>

      <Flex
        direction="row"
        align="center"
        onClick={() => handleSort(column, isActive && sorting?.direction === 'ASC' ? 'DESC' : 'ASC')}
        cursor="pointer">
        <Text>{label}</Text>
        {tooltip && (
          <Tooltip label={tooltip}>
            <Box ml="0.325rem">
              <MdHelp size={15} color="#422C76" />
            </Box>
          </Tooltip>
        )}
        <ArrowIcon isActive={isActive} direction={sorting?.direction} />
      </Flex>
    </Th>
  );
};

export default SortableTableHeader;
